import { Typography, Table } from "antd";
import styled from "styled-components";
const { Title } = Typography;

export const StyledContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  padding: 24px;
  border-radius: 8px;
  box-shadow:
    0 1px 3px #d9d9d9ee,
    0 1px 2px #d9d9d9ee;
`;

export const StyledTitle = styled(Title)`
  margin-bottom: 16px;
  @media (max-width: 576px) {
    text-align: center;
  }
`;

export const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: #f0f0f0;
  }
`;
