// Import the action creator for setting error messages in our Redux store
import { setErrorMessage } from '@/store/features/errorMessage/errorMessageSlice'

/**
 * Creates an error message object based on the API response
 *
 * @param {Object} response - The response from an API call
 * @returns {Object} An object with 'show' and 'message' for the error alert
 */
const createErrorMessageObject = (response) => {
  // Check if the response was successful
  if (response?.error) {
    // If error, return an object to show the error message
    return {
      show: true,
      message:
        response?.error?.data?.errorMessage?.[0] || 'Something went wrong!',
    }
  } else {
    // If  successful, return an empty object to hide the error message
    return {
      show: false,
      message: null,
    }
  }
}

/**
 * Handles creating an error message and dispatching it to the Redux store
 *
 * @param {Object} response - The response from an API call
 */
const handleErrorMessage = (response) => {
  // Create the error message object
  const errorMessage = createErrorMessageObject(response)

  // Import the store and dispatch the error message
  // We're using dynamic import to avoid circular dependency issues
  import('@/store/store').then(({ store }) => {
    // Dispatch the setErrorMessage action to update our Redux store
    store.dispatch(setErrorMessage(errorMessage))
  })
}

// Export the main function so it can be used in other parts of our app
export default handleErrorMessage

// Here's a simple explanation of what this code does:
// 1. We have a function that looks at the response from our server.
// 2. It decides if the response is good (success) or bad (error).
// 3. If it's good, it creates an object that will hide any error messages.
// 4. If it's bad, it creates an object with an error message to show to the user.
// 5. Then, it sends this object to our app's central data store (Redux).
// 6. Our app can then show or hide error messages based on this information.

// This helps our app tell the user when something goes wrong,
// and hide error messages when things are working correctly.
