import styled from 'styled-components'
import { Typography } from 'antd'

const { Title } = Typography

export const StyledFormContainer = styled.div`
  margin: 0 auto;
  padding: 24px;
  max-width: 800px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`

export const StyledFormTitle = styled(Title)`
  margin-bottom: 24px;
  color: #1890ff;
  font-size: 24px;
`
