import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Checkbox, Flex, Form, Space, Typography } from "antd";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import EmptyState from "@/components/ui/empty/Empty";
import {
  useGetCCMBarriersQuery,
  useGetCCMBarrierMappingQuery,
  useCreateCCMBarrierMutation,
} from "@/store/features/ccm/ccmApiSlice";
import LoadingComponent from "@/components/LoadingComponent";
import { CcmContext } from "../../withCcmData";

const BarriersForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);
  const { date } = useContext(CcmContext);
  const {
    data: barriers,
    isLoading: isBarriersLoading,
    isSuccess: isCcmSuccess,
  } = useGetCCMBarriersQuery();
  const {
    data: patientBarriers,
    isLoading: isPatientBarriersLoading,
    isSuccess: isPatientCcmSuccess,
  } = useGetCCMBarrierMappingQuery({
    date,
    patientId,
    pageNumber: 1,
    pageSize: 100,
  });
  const [createBarriers, { isLoading: isCreating }] =
    useCreateCCMBarrierMutation();

  useEffect(() => {
    if (patientBarriers && patientBarriers.length > 0) {
      const selectedBarriers = patientBarriers.map(
        (barrier) => barrier.ccmBarrierId
      );
      form.setFieldsValue({
        selectedGoals: selectedBarriers,
        barriersToCare: selectedBarriers.length === 0,
        isInitial: false,
      });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [patientBarriers, form, multiStep]);

  const handleFinish = async (values) => {
    const payload = {
      CreateCCMBarrierList:
        values.selectedGoals?.map((ccmBarrierId) => ({ ccmBarrierId })) || [],
      patientId,
    };

    await createBarriers(payload);

    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  if (multiStep && currentStep !== 2) return null;

  let content = null;

  if (isBarriersLoading || isPatientBarriersLoading) {
    content = <LoadingComponent text="  Barriers loading ...." />;
  }

  if (patientBarriers?.length === 0 && multiStep === false) {
    content = <EmptyState />;
  }
  if (patientBarriers?.length === 0 && !multiStep) return <EmptyState />;
  if (isCcmSuccess && isPatientCcmSuccess) {
    content = (
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <ErrorAlert />
        <Flex vertical gap={10} style={{ margin: "0px 15px" }}>
          <Typography.Title level={5}>
            What are some things that might make it hard for the patient to
            reach his / her goals?
          </Typography.Title>

          <Form.Item name="barriersToCare" gap="middle">
            <Flex>
              <Typography.Title level={5}>Barriers to Care</Typography.Title>
            </Flex>
          </Form.Item>

          <Form.Item name="selectedGoals" style={{ margin: "0px 15px" }}>
            <Checkbox.Group disabled={multiStep ? false : !isEditing}>
              <Flex gap="large">
                {[1, 2, 3].map((groupId) => (
                  <Space key={groupId} direction="vertical">
                    <Typography.Title level={5}>
                      {groupId === 1
                        ? "Social"
                        : groupId === 2
                          ? "Financial"
                          : "Assistive Devices"}
                    </Typography.Title>
                    {barriers
                      ?.filter(
                        (barrier) => barrier.barrierGroupTypeId === groupId
                      )
                      .map((option) => (
                        <Checkbox
                          key={option.ccmBarrierId}
                          value={option.ccmBarrierId}
                        >
                          <span style={{ color: "black", opacity: 1 }}>
                            {option.name}
                          </span>
                        </Checkbox>
                      ))}
                  </Space>
                ))}
              </Flex>
            </Checkbox.Group>
          </Form.Item>
        </Flex>

        {multiStep && (
          <StepControls isLoading={isCreating} onNextClick={form.submit} />
        )}
      </Form>
    );
  }

  return content;
};

export default BarriersForm;
