import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Switch,
  Typography,
} from "antd";
import {
  StyledFormContainer,
  StyledFormTitle,
  StyledMainContainer,
} from "./Style";
import useCreateAddUser from "@/hooks/patient/useAddUser";
const { Option } = Select;

export default function AddUser() {
  const { handleCreateAddUser, isLoading } = useCreateAddUser();

  const onFinish = (values) => {
    handleCreateAddUser(values);
  };
  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };

  return (
    <StyledMainContainer>
      <StyledFormTitle>
        <Typography.Title level={2}> &#60; Add User</Typography.Title>
      </StyledFormTitle>
      <StyledFormContainer>
        <Form
          name="addUser"
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
        >
          {/* First Name,  Last Name */}
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Please input the first name!",
                  },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Please input the last name!",
                  },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>

          {/* Email,  Mobile NO */}
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input the email!",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                  {
                    required: true,
                    message: "Please input your phone number!",
                  },
                ]}
              >
                <Input
                  addonBefore={
                    <Select style={{ width: 80 }}>
                      <Option value="+92">+92</Option>
                      <Option value="+82">+82</Option>
                      <Option value="+88">+88</Option>
                    </Select>
                  }
                  placeholder="Phone Number"
                />
              </Form.Item>
            </Col>
          </Row>

          {/* {Group type} */}
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="groupType"
                label="Group Type"
                rules={[
                  {
                    required: true,
                    message: "Please select the Group Type!",
                  },
                ]}
              >
                <Select placeholder="Group Type">
                  <Option value="physician">Physician</Option>
                  <Option value="therapist">Therapist</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Physician NPN */}

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="physicianNpn" label="Physician NPN">
                <Input placeholder="NPN" />
              </Form.Item>
            </Col>
          </Row>

          {/* User Acces */}

          <Form.Item
            name="userAcces"
            label="User Acces"
            rules={[
              {
                required: true,
                message: "Please select an option!",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="global">Global</Radio>
              <Radio value="assignedPatients">Assigned Patients</Radio>
              <Radio value="selectedLocations">Selected Location</Radio>
            </Radio.Group>
          </Form.Item>

          {/* Location */}

          <Col span={8}>
            <Form.Item
              name="location"
              label="Location(s)"
              rules={[
                {
                  required: true,
                  message: "Please select Location!",
                },
              ]}
            >
              <Select placeholder="None Selected">
                <Option value="pakistan">Pakistan</Option>
                <Option value="netherland">NetherLand</Option>
                <Option value="bangladesh">Bangladesh</Option>
              </Select>
            </Form.Item>
          </Col>

          {/* Switches */}

          <Col>
            <Form.Item
              name="clicnicalTime"
              rules={[
                {
                  required: true,
                  message: "Please switch the button !",
                },
              ]}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "19px", color: "gray" }}>
                  Clinical Time Tracking:{" "}
                </span>
                <Switch defaultChecked onChange={onChange} />
              </div>
            </Form.Item>

            <Form.Item
              name="smsReminder"
              rules={[
                {
                  required: true,
                  message: "Please switch the button!",
                },
              ]}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "19px", color: "gray" }}>
                  SMS Reminder Report:{" "}
                </span>
                <Switch defaultChecked onChange={onChange} />
              </div>
            </Form.Item>

            <Form.Item
              name="highAlert"
              rules={[
                {
                  required: true,
                  message: "Please switch the button",
                },
              ]}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "19px", color: "gray" }}>
                  Hight Alert Notification:{" "}
                </span>
                <Switch defaultChecked onChange={onChange} />
              </div>
            </Form.Item>
          </Col>

          {/* {Notification for Alert} */}

          <Form.Item
            name="notificationAlert"
            label="Notification for Alert"
            rules={[
              {
                required: true,
                message: "Please select an option!",
              },
            ]}
          >
            <Radio.Group>
              <Radio value="email">Email</Radio>
              <Radio value="sms">SMS</Radio>
            </Radio.Group>
          </Form.Item>

          {/* Divider */}

          <Divider />

          {/* Cancel & Submit Button */}
          <Row gutter={11}>
            <Col span={3}>
              <Form.Item>
                <Button
                  style={{ borderRadius: "7px", padding: "2px 17px" }}
                  htmlType="submit"
                >
                  Cancel
                </Button>
              </Form.Item>
            </Col>
            <Form.Item>
              <Button
                type="primary"
                style={{ borderRadius: "7px", padding: "2px 17px" }}
                htmlType="submit"
                loading={isLoading}
              >
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </StyledFormContainer>
    </StyledMainContainer>
  );
}
