import { useMemo } from "react";
import { Avatar } from "antd";

const professionalColors = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f1c40f",
  "#e67e22",
  "#e74c3c",
  "#ecf0f1",
  "#95a5a6",
  "#f39c12",
  "#d35400",
  "#c0392b",
  "#bdc3c7",
  "#7f8c8d",
];

const getRandomColor = (name) => {
  const index = (name || "")
    .split("")
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return professionalColors[index % professionalColors.length];
};

const getInitials = (name) => {
  if (!name || typeof name !== "string") return "??";

  const words = name.split(" ").filter((word) => word && word.length > 0);

  if (words.length === 0) return "??";
  if (words.length === 1) {
    const firstWord = words[0];
    return firstWord.substring(0, Math.min(2, firstWord.length)).toUpperCase();
  }

  const firstInitial = words[0][0] || "?";
  const lastInitial = words[words.length - 1][0] || "?";

  return (firstInitial + lastInitial).toUpperCase();
};

export default function PatientAvatar({ src, name }) {
  const fallBackName = useMemo(() => getInitials(name), [name]);
  const backgroundColor = useMemo(() => getRandomColor(name), [name]);

  if (!src) {
    return (
      <Avatar
        alt={name}
        size={48}
        style={{
          backgroundColor,
          verticalAlign: "middle",
          color: "#ffffff",
          fontWeight: "bold",
          fontSize: fallBackName.length > 1 ? "16px" : "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {fallBackName.slice(0, 2)}
      </Avatar>
    );
  }

  return <Avatar src={src} alt={name} size={48} />;
}
