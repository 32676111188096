import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Flex, Form, Typography, Spin, Empty } from "antd";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import RichTextEditor from "@/components/shared/richTextEditor";
import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import {
  useGetCCMAdditionalInfoQuery,
  useCreateCCMAdditionalNotesMutation,
} from "@/store/features/ccm/ccmApiSlice";
import LoadingComponent from "@/components/LoadingComponent";
import { CcmContext } from "../../withCcmData";

const AdditionalNotesForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);
  const { date } = useContext(CcmContext);

  const {
    data: additionalNotes,
    isLoading: isNotesLoading,
    isSuccess,
  } = useGetCCMAdditionalInfoQuery({
    patientId,
    pageNumber: 1,
    pageSize: 1,
    ccmAdditionalInfoTypeId: 1,
    date,
  });
  const [createNotes, { isLoading: isCreating }] =
    useCreateCCMAdditionalNotesMutation();

  useEffect(() => {
    if (additionalNotes && additionalNotes.length > 0) {
      form.setFieldsValue({ description: additionalNotes[0].description });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [additionalNotes, form, multiStep]);

  const handleFinish = async (values) => {
    const payload = {
      ...values,
      ccmAdditionalInfoTypeId: 1,
      patientId,
      isInitial: true,
    };

    await createNotes(payload);
    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  if (multiStep && currentStep !== 10) return null;

  let content = null;

  if (isNotesLoading) {
    content = <LoadingComponent text="   Notes Loading ...." />;
  }
  if (additionalNotes?.length === 0 && !multiStep) return <Empty />;
  if (isSuccess) {
    content = (
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <ErrorAlert />
        <Flex vertical gap={10}>
          <Typography.Title level={5} style={{ margin: "0px 15px" }}>
            Additional Notes
          </Typography.Title>

          <Spin spinning={isNotesLoading}>
            <RichTextEditor
              form={form}
              keyName="description"
              initialValue={additionalNotes?.[0]?.description || ""}
              sx={{ marginBottom: "30px" }}
              readOnly={multiStep ? false : !isEditing}
            />
          </Spin>
        </Flex>

        {multiStep && (
          <StepControls isLoading={isCreating} onNextClick={form.submit} />
        )}
      </Form>
    );
  }

  return content;
};

export default AdditionalNotesForm;
