import usePubNub from "@/hooks/pubnub/usePubnub";
import { useCreateCallMutation } from "@/store/features/audioVideoCall/audioVideoApiSlice";
import { PhoneOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Button, Flex } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import JitsiCallComponent from "./JitsiCallComponent";
import { setNotification } from "@/store/features/notification/notificationSlice";

const CallInterface = ({ recipientUsername }) => {
  const [activeCall, setActiveCall] = useState(null);
  const [createCall, { isLoading }] = useCreateCallMutation();
  const { subscribeToChannel, sendMessage, pubnub } = usePubNub();
  const username = useSelector((state) => state.auth.username) || "";
  const dispatch = useDispatch();
  const startCall = async (callType) => {
    const callData = {
      recipientUsername,
      callType,
    };
    try {
      const result = await createCall({
        ...callData,
        recipientUsername: recipientUsername,
      }).unwrap();
      if (result?.isSuccess) {
        setActiveCall({
          userId: username,
          jitsiUrl: result.message,
          ...callData,
        });
        await sendMessage({
          callType: callType,
          meetingUrl: result.message,
          caller: username,
          receiver: recipientUsername,
        });
      }
    } catch (error) {
      console.error("Failed to initiate call:", error);
      dispatch(
        setNotification({
          type: "error",
          message: `${error?.data}`,
        })
      );
    }
  };

  const endCall = () => {
    setActiveCall(null);
    // You might want to add an API call here to inform the server that the call has ended
  };

  useEffect(() => {
    const channelName = `call-${[username, recipientUsername].sort().join("-")}`;
    console.log("channelName", channelName);
    subscribeToChannel(channelName);
    return () => {
      pubnub?.unsubscribe({ channels: [channelName] });
    };
  }, [recipientUsername, subscribeToChannel, pubnub, username]);

  return (
    <div>
      {!activeCall ? (
        <Flex gap="small">
          <Button
            icon={<PhoneOutlined rotate={90} />}
            onClick={() => startCall("audio")}
            disabled={isLoading}
          >
            Audio Call
          </Button>
          <Button
            icon={<VideoCameraOutlined />}
            onClick={() => startCall("video")}
            disabled={isLoading}
          >
            Video Call
          </Button>
        </Flex>
      ) : (
        <div>
          <JitsiCallComponent
            jitsiUrl={activeCall.jitsiUrl}
            callType={activeCall.callType}
            onCallEnd={endCall}
          />
          <Button onClick={endCall}>End Call</Button>
        </div>
      )}
    </div>
  );
};

export default CallInterface;
