import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import LoadingComponent from "@/components/LoadingComponent";
import ErrorAlert from "@/components/ui/errorAlert";
import {
  useCreateCCMGoalMutation,
  useGetCCMGoalMappingQuery,
  useGetCCMGoalsQuery,
} from "@/store/features/ccm/ccmApiSlice";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import { Checkbox, Empty, Flex, Form, Space, Spin, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CcmContext } from "../../withCcmData";

const GoalsForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);
  const { date } = useContext(CcmContext);

  const {
    data: goals,
    isLoading: isGoalsLoading,
    isSuccess,
  } = useGetCCMGoalsQuery();
  const { data: patientGoals, isLoading: isPatientGoalsLoading } =
    useGetCCMGoalMappingQuery({
      patientId,
      pageNumber: 1,
      pageSize: 100,
      date,
    });
  const [createGoals, { isLoading: isCreating }] = useCreateCCMGoalMutation();

  useEffect(() => {
    if (patientGoals && patientGoals.length > 0) {
      const selectedGoals = patientGoals.map((goal) => goal.ccmGoalId);
      form.setFieldsValue({ selectedGoals, isInitial: false });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [patientGoals, form, multiStep]);

  const handleFinish = async (values) => {
    const payload = {
      ccmGoalMappingList: values.selectedGoals.map((ccmGoalId) => ({
        ccmGoalId,
      })),
      patientId,
    };

    await createGoals(payload);

    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  if (multiStep && currentStep !== 1) return null;

  let content = null;
  if (isGoalsLoading || isPatientGoalsLoading) {
    content = <LoadingComponent text="  CCM Goals Loading ...." />;
  }

  if (patientGoals?.length === 0 && !multiStep) return <Empty />;
  if (isSuccess) {
    content = (
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <ErrorAlert />
        <Flex style={{ margin: "0px 1rem" }} vertical gap={10}>
          <Typography.Title level={5}>
            What are the patient&apos;s goals for disease management?
          </Typography.Title>

          <Spin spinning={isGoalsLoading || isPatientGoalsLoading}>
            <Form.Item style={{ margin: "0px 1rem" }} name="selectedGoals">
              <Checkbox.Group disabled={multiStep ? false : !isEditing}>
                <Space direction="vertical">
                  {goals?.map((option) => (
                    <Checkbox key={option.name} value={option.ccmGoalId}>
                      <span style={{ color: "black", opacity: 1 }}>
                        {option.name}
                      </span>
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            </Form.Item>
          </Spin>
        </Flex>

        {multiStep && (
          <StepControls isLoading={isCreating} onNextClick={form.submit} />
        )}
      </Form>
    );
  }

  return content;
};

export default GoalsForm;
