import { Pagination } from "antd";
import { PaginationWrapper, PageInfo } from "./styles";

const CustomPagination = ({
  current,
  total,
  pageSize,
  onChange,
  showSizeChanger = false,
  showQuickJumper = false,
}) => {
  // Calculate the range of items currently displayed
  const startIndex = (current - 1) * pageSize + 1;
  const endIndex = Math.min(current * pageSize, total);

  return (
    <PaginationWrapper>
      {/* Display current range of items and total */}
      <PageInfo>
        Showing {startIndex}-{endIndex} of {total} items
      </PageInfo>

      {/* Ant Design Pagination component */}
      <Pagination
        current={current}
        total={total}
        pageSize={pageSize}
        onChange={onChange}
        showSizeChanger={showSizeChanger}
        showQuickJumper={showQuickJumper}
        pageSizeOptions={[10, 20, 50, 100]}
      />
    </PaginationWrapper>
  );
};

export default CustomPagination;
