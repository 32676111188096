import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  show: false,
  message: null,
}

const errorMessageSlice = createSlice({
  name: 'errorMessage',
  initialState,
  reducers: {
    setErrorMessage: (state, action) => {
      state = action.payload
      return state
    },
  },
})

export const { setErrorMessage } = errorMessageSlice.actions
export default errorMessageSlice.reducer
