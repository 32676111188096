import { InfoCircleFilled } from "@ant-design/icons";
import { Flex, Form, Radio, Typography } from "antd";
import { useState } from "react";
import RiskClassificationModal from "./riskClassificationModal";
import { StyledCheckBoxContainer, StyledMainContainer } from "./styles";

const options = [
  {
    label: "Has Risks",
    value: 1,
  },
  {
    label: "Moderate Risks",
    value: 2,
  },
  {
    label: "Low Risks",
    value: 3,
  },
];

export default function PatientRiskClassification() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <StyledMainContainer>
      <Flex align="middle" gap="10px">
        <Typography.Title level={4}>
          What is the patient risk classificaiton?
          <span
            onClick={() => setIsModalOpen(true)}
            style={{ color: "#747474", cursor: "pointer", marginLeft: "10px" }}
          >
            <InfoCircleFilled style={{ fontSize: "18px" }} />
          </span>
        </Typography.Title>
      </Flex>
      <StyledCheckBoxContainer>
        <Form.Item name="riskClassificaitonTypeId">
          <Radio.Group options={options} />
        </Form.Item>
      </StyledCheckBoxContainer>
      <RiskClassificationModal
        visible={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </StyledMainContainer>
  );
}
