import { useCreateCareGiverMutation } from "@/store/features/patient/patientApiSlice";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const useCreateCareGiver = () => {
  const [createCareGiver, { isLoading, isSuccess, isError, data, error }] =
    useCreateCareGiverMutation();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const handleCreateCareGiver = (body) => {
    createCareGiver(body);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(`/patients/add/${patientId}/patient-settings`);
    }
  }, [isSuccess, navigate]);
  return { handleCreateCareGiver, isLoading, isSuccess, isError, data, error };
};

export default useCreateCareGiver;
