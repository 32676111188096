import { useDispatch, useSelector } from "react-redux";
import { ButtonContainer } from "./styles";
import { Button } from "antd";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";

const StepControls = (props) => {
  const { onNextClick, isLoading } = props;
  const { currentStep } = useSelector((state) => state.ccmForm);
  const dispatch = useDispatch();
  const handlePrevious = () => {
    dispatch(setCurrentStep(currentStep - 1));
  };

  const handleNext = () => {
    onNextClick();
  };

  return (
    <ButtonContainer>
      <Button onClick={handlePrevious} disabled={currentStep === 0}>
        Previous
      </Button>
      <Button type="primary" onClick={handleNext} loading={isLoading}>
        {currentStep === 11 ? "Finish" : "Next"}
      </Button>
    </ButtonContainer>
  );
};

export default StepControls;
