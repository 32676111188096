import { Divider, Typography, Button } from 'antd'
import {
  StyledButton,
  StyledContaintDiv,
  StyledMainContainer,
  StyledTitleDiv,
} from './Style'
import { CloseOutlined } from '@ant-design/icons'

export default function TaskPopup() {
  return (
    <StyledMainContainer>
      <StyledTitleDiv>
        <Typography.Title level={3}>Task</Typography.Title>
        <Typography.Text style={{ fontSize: '16px', fontWeight: 'bold' }}>
          Timer: 7Sec{' '}
          <span style={{ fontSize: '20px', cursor: 'pointer' }}>
            {' '}
            <CloseOutlined />{' '}
          </span>
        </Typography.Text>
      </StyledTitleDiv>
      <StyledContaintDiv>
        <h4>Reminder Due at:June 05,2023 - 12:00 PM</h4>
        <h4>Notes</h4>
        <h4>Call the patient</h4>
      </StyledContaintDiv>
      <Divider />
      <StyledButton>
        <Button style={{ marginRight: '7px' }}>Edit Details</Button>
        <Button type="primary">Mark as Complete</Button>
      </StyledButton>
    </StyledMainContainer>
  )
}
