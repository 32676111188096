import AiChatIcon from "@/assets/icons/AiChat";
import { useSendChatMessageMutation } from "@/store/features/chat/chatApiSlice";
import { FloatButton, Modal } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";
import ChatInput from "./ChatInput";
import ChatMessages from "./ChatMessages";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAIMessageModal } from "@/store/features/chat/chatContextSlice";

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
`;

const StyledFloatButton = styled(FloatButton)`
  .ant-float-btn-icon {
    width: auto !important;
  }
`;

const AiChat = () => {
  const [messages, setMessages] = useState([]);
  const { pathname } = useLocation();
  const [sendChatMessage, { isLoading }] = useSendChatMessageMutation();
  const {
    showModal: isModalVisible,
    shouldSendAutomatically,
    message,
  } = useSelector((state) => state.chatContext);
  const dispatch = useDispatch();
  const handleOpenChat = () => {
    dispatch(setAIMessageModal(true));
  };

  const handleCloseChat = () => {
    dispatch(setAIMessageModal(false));
  };

  const handleSendMessage = async (message) => {
    try {
      const response = await sendChatMessage({ message }).unwrap();
      setMessages([
        ...messages,
        { type: "user", content: message },
        { type: "ai", content: response.response },
      ]);
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  useEffect(() => {
    if (shouldSendAutomatically) {
      handleSendMessage(message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSendAutomatically, message]);
  if (pathname === "/messages") return;

  return (
    <>
      <StyledFloatButton
        type="primary"
        icon={<AiChatIcon />}
        onClick={handleOpenChat}
        size="large"
      />
      <Modal
        title="Chat with AI"
        open={isModalVisible}
        onCancel={handleCloseChat}
        footer={null}
        width={400}
      >
        <ChatContainer>
          <ChatMessages messages={messages} />
          <ChatInput onSendMessage={handleSendMessage} loading={isLoading} />
        </ChatContainer>
      </Modal>
    </>
  );
};

export default AiChat;
