import ErrorAlert from "@/components/ui/errorAlert";
import { useManualTimeTrackingMutation } from "@/store/features/timeTracking/timeTrackerApiSlice";
import { Button, DatePicker, Flex, Form, Select } from "antd";
import { useParams } from "react-router-dom";

const AddManualTimeLogForm = ({ setModalOpen }) => {
  const { patientId } = useParams();
  const [handleAddManualTimeLog, { isLoading }] =
    useManualTimeTrackingMutation();
  const [form] = Form.useForm();
  const handleSubmit = async (values) => {
    let cptCodeId = null;
    if (values.moduleName === "Patient Details") {
      cptCodeId = 1;
    } else if (values.moduleName === "RPM") {
      cptCodeId = 11;
    } else if (values.moduleName === "CCM") {
      cptCodeId = 17;
    }

    try {
      await handleAddManualTimeLog({
        ...values,
        cptCodeId,
        patientId,
      });
      setModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <ErrorAlert />
      <Form.Item name="moduleName" label="Module Name" required>
        <Select
          placeholder="Select Module"
          options={[
            {
              label: "Patient Details",
              value: "Patient Details",
            },
            {
              label: "RPM",
              value: "RPM",
            },
            {
              label: "CCM",
              value: "CCM",
            },
          ]}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item name="startTime" label="Start Time" required>
        <DatePicker
          showTime
          showSecond={false}
          needConfirm={false}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item name="endTime" label="End Time" required>
        <DatePicker
          showTime
          showSecond={false}
          needConfirm={false}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Flex justify="end" gap="10px">
        <Button type="primary" htmlType="submit" loading={isLoading}>
          Save
        </Button>
      </Flex>
    </Form>
  );
};

export default AddManualTimeLogForm;
