import Icon from "@ant-design/icons";

const CustomIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={"24"}
    height={"24"}
    viewBox="0 0 24 24"
    fill="none"
    stroke={"gray"}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M6.5 6.5l11 11L12 23V1l5.5 5.5-11 11" />
  </svg>
);

const BluetoothIcon = (props) => <Icon component={CustomIcon} {...props} />;
export default BluetoothIcon;
