import { useCreatePatientSettingMutation } from "@/store/features/patient/patientApiSlice";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const useCreatePatientSetting = () => {
  const [createPatientSetting, { isLoading, isSuccess, isError, data, error }] =
    useCreatePatientSettingMutation();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const handleCreatePatientSetting = (body) => {
    createPatientSetting(body);
  };
  useEffect(() => {
    if (isSuccess) {
      navigate(`/patients/add/${patientId}/provided-devices`);
    }
  }, [isSuccess, navigate]);
  return {
    handleCreatePatientSetting,
    isLoading,
    isSuccess,
    isError,
    data,
    error,
  };
};

export default useCreatePatientSetting;
