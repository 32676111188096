import styled from 'styled-components'
import { Typography } from 'antd'

const { Title } = Typography

export const StyledMainContainer = styled.div`
  max-width: 100%;
`
export const StyledFormContainer = styled.div`
  margin: 0 auto;
  padding: 24px;
  max-width: 80%;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`

export const StyledFormTitle = styled(Title)`
  margin: 10px 10px 10px 100px;
`
