import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Flex, Form, Radio, Space, Typography, Spin, Empty } from "antd";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import {
  useGetCCMAdditionalInfoQuery,
  useCreateCCMAdditionalNotesMutation,
} from "@/store/features/ccm/ccmApiSlice";
import LoadingComponent from "@/components/LoadingComponent";
import { CcmContext } from "../../withCcmData";

const AllergiesForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);
  const { date } = useContext(CcmContext);
  const {
    data: allergiesInfo,
    isLoading: isAllergiesLoading,
    isSuccess: isAllergiesSuccess,
  } = useGetCCMAdditionalInfoQuery({
    patientId,
    pageNumber: 1,
    pageSize: 1,
    ccmAdditionalInfoTypeId: 1,
    date,
  });
  const [createAllergiesInfo, { isLoading: isCreating }] =
    useCreateCCMAdditionalNotesMutation();

  useEffect(() => {
    if (allergiesInfo && allergiesInfo.length > 0) {
      form.setFieldsValue({
        hasAllergies: allergiesInfo[0].hasAllergies,
        isInitial: false,
      });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [allergiesInfo, form, multiStep]);

  const handleFinish = async (values) => {
    const payload = {
      ...values,
      ccmAdditionalInfoTypeId: 1,
      patientId,
    };

    await createAllergiesInfo({ ...payload });
    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  if (multiStep && currentStep !== 4) return null;

  let content = null;
  if (isAllergiesLoading) {
    content = <LoadingComponent text="  Allergies Info Loading ...." />;
  }
  if (allergiesInfo?.length === 0 && !multiStep) return <Empty />;
  if (isAllergiesSuccess) {
    content = (
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <ErrorAlert />
        <Flex vertical style={{ margin: "0px 15px" }} gap={10}>
          <Typography.Title level={5}>Do you have allergies?</Typography.Title>

          <Spin spinning={isAllergiesLoading}>
            <Form.Item style={{ margin: "0px 15px" }} name="hasAllergies">
              <Radio.Group disabled={multiStep ? false : !isEditing}>
                <Space direction="vertical">
                  <Radio value={true}>
                    <span style={{ color: "black", opacity: 1 }}>
                      Has Allergies
                    </span>
                  </Radio>
                  <Radio value={false}>
                    <span style={{ color: "black", opacity: 1 }}>
                      Do not have Allergies
                    </span>
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Spin>
        </Flex>

        {multiStep && (
          <StepControls isLoading={isCreating} onNextClick={form.submit} />
        )}
      </Form>
    );
  }

  return content;
};

export default AllergiesForm;
