import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import ErrorAlert from "@/components/ui/errorAlert";
import {
  useCreateCCMMedicationMutation,
  useGetCCMMedicationQuery,
} from "@/store/features/ccm/ccmApiSlice";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Empty,
  Flex,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { CcmContext } from "../../withCcmData";

const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 8px 16px;
  }
`;

const MedicationsForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);
  const { date } = useContext(CcmContext);

  const { data: medications, isLoading: isMedicationsLoading } =
    useGetCCMMedicationQuery({ date, patientId, pageNumber: 1, pageSize: 100 });
  const [createMedication, { isLoading: isCreating }] =
    useCreateCCMMedicationMutation();

  const hasPrescribedMedicationRegimen = Form.useWatch(
    "hasPrescribedMedicationRegimen",
    form
  );

  useEffect(() => {
    if (medications) {
      const medicationsList = medications.map((medication) => ({
        ...medication,
        endDate: medication.endDate ? dayjs(medication.endDate) : null,
      }));
      form.setFieldsValue({
        hasPrescribedMedicationRegimen: medications.length > 0,
        ccmMedicationDtoList: medicationsList,
        isInitial: false,
      });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [medications, form, multiStep]);

  useEffect(() => {
    if (
      hasPrescribedMedicationRegimen &&
      form.getFieldValue("ccmMedicationDtoList")?.length === 0
    ) {
      form.setFieldValue("ccmMedicationDtoList", [{}]);
    }
  }, [hasPrescribedMedicationRegimen, form]);

  const handleSubmit = async () => {
    const values = form.getFieldsValue(true);
    const payload = {
      ...values,
      patientId,
      isInitial: !values?.isInitial ? false : true,
    };

    await createMedication(payload);

    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  if (multiStep && currentStep !== 5) return null;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, __, index) => (
        <Form.Item
          name={[index, "name"]}
          rules={[{ required: true, message: "Please input the name" }]}
        >
          <Input placeholder="Name" disabled={multiStep ? false : !isEditing} />
        </Form.Item>
      ),
    },
    {
      title: "Prescription Reason",
      dataIndex: "prescriptionReason",
      key: "prescriptionReason",
      render: (_, __, index) => (
        <Form.Item
          name={[index, "prescriptionReason"]}
          rules={[{ required: true, message: "Please input the reason" }]}
        >
          <Input
            placeholder="Prescription Reason"
            disabled={multiStep ? false : !isEditing}
          />
        </Form.Item>
      ),
    },
    {
      title: "Dosage",
      dataIndex: "dosage",
      key: "dosage",
      render: (_, __, index) => (
        <Form.Item
          name={[index, "dosage"]}
          rules={[{ required: true, message: "Please input the dosage" }]}
        >
          <Input
            placeholder="Dosage"
            disabled={multiStep ? false : !isEditing}
          />
        </Form.Item>
      ),
    },
    {
      title: "Frequency",
      dataIndex: "frequencyTypeId",
      key: "frequencyTypeId",
      render: (_, __, index) => (
        <Form.Item
          name={[index, "frequencyTypeId"]}
          rules={[{ required: true, message: "Please select the frequency" }]}
        >
          <Select
            placeholder="Select frequency"
            disabled={multiStep ? false : !isEditing}
            options={[
              { label: "Once a day", value: 1 },
              { label: "Twice a day", value: 2 },
              { label: "Three times a day", value: 3 },
            ]}
          />
        </Form.Item>
      ),
    },
    {
      title: "Ongoing",
      dataIndex: "isOnGoing",
      key: "isOnGoing",
      render: (_, __, index) => (
        <Form.Item name={[index, "isOnGoing"]} valuePropName="checked">
          <Switch disabled={multiStep ? false : !isEditing} />
        </Form.Item>
      ),
    },
    {
      title: "Likely End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (_, __, index) => (
        <Form.Item
          name={[index, "endDate"]}
          rules={[{ required: true, message: "Please select the end date" }]}
        >
          <DatePicker disabled={multiStep ? false : !isEditing} />
        </Form.Item>
      ),
    },
    {
      title: "",
      key: "delete",
      render: (_, __, index) => (
        <Form.Item>
          {(multiStep || isEditing) && (
            <DeleteOutlined
              onClick={() => {
                const medications = form.getFieldValue("ccmMedicationDtoList");
                form.setFieldValue(
                  "ccmMedicationDtoList",
                  medications.filter((_, i) => i !== index)
                );
              }}
              style={{ color: "red", cursor: "pointer" }}
            />
          )}
        </Form.Item>
      ),
    },
  ];
  if (medications?.length === 0 && !multiStep) return <Empty />;
  return (
    <Spin spinning={isMedicationsLoading}>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <ErrorAlert />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Typography.Title level={4}>
              Does the Patient have a prescribed medication regimen?
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.Item name="hasPrescribedMedicationRegimen">
              <Radio.Group disabled={multiStep ? false : !isEditing}>
                <Radio value={true}>
                  <span style={{ color: "black", opacity: 1 }}>Yes</span>
                </Radio>
                <Radio value={false}>
                  <span style={{ color: "black", opacity: 1 }}>No</span>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {hasPrescribedMedicationRegimen && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Typography.Title level={4}>
                Please Provide an accurate and complete list of prescribed
                medications and dosages
              </Typography.Title>
            </Col>
            <Col span={24}>
              <Form.List name="ccmMedicationDtoList">
                {(fields, { add }) => (
                  <>
                    <StyledTable
                      dataSource={fields}
                      columns={columns}
                      pagination={false}
                      rowKey={(record) => record.key}
                      size="small"
                    />
                    {(multiStep || isEditing) && (
                      <Flex justify="end" gap="small" style={{ marginTop: 16 }}>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add New
                        </Button>
                      </Flex>
                    )}
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
        )}

        {multiStep ? (
          <StepControls isLoading={isCreating} onNextClick={form.submit} />
        ) : null}
      </Form>
    </Spin>
  );
};

export default MedicationsForm;
