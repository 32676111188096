import { useCreateAddUserMutation} from "@/store/features/patient/patientApiSlice"

const useCreateAddUser = () => {
  const [createAddUser, { isLoading, isSuccess, isError, data, error }] =
    useCreateAddUserMutation()

  const handleCreateAddUser = (body) => {
    console.log('body', body)
    createAddUser(body)
  }

  return { handleCreateAddUser, isLoading, isSuccess, isError, data, error }
}

export default useCreateAddUser
