import ErrorAlert from "@/components/ui/errorAlert";
import useCreateCareGiver from "@/hooks/patient/useCareGiverInfo";
import { PlusOutlined, UserDeleteOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Flex, Form, Input, Row, Select } from "antd";
import { useParams } from "react-router-dom";
import { StyledFormContainer } from "./Style";
export default function CareGiverInfo() {
  const { handleCreateCareGiver, isLoading } = useCreateCareGiver();
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const onFinish = (values) => {
    const payload = values?.careGiverInfo?.map((item) => {
      return {
        ...item,
        patientId: patientId,
      };
    });
    handleCreateCareGiver(payload);
  };

  return (
    <StyledFormContainer>
      <ErrorAlert />
      <Form
        name="careGiverInfo"
        onFinish={onFinish}
        layout="vertical"
        requiredMark="optional"
        form={form}
      >
        <Form.List name="careGiverInfo" initialValue={[{}]}>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row gutter={[16, 16]} key={field.key}>
                  <Col md={4} sm={12} xs={24}>
                    <Form.Item
                      {...field}
                      name={[field.name, "firstName"]}
                      label="First Name"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Name!",
                        },
                      ]}
                    >
                      <Input placeholder="First Name" />
                    </Form.Item>
                  </Col>
                  <Col md={4} sm={12} xs={24}>
                    <Form.Item
                      {...field}
                      name={[field.name, "lastName"]}
                      label="Last Name"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Last Name!",
                        },
                      ]}
                    >
                      <Input placeholder="Last Name" />
                    </Form.Item>
                  </Col>
                  <Col md={5} sm={12} xs={24}>
                    <Form.Item
                      {...field}
                      name={[field.name, "emailAddress"]}
                      label="Email"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Please Enter Email!",
                        },
                      ]}
                    >
                      <Input placeholder="abc@gmail.com" />
                    </Form.Item>
                  </Col>
                  <Col md={5} sm={12} xs={24}>
                    <Form.Item
                      {...field}
                      name={[field.name, "phoneNumber"]}
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number!",
                        },
                      ]}
                    >
                      <Input placeholder="Phone Number" />
                    </Form.Item>
                  </Col>
                  <Col md={4} sm={12} xs={24}>
                    <Form.Item
                      {...field}
                      name={[field.name, "relationshipTypeId"]}
                      label="Relationship"
                      rules={[
                        {
                          required: true,
                          message: "Please Select an option!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select"
                        options={[
                          { label: "Parent", value: 1 },
                          { label: "Sibling", value: 2 },
                          { label: "Spouse", value: 3 },
                          { label: "Child", value: 4 },
                          { label: "Grandparent", value: 5 },
                          { label: "Grandchild", value: 6 },
                          { label: "Partner", value: 7 },
                          { label: "Guardian", value: 8 },
                          { label: "Friend", value: 9 },
                          { label: "Other", value: 10 },
                        ]}
                        allowClear
                        showSearch
                      />
                    </Form.Item>
                  </Col>
                  <Col md={2} sm={12} xs={24}>
                    {index === fields.length - 1 ? (
                      <Flex align="middle" gap="10px">
                        <Button
                          danger
                          type="primary"
                          htmlType="button"
                          style={{
                            borderRadius: "20px",
                            padding: "7px",
                            marginTop: "29px",
                          }}
                          onClick={() => remove(field.name)}
                        >
                          <UserDeleteOutlined />
                        </Button>
                        <Button
                          type="primary"
                          htmlType="button"
                          style={{
                            borderRadius: "20px",
                            padding: "7px",
                            marginTop: "29px",
                          }}
                          onClick={() => add()}
                        >
                          <PlusOutlined />
                        </Button>
                      </Flex>
                    ) : (
                      <Button
                        danger
                        type="primary"
                        htmlType="button"
                        style={{
                          borderRadius: "20px",
                          padding: "7px",
                          marginTop: "29px",
                        }}
                        onClick={() => remove(field.name)}
                      >
                        <UserDeleteOutlined />
                      </Button>
                    )}
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List>

        <Divider />

        <Row gutter={11}>
          <Col span={3}>
            <Button style={{ borderRadius: "7px", padding: "2px 17px" }}>
              Previous
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              style={{ borderRadius: "7px", padding: "2px 24px" }}
              loading={isLoading}
              htmlType="submit"
            >
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    </StyledFormContainer>
  );
}
