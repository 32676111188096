import { useCreateProvidedDevicesMutation } from "@/store/features/patient/patientApiSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useCreateProvidedDevices = () => {
  const [
    createProvidedDevices,
    { isLoading, isSuccess, isError, data, error },
  ] = useCreateProvidedDevicesMutation();
  const navigate = useNavigate();
  const handleCreateProvidedDevices = (body) => {
    createProvidedDevices(body);
  };
  useEffect(() => {
    if (isSuccess) {
      navigate(`/dashboard`);
    }
  }, [isSuccess, navigate]);
  return {
    handleCreateProvidedDevices,
    isLoading,
    isSuccess,
    isError,
    data,
    error,
  };
};

export default useCreateProvidedDevices;
