import { Alert, Result } from 'antd'
import styled from 'styled-components'

export const StyledAlert = styled(Alert)`
  margin: 5rem auto;
  padding: 5rem auto;
`
export const StyleResult = styled(Result)`
.ant-result-subtitle{
font-weight: bold;
font-size: 1.25rem;
`
