import { Button, Table } from "antd";
import { StyledFormContainer } from "./styles";
import { Link, useParams } from "react-router-dom";
import { useGetDevicesByPatientIdQuery } from "@/store/features/patientDetails/patientDetailsApiSlice";
import dayjs from "dayjs";
import { useGetAllDevicesQuery } from "@/store/features/devices/deviceApiSlice";
import LoadingComponent from "@/components/LoadingComponent";
import EmptyState from "@/components/ui/empty/Empty";
const PatientDeviceDetails = () => {
  const { patientId } = useParams();

  const {
    data: devices,
    isLoading: isDevicesLoading,
    isSuccess: isDeviceSuccess
  } = useGetAllDevicesQuery({ pageNumber: 1, pageSize: 100 });
  const {
    data: mapDevice,
    isLoading,
    isSuccess
  } = useGetDevicesByPatientIdQuery({
    pageNumber: 1,
    pageSize: 10,
    patientId: patientId
  });

  const columns = [
    {
      title: (
        <span style={{ fontWeight: "bold", color: "#1677ff" }}>
          Device Name
        </span>
      ),
      dataIndex: "deviceName",
      key: "deviceName"
    },
    {
      title: (
        <span style={{ fontWeight: "bold", color: "#1677ff" }}>
          Device Type
        </span>
      ),
      dataIndex: "deviceType",
      key: "deviceType"
    },
    {
      title: (
        <span style={{ fontWeight: "bold", color: "#1677ff" }}>
          Assigned Date
        </span>
      ),
      dataIndex: "assignedDate",
      key: "assignedDate",
      render: (date) => <span> {dayjs(date).format("DD-MMM-YYYY")}</span>
    },
    {
      title: (
        <span style={{ fontWeight: "bold", color: "#1677ff" }}>
          First Transmission Date
        </span>
      ),
      dataIndex: "transmissionDate",
      key: "transmissionDate",
      render: (date) => <span> {dayjs(date).format("DD-MMM-YYYY")}</span>
    }
  ];

  let content = null;

  if (isDevicesLoading) {
    content = <LoadingComponent text={"  Device Table Loading...."} />;
  }

  if (isSuccess && mapDevice?.length === 0) {
    content = (
      <EmptyState link={`/patients/add/${patientId}/provided-devices`} />
    );
  }

  if (isDeviceSuccess && isSuccess) {
    const filteredDevices = mapDevice
      .map((mapItem) => {
        const matchingDevice = devices.find(
          (device) => device.deviceId === mapItem.deviceId
        );

        if (matchingDevice) {
          return {
            ...mapItem,
            deviceName: matchingDevice.deviceName,
            deviceType: matchingDevice.deviceType
          };
        }

        return null;
      })
      .filter((item) => item !== null);

    console.log(filteredDevices);

    content = (
      <div
        className="MainContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          paddingRight: "12px"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            width: "100%",
            alignItems: "right",
            marginTop: "23px"
          }}
        >
          <Link to={`/patients/${patientId}/provided-devices`}>
            <Button
              type="primary"
              style={{ padding: "7px", borderRadius: "12px" }}
            >
              Add New Device
            </Button>
          </Link>
        </div>

        {/* Table */}

        <StyledFormContainer>
          <Table
            loading={isLoading || isDevicesLoading}
            columns={columns}
            dataSource={filteredDevices}
            pagination={false}
            style={{ width: "100%" }}
          />
        </StyledFormContainer>
      </div>
    );
  }

  return content;
};

export default PatientDeviceDetails;
