import { useState, useEffect, useCallback, useRef } from "react";
import PubNub from "pubnub";
import { useSelector } from "react-redux";

const usePubNub = () => {
  const [pubnub, setPubnub] = useState(null);
  const [messages, setMessages] = useState([]);
  const [currentChannel, setCurrentChannel] = useState(null);
  const [error, setError] = useState(null);
  const currentUser =
    useSelector((state) => state.auth.username) || "defaultUser";
  const listenerRef = useRef(null);

  useEffect(() => {
    const publishKey = import.meta.env.VITE_PUBNUB_PUBLISH_KEY;
    const subscribeKey = import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY;
    const pubnubInstance = new PubNub({
      publishKey,
      subscribeKey,
      userId: currentUser,
    });

    setPubnub(pubnubInstance);

    return () => {
      if (pubnubInstance) {
        pubnubInstance.unsubscribeAll();
        if (listenerRef.current) {
          pubnubInstance.removeListener(listenerRef.current);
        }
      }
    };
  }, [currentUser]);

  const createChannel = useCallback(
    (otherUserId) => {
      return `chat_${[currentUser, otherUserId].sort().join("_")}`;
    },
    [currentUser]
  );

  const subscribeToChannel = useCallback(
    (channelName) => {
      if (pubnub) {
        // Unsubscribe from the current channel
        if (currentChannel) {
          pubnub.unsubscribe({ channels: [currentChannel] });
        }

        // Clear previous messages
        setMessages([]);

        // Set the new channel
        setCurrentChannel(channelName);

        // Subscribe to the new channel
        pubnub.subscribe({ channels: [channelName] });

        if (listenerRef.current) {
          pubnub.removeListener(listenerRef.current);
        }

        const listener = {
          message: (event) => {
            setMessages((prevMessages) => [...prevMessages, event.message]);
          },
          status: (statusEvent) => {
            if (statusEvent.category === "PNNetworkDownCategory") {
              setError(
                "Network is down. Messages will be sent when connection is restored."
              );
            }
          },
        };

        listenerRef.current = listener;
        pubnub.addListener(listener);
      }
    },
    [pubnub, currentChannel]
  );

  const sendMessage = useCallback(
    async (content, messageType = "text") => {
      if (pubnub && currentChannel) {
        try {
          await pubnub.publish({
            channel: currentChannel,
            message: {
              content,
              sender: currentUser,
              timestamp: new Date().toISOString(),
              type: messageType,
            },
          });
        } catch (e) {
          console.error(e);
          setError("Failed to send message. Please try again.");
        }
      }
    },
    [pubnub, currentChannel, currentUser]
  );

  const fetchHistory = useCallback(
    async (channelName) => {
      if (pubnub && channelName) {
        try {
          const result = await pubnub.fetchMessages({
            channels: [channelName],
            count: 100,
            reverse: true,
          });

          if (result.channels[channelName]) {
            setMessages(result.channels[channelName].map((msg) => msg.message));
          }
        } catch (e) {
          console.error(e);
          setError("Failed to fetch message history.");
        }
      }
    },
    [pubnub]
  );

  const uploadFile = useCallback(
    async (file) => {
      if (pubnub && currentChannel) {
        try {
          const result = await pubnub.sendFile({
            channel: currentChannel,
            file,
          });
          return result.file.url;
        } catch (err) {
          setError("Failed to upload file.");
          throw err;
        }
      }
    },
    [pubnub, currentChannel]
  );

  return {
    pubnub,
    messages,
    currentChannel,
    createChannel,
    subscribeToChannel,
    sendMessage,
    fetchHistory,
    uploadFile,
    error,
  };
};

export default usePubNub;
