import Icon from '@ant-design/icons'

const CustomIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
    ></path>
  </svg>
)

const FilterIcon = (props) => <Icon component={CustomIcon} {...props} />
export default FilterIcon



