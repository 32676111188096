import { useContext, useEffect, useState } from "react";
import { Form, Typography, Flex, Spin } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RichTextEditor from "@/components/shared/richTextEditor";
import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import {
  useGetCCMPatientHistoryQuery,
  useCreatePatientHistoryMutation
} from "@/store/features/ccm/ccmApiSlice";
import LoadingComponent from "@/components/LoadingComponent";
import EmptyState from "@/components/ui/empty/Empty";
import { CcmContext } from "../../withCcmData";

const PatientHistoryForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);
  const { date } = useContext(CcmContext);

  const {
    data: patientHistory,
    isLoading: isLoadingHistory,
    isSuccess
  } = useGetCCMPatientHistoryQuery({ patientId, date });

  const [createPatientHistory, { isLoading: isCreating }] =
    useCreatePatientHistoryMutation();

  useEffect(() => {
    if (patientHistory && patientHistory.length > 0) {
      form.setFieldsValue({
        patientHistoryDescription: patientHistory[0].patientHistoryDescription
      });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [patientHistory, form, multiStep]);

  const handleFinish = async (values) => {
    const payload = { ...values, patientId };

    await createPatientHistory({ ...payload });
    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  if (multiStep && currentStep !== 0) return null;

  let content = null;
  if (isLoadingHistory) {
    content = <LoadingComponent text="   Patient CCM history loading...." />;
  }
  if (isSuccess && patientHistory?.length === 0) {
    if (multiStep === true) {
      content = (
        <Form form={form} layout="vertical" onFinish={handleFinish}>
          <ErrorAlert />
          <Flex vertical gap={10}>
            <Typography.Title level={5} style={{ margin: "0px 15px" }}>
              Patient History
            </Typography.Title>

            <Spin spinning={isLoadingHistory}>
              <RichTextEditor
                form={form}
                keyName="patientHistoryDescription"
                sx={{ marginBottom: "30px" }}
                readOnly={false}
              />
            </Spin>
          </Flex>
          {multiStep && (
            <StepControls isLoading={isCreating} onNextClick={form.submit} />
          )}
        </Form>
      );
    } else {
      content = <EmptyState />;
    }
  }
  if (isSuccess && patientHistory?.length !== 0) {
    content = (
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <ErrorAlert />
        <Flex vertical gap={10}>
          <Typography.Title level={5} style={{ margin: "0px 15px" }}>
            Patient History
          </Typography.Title>

          <Spin spinning={isLoadingHistory}>
            <RichTextEditor
              form={form}
              keyName="patientHistoryDescription"
              initialValue={
                !multiStep
                  ? patientHistory?.[patientHistory?.length - 1]
                      ?.patientHistoryDescription || ""
                  : false
              }
              sx={{ marginBottom: "30px" }}
              readOnly={multiStep ? false : !isEditing}
            />
          </Spin>
          {multiStep && (
            <StepControls isLoading={isCreating} onNextClick={form.submit} />
          )}
        </Flex>
      </Form>
    );
  }

  return content;
};

export default PatientHistoryForm;
