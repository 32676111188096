import {
  GET_PATIENT_BILL,
  GET_PATIENT_LIST,
  GET_PATIENT_DEVICE,
  GET_PATIENT_BILL_REPORT
} from "@/constants/apiDefinition";
import { apiSlice } from "@/store/features/api/apiSlice";

export const reportApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatientsReport: builder.query({
      query: () => ({
        url: GET_PATIENT_BILL,
        method: "GET",
        responseHandler: (response) => response.blob(),
        headers: {
          Accept:
            "application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      })
    }),
    getPatientBillingReport: builder.query({
      query: () => ({
        url: GET_PATIENT_BILL_REPORT,
        method: "GET",
        responseHandler: (response) => response.blob(),
        headers: {
          Accept:
            "application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      })
    }),
    getPatientsListReport: builder.query({
      query: () => ({
        url: GET_PATIENT_LIST,
        method: "GET",
        responseHandler: (response) => response.blob(),
        headers: {
          Accept:
            "application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      })
    }),
    getPatientDeviceReport: builder.query({
      query: () => ({
        url: GET_PATIENT_DEVICE,
        method: "GET",
        responseHandler: (response) => response.blob(),
        headers: {
          Accept:
            "application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        }
      })
    })
  })
});

export const {
  useGetPatientDeviceReportQuery,
  useGetPatientsListReportQuery,
  useGetPatientsReportQuery,
  useGetPatientBillingReportQuery
} = reportApi;
