import { useCreateRpmSetupMutation } from "@/store/features/patient/patientApiSlice";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const useCreateRpmSetup = () => {
  const [isRtm, setIsRtm] = useState(false);
  const [createRpmSetup, { isLoading, isSuccess, isError, data, error }] =
    useCreateRpmSetupMutation();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const { management } = useSelector((state) => state.addPatient);

  const getRedirectUrl = () => {
    if (management?.ccm) {
      navigate(`/patients/add/${patientId}/ccm-setup`);
    } else if (management?.pcm) {
      navigate(`/patients/add/${patientId}/pcm-setup`);
    } else if (management?.rtm) {
      navigate(`/patients/add/${patientId}/rtm-setup`);
    } else {
      navigate(`/patients/add/${patientId}/caregiver-info`);
    }
  };

  const handleCreateRpmSetup = (body) => {
    console.log("body", body);
    if (body?.rpm_RtmData?.[0]?.isRTM) {
      setIsRtm(true);
    } else {
      setIsRtm(false);
    }
    createRpmSetup(body);
  };

  useEffect(() => {
    if (isSuccess) {
      if (isRtm) {
        navigate(`/patients/add/${patientId}/caregiver-info`);
        return;
      }
      navigate(getRedirectUrl());
    }
  }, [isSuccess, navigate]);

  return { handleCreateRpmSetup, isLoading, isSuccess, isError, data, error };
};

export default useCreateRpmSetup;
