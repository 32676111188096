import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import {
  useCreateCCMMonitoringMutation,
  useGetCCMMonitoringQuery,
} from "@/store/features/ccm/ccmApiSlice";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import { Checkbox, Flex, Form, Space, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RPMType } from "../constant";
import LoadingComponent from "@/components/LoadingComponent";
import EmptyState from "@/components/ui/empty/Empty";
import { CcmContext } from "../../withCcmData";

const MonitoringForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);

  const { date } = useContext(CcmContext);

  const {
    data: monitoring,
    isLoading: isMonitoringLoading,
    isSuccess,
  } = useGetCCMMonitoringQuery({
    date,
    patientId,
    pageNumber: 1,
    pageSize: 100,
  });
  const [createMonitoring, { isLoading: isCreating }] =
    useCreateCCMMonitoringMutation();

  useEffect(() => {
    if (monitoring) {
      const monitoringList = monitoring.map((item) => item.rpmTypeId);
      form.setFieldsValue({ monitoringList, isInitial: false });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [monitoring, form, multiStep]);

  const handleFinish = async () => {
    const values = form.getFieldsValue(true);
    const payload = {
      ccmMonitoringList:
        values.monitoringList?.map((rpmTypeId) => ({ rpmTypeId })) || [],
      patientId,
    };

    await createMonitoring(payload);

    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  if (multiStep && currentStep !== 6) return null;

  let content = null;
  if (isMonitoringLoading) {
    content = <LoadingComponent text="  Patient Monitoring Info Loading ..." />;
  }

  if (isSuccess && monitoring?.length === 0 && !multiStep) {
    content = <EmptyState />;
  }

  if (monitoring?.length !== 0 && isSuccess) {
    content = (
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <ErrorAlert />
        <Flex vertical gap={10} style={{ margin: "0px 15px" }}>
          <Typography.Title level={5}>
            What Physiological and Non-Physiological parameters need to be
            monitored?
          </Typography.Title>

          <Form.Item name="monitoringList" style={{ margin: "0px 15px" }}>
            <Checkbox.Group disabled={multiStep ? false : !isEditing}>
              <Space direction="vertical">
                {RPMType?.map((option) => (
                  <Checkbox key={option.value} value={option.value}>
                    <span style={{ color: "black", opacity: 1 }}>
                      {option.label}
                    </span>
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </Form.Item>
        </Flex>

        {multiStep ? (
          <StepControls isLoading={isCreating} onNextClick={form.submit} />
        ) : null}
      </Form>
    );
  }

  return content;
};

export default MonitoringForm;
