import styled from "styled-components";
import { Typography } from "antd";

const { Text } = Typography;

const MessagesContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const MessageBubble = styled.div`
  max-width: 70%;
  padding: 10px 15px;
  border-radius: 18px;
  margin-bottom: 10px;
  ${({ isUser }) =>
    isUser
      ? `
      align-self: flex-end;
      background-color: #1890ff;
      color: white !important;
    `
      : `
      align-self: flex-start;
      background-color: #d9d9d9;
      color: rgba(0, 0, 0, 0.85);
    `}
`;

const StyledText = styled(Text)`
  color: ${(props) => (props?.isUser ? "white" : "black")};
`;
const ChatMessages = ({ messages }) => {
  return (
    <MessagesContainer>
      {messages.map((message, index) => (
        <MessageBubble key={index} isUser={message.type === "user"}>
          <StyledText isUser={message.type === "user"}>
            {message.content}
          </StyledText>
        </MessageBubble>
      ))}
    </MessagesContainer>
  );
};

export default ChatMessages;
