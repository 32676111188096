import { Form, Radio, Space } from "antd";
import { StyledContainer, StyledParagraph, StyledTitle } from "./Style";
const SmsReminder = () => {
  return (
    <StyledContainer>
      <StyledTitle level={4}>SMS Reminder Settings</StyledTitle>
      <StyledParagraph>
        Please select the phone number to be visible during SMS and Audio Call
        to patient
      </StyledParagraph>

      <Space direction="vertical" size="large" style={{ width: "100%" }}>
        <Form.Item
          label="Do you want to enable daily SMS reminder for your patient"
          name="isSMSreminderForPatientEnabled"
          required
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          required
          label="Do you want to enable New Patients Sign-Up SMS for your Patients"
          name="isSMSforNewPatientSignedUpEnabled"
        >
          <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </Form.Item>
      </Space>
    </StyledContainer>
  );
};

export default SmsReminder;
