const getRpmPayload = (values, healthConditionId, isRtm = false) => {
  const rpm_RtmData = values.setupOptions.flatMap((option) => {
    const basePayload = {
      rpmTypeId: option,
      isRTM: false,
    };
    const getPayload = (dataKey, notesKey, frequencyKey) => ({
      ...basePayload,
      critMin: values[dataKey]?.[0],
      min: values[dataKey]?.[1],
      max: values[dataKey]?.[2],
      critMax: values[dataKey]?.[3],
      inteventionNote: values[notesKey] || "NA",
      frequencyTypeId: values[frequencyKey] || 1,
      isRTM: isRtm,
    });

    switch (option) {
      case 1: // Blood Pressure
        return [
          {
            ...getPayload(
              "systolic",
              "interventionNoteForBloodPressure",
              "frequencyTypeIdForBloodPressure"
            ),
            bloodPressureTypeId: 1,
          },
          {
            ...getPayload(
              "diastolic",
              "interventionNoteForBloodPressure",
              "frequencyTypeIdForBloodPressure"
            ),
            bloodPressureTypeId: 2,
          },
        ];
      case 2: // Pulse
        return [
          getPayload(
            "pulse",
            "interventionNotesForPulse",
            "frequencyTypeIdOfPulse"
          ),
        ];
      case 3: // Weight
        return [
          getPayload(
            "weight",
            "interventionNotesForWeight",
            "frequencyTypeIdOfWeight"
          ),
        ];
      case 4: // SpO2
        return [
          getPayload(
            "spo2",
            "interventionNotesForSp02",
            "frequencyTypeIdOfSpo2"
          ),
        ];
      case 5: // Glucose
        return [
          getPayload(
            "glucose",
            "interventionNotesForGlucose",
            "frequencyTypeIdOfGlucose"
          ),
        ];
      case 6: // Temperature
        return [
          getPayload(
            "temperature",
            "interventionNotesForTemperature",
            "frequencyTypeIdOfTemperature"
          ),
        ];
      default:
        return [];
    }
  });
  return {
    rpm_RtmData,
    healthConditionId: healthConditionId,
  };
};

export default getRpmPayload;
