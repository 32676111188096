import Icon from '@ant-design/icons'

const CustomIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <path
      fill="transparent"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M17.25 2.25H6.75a4.5 4.5 0 00-4.5 4.5v10.5a4.5 4.5 0 004.5 4.5h10.5a4.5 4.5 0 004.5-4.5V6.75a4.5 4.5 0 00-4.5-4.5z"
    ></path>
    <path
      fill="transparent"
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M18.232 7.104A9.15 9.15 0 0012 4.695a9.15 9.15 0 00-6.232 2.41 1.625 1.625 0 00-.143 2.253l1.562 1.838a1.222 1.222 0 001.575.244c.746-.46 1.918-1.014 3.239-1.014s2.493.553 3.239 1.014a1.222 1.222 0 001.575-.244l1.56-1.838a1.624 1.624 0 00-.143-2.254z"
    ></path>
  </svg>
)

const WeightIcon = (props) => <Icon component={CustomIcon} {...props} />
export default WeightIcon
