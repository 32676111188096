import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Switch,
  Typography,
} from "antd";
import { StyledFormContainer } from "./Style";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setManagement } from "@/store/features/patient/addPatientSlice";
import useCreateHealthCondition from "@/hooks/patient/useHealthCondition";
import { useParams } from "react-router-dom";
import ErrorAlert from "@/components/ui/errorAlert";
const { Option } = Select;

export default function HealthConditions() {
  const { handleCreateHealthCondition, isLoading } = useCreateHealthCondition();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { patientId } = useParams();
  const consentReceived = Form.useWatch("patientConsentReceived", {
    form: form,
  });
  const managementMethods = Form.useWatch("activityFor", {
    form: form,
  });
  const onFinish = (values) => {
    const payload = {
      ...values,
      patientId: patientId,
      hasActivityRPM: values?.activityFor?.includes("RPM"),
      hasActivityCCM: values?.activityFor?.includes("CCM"),
      hasActivityPCM: values?.activityFor?.includes("PCM"),
      hasActivityRTM: values?.activityFor?.includes("RTM"),
    };
    handleCreateHealthCondition(payload);
  };

  useEffect(() => {
    const payload = {
      rpm: managementMethods?.includes("RPM"),
      ccm: managementMethods?.includes("CCM"),
      pcm: managementMethods?.includes("PCM"),
      rtm: managementMethods?.includes("RTM"),
    };
    dispatch(setManagement(payload));
  }, [managementMethods, dispatch]);

  return (
    <StyledFormContainer>
      <Form
        name="healthConditions"
        onFinish={onFinish}
        layout="vertical"
        requiredMark="optional"
        form={form}
      >
        {/* PatientContent Switch & Date */}
        <ErrorAlert />
        <Row gutter={[16, 16]} align="middle">
          <Col xs={24} sm={12}>
            <div style={{ display: "flex", alignItems: "start" }}>
              <span
                className="ant-form-item-label"
                style={{ marginRight: "19px", paddingTop: "5px" }}
              >
                Patient Consent Received
              </span>
              <Form.Item
                name="patientConsentReceived"
                initialValue={false}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <span style={{ marginLeft: "12px", paddingTop: "5px" }}>
                {consentReceived ? "Yes" : "No"}
              </span>
            </div>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="patientConsentReceivedDate"
              label="Patient Consent Received Date"
              rules={[
                {
                  required: true,
                  message: "Please select the date!",
                },
              ]}
            >
              <DatePicker placeholder="Select Date" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        {/* Activity For Buttons */}

        <Row gutter={11} style={{ marginTop: "10px" }}>
          <Col span={24}>
            <Form.Item
              name="activityFor"
              label="Activity For"
              valuePropName="checked"
            >
              <Checkbox.Group
                options={[
                  { label: "RPM", value: "RPM" },
                  { label: "CCM", value: "CCM" },
                  { label: "PCM", value: "PCM" },
                  { label: "RTM", value: "RTM" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Locations*/}

        <Col span={8}>
          <Form.Item
            name="location"
            label="Location(s)"
            rules={[
              {
                required: true,
                message: "Please Select an option!",
              },
            ]}
          >
            <Select placeholder="location">
              <Option value="pakistan">Pakistan</Option>
              <Option value="Bangladesh">Bangladesh</Option>
            </Select>
          </Form.Item>
        </Col>

        {/* {Assigned To} */}
        <Typography.Text>Assigned To</Typography.Text>
        <Row gutter={16} style={{ marginTop: "10px" }}>
          <Col span={8}>
            <Form.Item
              name="careCoordinator"
              rules={[
                {
                  required: true,
                  message: "Please select the Option!",
                },
              ]}
            >
              <Select placeholder="Select Care Co-ordinator">
                <Option value="self">Self</Option>
                <Option value="Other">Other</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="provider"
              rules={[
                {
                  required: true,
                  message: "Please select Provider!",
                },
              ]}
            >
              <Select placeholder="Provider">
                <Option value="physician">Physician</Option>
                <Option value="therapist">Therapist</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Divider */}

        <Divider />

        {/* Cancel & Submit Button */}
        <Row gutter={11}>
          <Col span={3}>
            <Form.Item>
              <Button style={{ borderRadius: "7px", padding: "2px 17px" }}>
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button
                type="primary"
                style={{ borderRadius: "7px", padding: "2px 24px" }}
                htmlType="submit"
                loading={isLoading}
              >
                Next
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledFormContainer>
  );
}
