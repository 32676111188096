import React from 'react';
import { Switch, Table, Avatar} from 'antd';
import { EditOutlined, EditTwoTone,  UserOutlined } from '@ant-design/icons';


const onChange = (checked) => {
  console.log(`switch to ${checked}`);
};
const columns = [
  {
    title: 'User Name',
    dataIndex: 'name',
    key: 'name',
    width: 190,
    render:(text) => <span><Avatar icon={<UserOutlined/>} style={{marginRight:'4px'}}></Avatar>{text}</span>
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Mobile',
    dataIndex: 'mobile',
    key: 'mobile',

  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',

  },
  {
    title: 'Clinical Type Tracking',
    dataIndex: 'tracking',
    key: 'tracking',
  },
  {

    title: 'Hight alert Notification',
    dataIndex: 'alert',
    key: 'alert',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render:(action) => <span
     style={{display:'flex',gap:'9px',fontSize:'17px',paddingLeft:'5px'}}
     >
      <EditTwoTone style={{fontSize:'22px'}}/>
      <Switch defaultChecked onChange={onChange} />

    </span>
  },
];
const data = [
  {
    key: '1',
    name: 'John Brown',
    email: 'abcToxyz@gmail.com',
    mobile: '+92-479373272',
    type:'Nurse',
    tracking:'Yes',
    alert:'No',
  },
  {
    key: '2',
    name: 'James Chadwick',
    email: 'abcToxyz@gmail.com',
    mobile: '+92-379372052',
    type:'Nurse',
    tracking:'Yes',
    alert:'No',
  },
  {
    key: '3',
    name: 'Davin',
    email: 'davinGeroge@gmail.com',
    mobile: '+92-4285624272',
    type:'Patient',
    tracking:'Yes',
    alert:'No',
  },
  {
    key: '4',
    name: 'George Brown',
    email: 'abcToxyz@gmail.com',
    mobile: '+92-427274272',
    type:'Dcotor',
    tracking:'Yes',
    alert:'No',
  },
  {
    key: '5',
    name: 'John Edwin',
    email: 'Xyz@gmail.com',
    mobile: '+92-479274272',
    type:'Nurse',
    tracking:'Yes',
    alert:'No',
  },
  {
    key: '6',
    name: 'John Brown',
    email: 'abcToxyz@gmail.com',
    mobile: '+92-479274272',
    type:'Nurse',
    tracking:'Yes',
    alert:'No',
  },
  {
    key: '7',
    name: 'James Chadwick',
    email: 'abcToxyz@gmail.com',
    mobile: '+92-379372052',
    type:'Nurse',
    tracking:'Yes',
    alert:'No',
  },
  
];
const TableContent= () => <Table columns={columns} dataSource={data} style={{width:'100%'}} pagination={true}  />;
export default TableContent;