import {
  CREATE_DEVICE_URL,
  UPDATE_DEVICE_URL,
  GET_DEVICE_BY_ID_URL,
  GET_ALL_DEVICES_URL,
  GET_MIOLAB_DEVICE_URL,
  CREATE_DEVICE_ORDER_URL,
  GET_ALL_DEVICE_ORDERS_URL,
  DELETE_DEVICE_ORDERS_URL
} from "@/constants/apiDefinition";
import { apiSlice } from "@/store/features/api/apiSlice";

import { setDevices } from "./deviceSlice";

export const deviceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createDevice: builder.mutation({
      query: (data) => ({
        url: CREATE_DEVICE_URL,
        method: "POST",
        body: data
      }),
      invalidatesTags: [{ type: "Devices", id: "LIST" }]
    }),

    createDeviceOrder: builder.mutation({
      query: (data) => ({
        url: CREATE_DEVICE_ORDER_URL,
        method: "POST",
        body: data
      }),
      invalidatesTags: [{ type: "DeviceOrders", id: "LIST" }]
    }),

    updateDevice: builder.mutation({
      query: (data) => ({
        url: UPDATE_DEVICE_URL,
        method: "PUT",
        body: data
      }),
      invalidatesTags: [{ type: "Devices", id: "LIST" }]
    }),

    getAllDevices: builder.query({
      query: ({ pageNumber = 1, pageSize = 50 }) => ({
        url: GET_ALL_DEVICES_URL,
        method: "GET",
        params: { pageNumber, pageSize }
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setDevices(data));
        } catch (error) {
          console.log(error);
        }
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Devices", id })),
              { type: "Devices", id: "LIST" }
            ]
          : [{ type: "Devices", id: "LIST" }]
    }),

    getSingleDevice: builder.query({
      query: (id) => ({
        url: `${GET_DEVICE_BY_ID_URL}/${id}`,
        method: "GET"
      }),
      providesTags: (result, error, id) => [{ type: "Devices", id }]
    }),
    getMiolabDevices: builder.query({
      query: () => {
        return {
          url: GET_MIOLAB_DEVICE_URL,
          method: "GET",
          headers: {
            "x-api-key": import.meta.env.VITE_MIOLAB_API_KEY
          }
        };
      }
    }),

    deleteDevice: builder.mutation({
      query: (id) => {
        return {
          url: `${GET_DEVICE_BY_ID_URL}/${id}`,
          method: "DELETE",
          providesTags: ["Device"]
        };
      },
      invalidatesTags: [{ type: "Devices", id: "LIST" }]
    }),

    getAllDeviceOrders: builder.query({
      query: ({ pageNumber = 1, pageSize = 50 }) => ({
        url: GET_ALL_DEVICE_ORDERS_URL,
        method: "GET",
        params: { pageNumber, pageSize }
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "DeviceOrders", id })),
              { type: "DeviceOrders", id: "LIST" }
            ]
          : [{ type: "DeviceOrders", id: "LIST" }]
    }),
    deleteDeviceOrders: builder.mutation({
      query: (id) => ({
        url: `${DELETE_DEVICE_ORDERS_URL}/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: [{ type: "DeviceOrders", id: "LIST" }]
    })
  })
});

export const {
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useGetAllDevicesQuery,
  useGetSingleDeviceQuery,
  useDeleteDeviceMutation,
  useGetMiolabDevicesQuery,
  useCreateDeviceOrderMutation,
  useGetAllDeviceOrdersQuery,
  useDeleteDeviceOrdersMutation
} = deviceApi;
