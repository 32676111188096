import React from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const BloodPressureScatterPlot = ({ data }) => {
  const formatData = data.map((entry) => ({
    systolic: entry.bloodPressureSystolic,
    diastolic: entry.bloodPressureDiastolic,
    date: new Date(entry.createdAt).toLocaleDateString(),
  }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ScatterChart
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid />
        <XAxis
          type="number"
          dataKey="systolic"
          name="Systolic"
          unit="mmHg"
          domain={["dataMin - 10", "dataMax + 10"]}
        />
        <YAxis
          type="number"
          dataKey="diastolic"
          name="Diastolic"
          unit="mmHg"
          domain={["dataMin - 10", "dataMax + 10"]}
        />
        <Tooltip cursor={{ strokeDasharray: "3 3" }} content={CustomTooltip} />
        <Scatter name="Blood Pressure" data={formatData} fill="#8884d8" />
      </ScatterChart>
    </ResponsiveContainer>
  );
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        style={{
          backgroundColor: "white",
          padding: "5px",
          border: "1px solid #ccc",
        }}
      >
        <p>{`Date: ${data.date}`}</p>
        <p>{`Systolic: ${data.systolic} mmHg`}</p>
        <p>{`Diastolic: ${data.diastolic} mmHg`}</p>
      </div>
    );
  }
  return null;
};

export default BloodPressureScatterPlot;
