export const DASHBOARD_PATH = "/dashboard";
export const DEVICES_PATH = "/device-list";
export const DEVICES_ORDER_PATH = "/device-order-list";

export const REPORTS_PATH = "/reports";
export const MESSAGES_PATH = "/messages";
export const SEND_MESSAGE_PATH = "/messages/:receiver";
export const SIGN_UP_PATH = "/signUp";
export const LOGIN_PATH = "/login";
export const RESET_PASSWORD_PATH = "/reset-password";
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const VERIFY_PHONE_PATH = "/verify-phone";
export const FILTER_PATH = "/filter";
export const DASHBOARD_NOTES_COMPONENT = "/dashboardNotes";
export const CARE_APPROVAL_FORM = "/careApproval";
export const ADD_USER = "/addUser";
export const COLLAPSE_FORM = "/collapseForm";
export const CREATE_NEW_ORDER = "/createNewOrder";
export const PATIENT_DETAILS = "/patientDetails";
export const PATIENT_DETAILS_ASSESMENTS = "/patientAssesments";
export const PATIENT_DETAILS_PULSE = "/patientPulse";
export const PATIENT_DETAILS_CARE_PLAN = "/patientCarePlan";
export const PATIENT_DETAILS_WEIGHT = "/patientWeight";
export const PATIENT_DETAILS_BLOOD_PRESSURE = "/patientBP";
export const PATIENT_DETAILS_TIME_LOG = "/patientTime";
export const PATIENT_DETAILS_DEVICES = "/patientDevices";
export const PATIENT_CHRONIC_GOALS = "/chronicGoals";
export const PATIENT_CHRONIC_BARRIERS = "/chronicBarriers";
export const PATIENT_CHRONIC_SYMPTOMS = "/chronicSymptoms";
export const PATIENT_CHRONIC_ALLERGIES = "/chronicAllergies";
export const PATIENT_CHRONIC_RISK = "/chronicRisk";
export const PATIENT_TASK_POPUP = "/taskPopup";
export const PATIENT_NOTIFICATION = "/notifications";
export const PATIENT_DETAILS_SPO2 = "/patientSpO2";
export const PATIENT_DETAILS_GLUCOSE = "/patientGlucose";
export const PATIENT_DETAILS_TEMPERATURE = "/patientTemperature";
export const PATIENT_DUMMY_DATA = "patientDummyData";
export const PATIENT_USER_DETAIL = "patientUserDetail";
export const PATIENT_GLOBAL_SETTINGS = "patientGlobalSettings";
export const PATIENT_CCM_DASHBOARD = "patientCcmDashboard";
export const GLOBAL_SETTINGS = "global-settings";

export const PATIENTS_PATH = "/patients";
export const ADD_PATIENT_PATH = "patients/add";
export const ADD_PATIENT_DETAILS_PATH = "patients/add/:patientId/:tabKey";
export const PATIENT_DETAILS_PARENT_PATH = "patients/:patientId";
export const PATIENT_DETAILS_PATH = "details";
export const PATIENT_DETAILS_CARE_PLAN_PATH = "care-plan";
export const PATIENT_DETAILS_BLOOD_PRESSURE_PATH = "blood-pressure";
export const PATIENT_DETAILS_PULSE_PATH = "pulse";
export const PATIENT_DETAILS_WEIGHT_PATH = "weight";
export const PATIENT_DETAILS_SPO2_PATH = "spo2";
export const PATIENT_DETAILS_GLUCOSE_PATH = "glucose";
export const PATIENT_DETAILS_TEMPERATURE_PATH = "temperature";
export const PATIENT_DETAILS_ASSESSMENT_PATH = "assessment";
export const PATIENT_DETAILS_DEVICES_PATH = "devices";
export const PATIENT_DETAILS_TIMELOG_PATH = "timelog";
export const PATIENT_DETAILS_ATTACHMENTS_PATH = "attachments";
export const PATIENT_PROVIDED_DEVICES_PATH = "provided-devices";
