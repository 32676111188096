import {
  useAddPatientTaskMutation,
  useUpdatePatientTaskMutation,
} from "@/store/features/patientTasks/patientTaskApiSlice";
import { Button, DatePicker, Flex, Form, Input, message, Select } from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
const { Option } = Select;

const TaskForm = ({ patientId, onSuccess, prevData = null }) => {
  const [form] = Form.useForm();
  const [addTask, { isLoading }] = useAddPatientTaskMutation();
  const [updateTask, { isLoading: updateLoading }] =
    useUpdatePatientTaskMutation();
  const handleSubmit = async (values) => {
    try {
      let response;
      if (prevData) {
        response = await updateTask({ ...prevData, ...values, patientId });
      } else {
        response = await addTask({ ...values, patientId });
      }
      if (!response?.error) {
        form.resetFields();
        onSuccess();
      } else {
        message.error("Failed to add task");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to add task");
    }
  };

  useEffect(() => {
    form.setFieldsValue({ ...prevData, dueDate: dayjs(prevData?.dueDate) });
  }, [prevData, form]);
  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Form.Item
        name="title"
        rules={[{ required: true, message: "Please enter task title" }]}
      >
        <Input placeholder="Enter task title" />
      </Form.Item>
      <Form.Item
        name="status"
        rules={[{ required: true, message: "Please select status" }]}
      >
        <Select placeholder="Select status">
          <Option value="Pending">Pending</Option>
          <Option value="In Progress">In Progress</Option>
          <Option value="Completed">Completed</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="dueDate"
        rules={[{ required: true, message: "Please select due date" }]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>
      <Flex justify="end" gap="10px">
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading || updateLoading}
        >
          {prevData ? "Update" : "Create"}
        </Button>
      </Flex>
    </Form>
  );
};

export default TaskForm;
