import styled from "styled-components";
import { Steps, Modal } from "antd";

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 12px;
    max-height: calc(100vh - 200px);
    overflow-y: auto;

    /* Webkit browsers (Chrome, Safari) */
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    /* Firefox */
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;

    /* IE and Edge */
    -ms-overflow-style: none;

    /* General styles for future compatibility */
    scrollbar-width: thin;

    /* For Safari on macOS */
    @supports (scrollbar-width: thin) {
      scrollbar-width: thin;
      scrollbar-color: #888 #f1f1f1;
    }
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  height: 100%;
`;

export const StepperContainer = styled.div`
  width: 200px;
  padding: 12px;
  border-right: 1px solid #f0f0f0;
  overflow-y: auto;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  padding: 12px;
  overflow-y: auto;
`;

export const StyledSteps = styled(Steps)`
  .ant-steps-item-container {
    align-items: flex-start;
  }

  .ant-steps-item-title {
    font-size: 12px;
    padding-right: 8px;
  }

  .ant-steps-item-tail {
    padding: 0 8px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;
