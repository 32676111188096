/**
 * Generates a full name string from given name parts.
 *
 * @param {string} firstName - The person's first name.
 * @param {string} middleName - The person's middle name.
 * @param {string} [lastName] - The person's last name (optional).
 * @returns {string} The generated full name.
 * @throws {Error} If firstName or middleName is missing or empty.
 */
const getFullName = (firstName = "", middleName = "", lastName = "") => {
  // Trim all name parts to remove any leading or trailing spaces
  const trimmedFirstName = firstName.trim();
  const trimmedLastName = lastName.trim();
  const trimmedMiddleName = middleName ? middleName.trim() : "";
  // Construct the full name
  if (trimmedLastName) {
    return `${trimmedFirstName} ${trimmedMiddleName} ${trimmedLastName}`;
  } else {
    return `${trimmedFirstName} ${trimmedMiddleName}`;
  }
};

export default getFullName;
