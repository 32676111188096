import PatientNotesAndTasks from "./PatientNotesAndTasks";
import { Flex } from "antd";
import PatientDetailsSidebar from "./PatientDetailsSidebar";
export default function PatientDetails() {
  return (
    <Flex>
      <PatientDetailsSidebar />
      <PatientNotesAndTasks />
    </Flex>
  );
}
