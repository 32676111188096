import { apiSlice } from '@/store/features/api/apiSlice'
import { setAuth } from '@/store/features/auth/authSlice'

const authMiddleware = (baseQueryApi) => {
  return async (args, api, extraOptions) => {
    const response = await baseQueryApi(args, api, extraOptions)
    if (response.error && response.error.status === 401) {
      localStorage.removeItem('token')
      import('@/store/store').then(({ store }) => {
        localStorage.removeItem('token')
        store.dispatch(apiSlice.util.resetApiState())
        store.dispatch(setAuth({}))
      })
    }

    return response
  }
}
export default authMiddleware
