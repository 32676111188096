import { Button, Col, DatePicker, Divider, Form, Row, Select } from 'antd'
import { StyledFormContainer } from './Style'
const { Option } = Select

export default function FileterForm() {
  const onFinish = (values) => {
    console.log('Received values of form: ', values)
  }

  return (
    <StyledFormContainer>
      <Form
        name="filter_form"
        onFinish={onFinish}
        layout="vertical"
        requiredMark="optional"
      >
        {/* Communication,Compliance ,location */}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="communication"
              label="Intractive Communication"
              rules={[
                {
                  required: true,
                  message: 'Please select the communication!',
                },
              ]}
            >
              <Select placeholder="Intractive Communication">
                <Option value="normalCommunication">Normal</Option>
                <Option value="mediumCommunication">Medium</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="compliance"
              label="Active Days Compliance"
              rules={[
                {
                  required: true,
                  message: 'Please select Compliance!',
                },
              ]}
            >
              <Select placeholder="Select Compliance">
                <Option value="motivated">Motivated</Option>
                <Option value="notMotivated">Not Motivated</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="location"
              label="Location(s)"
              rules={[
                {
                  required: true,
                  message: 'Please select users!',
                },
              ]}
            >
              <Select placeholder="Select Location">
                <Option value="maleUser">Male</Option>
                <Option value="femaleUser">Female</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Condition,Payer ,User */}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="condition"
              label="All Chronic Conditions"
              rules={[
                {
                  required: true,
                  message: 'Please select the condition!',
                },
              ]}
            >
              <Select placeholder="Select Conditions">
                <Option value="normalConditions">Normal</Option>
                <Option value="mediumConditions">Medium</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="payers"
              label="All Payers"
              rules={[
                {
                  required: true,
                  message: 'Please select Payers!',
                },
              ]}
            >
              <Select placeholder="Select payers">
                <Option value="payerOne">One</Option>
                <Option value="payerTwo">Two</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="users"
              label="All Users"
              rules={[
                {
                  required: true,
                  message: 'Please select a user!',
                },
              ]}
            >
              <Select placeholder="Select Users">
                <Option value="maleUser">Male</Option>
                <Option value="femaleUser">Female</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Alert,Time,Appointment */}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="alerts"
              label="Alerts"
              rules={[
                {
                  required: true,
                  message: 'Please select the alert!',
                },
              ]}
            >
              <Select placeholder="Choose Alert">
                <Option value="highAlert">Hight</Option>
                <Option value="normalAlert">Normal</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="timeLong"
              label="Time Long"
              rules={[
                {
                  required: true,
                  message: 'Please select the date!',
                },
              ]}
            >
              <DatePicker placeholder="Select Date" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="appointment"
              label="Appointment Filter"
              rules={[
                {
                  required: true,
                  message: 'Please select an Appointment!',
                },
              ]}
            >
              <Select placeholder="Appointment Filter">
                <Option value="maleAppointment">Male Appointment</Option>
                <Option value="femaleAppoinment">Female Appointment</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Notification filter */}

        <Col span={8}>
          <Form.Item
            name="notification"
            label="Notification Filter"
            rules={[
              {
                required: true,
                message: 'Please select an notification filter!',
              },
            ]}
          >
            <Select placeholder="Notificaiton Filter">
              <Option value="first">First</Option>
              <Option value="second">Second</Option>
            </Select>
          </Form.Item>
        </Col>

        <Divider />

        {/* Submit Button */}
        <Row gutter={16}>
          <Col span={3}>
            <Form.Item>
              <Button type="" htmlType="submit">
                Clear All
              </Button>
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Filter & Search
              </Button>
            </Form.Item>
          </Col>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </StyledFormContainer>
  )
}
