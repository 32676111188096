import InfiniteScroll from "react-infinite-scroll-component";
import { List, Avatar, Empty } from "antd";
import { StyledList, LastMessageTime } from "./Style";

const PatientList = ({
  patientList,
  totalCount,
  isLoadingPatients,
  loadMorePatients,
  handlePersonSelect,
  userName,
}) => {
  const isListEmpty = patientList.length === 0 && !isLoadingPatients;

  let defaultDoctor = {
    patientId: 1,
    mrn: "doctor",
    firstName: userName === "admin" ? "mohamed" : "admin",
    middleName: "James",
    lastName: "",
    dob: "1990-09-01T20:00:00",
    genderId: 1,
    gender: "Male",
    photoUrl:
      "https://res.cloudinary.com/dzfnt6e0i/image/upload/v1725648640/tqw9djtwa9k1a9eae5iy.png",
    photoPublicId: "tqw9djtwa9k1a9eae5iy",
    companyId: 1,
  };

  // Create a new array with defaultDoctor at the beginning
  const updatedPatientList = [defaultDoctor, ...patientList];

  return (
    <div
      id="scrollableDiv"
      style={{
        height: "100%",
        overflow: "auto",
        padding: "0 16px",
        border: "1px solid rgba(140, 140, 140, 0.35)",
      }}
    >
      <InfiniteScroll
        dataLength={patientList.length}
        next={loadMorePatients}
        hasMore={patientList.length < totalCount && !isListEmpty}
        loader={null}
        scrollableTarget="scrollableDiv"
        endMessage={
          <div style={{ textAlign: "center", padding: "10px 0" }}>
            {isListEmpty ? (
              <Empty description="No patients found" />
            ) : (
              <b>You have seen all patients</b>
            )}
          </div>
        }
      >
        <StyledList
          loading={isLoadingPatients}
          dataSource={updatedPatientList}
          renderItem={(person) => (
            <List.Item onClick={() => handlePersonSelect(person)}>
              <List.Item.Meta
                avatar={
                  <Avatar src={person.photoUrl}>{person.firstName[0]}</Avatar>
                }
                title={`${person.firstName} ${person.lastName}`}
                description={person.mrn}
              />
              <LastMessageTime>
                {/* You might want to fetch and display last message time here */}
              </LastMessageTime>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );
};

export default PatientList;
