import styled from 'styled-components'
import { Card, Typography, Form } from 'antd'

export const StyledSection = styled.section`
  align-items: center;
  display: flex;
  height: 100vh;
  background-color: #f0f2f5;
`

export const Container = styled(Card)`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

export const Header = styled.div`
  text-align: center;
  margin-bottom: 32px;
`

export const StyledTitle = styled(Typography.Title)`
  margin-top: 24px !important;
  margin-bottom: 8px !important;
`

export const StyledText = styled(Typography.Text)`
  display: block;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 24px;
`

export const FormItem = styled(Form.Item)`
  margin-bottom: 24px;
`

export const ResendLink = styled(Typography.Link)`
  display: block;
  text-align: center;
  margin-top: 16px;

  &[disabled] {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
`

export const TimerText = styled(Typography.Text)`
  display: block;
  text-align: center;
  margin-top: 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
`
