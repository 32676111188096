import {
  CREATE_GLOBAL_LOCATION,
  CREATE_GLOBAL_SETTINGS_LOCATION_MAP,
  GET_ALL_LOCATIONS,
  GET_GLOBAL_SETTINGS_LOCATION_MAP,
  UPDATE_GLOBAL_SETTINGS_FOR_USER
} from "@/constants/apiDefinition";
import { apiSlice } from "@/store/features/api/apiSlice";

// locations
export const globalLocationApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createLocation: builder.mutation({
      query: (data) => ({
        url: CREATE_GLOBAL_LOCATION,
        method: "POST",
        body: data
      }),
      invalidatesTags: [{ type: "GlobalLocations", id: "LIST" }]
    }),
    createLocationMap: builder.mutation({
      query: (data) => ({
        url: CREATE_GLOBAL_SETTINGS_LOCATION_MAP,
        method: "POST",
        body: data
      }),
      invalidatesTags: [{ type: "GlobalLocationsMap", id: "LIST" }]
    }),

    getAllLocations: builder.query({
      query: () => ({
        url: `${GET_ALL_LOCATIONS}`,
        method: "GET"
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "GlobalLocations", id })),
              { type: "GlobalLocations", id: "LIST" }
            ]
          : [{ type: "GlobalLocations", id: "LIST" }]
    }),
    getGlobalLocationsMap: builder.query({
      query: () => ({
        url: `${GET_GLOBAL_SETTINGS_LOCATION_MAP}`,
        method: "GET"
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "GlobalLocationsMap", id })),
              { type: "GlobalLocationsMap", id: "LIST" }
            ]
          : [{ type: "GlobalLocationsMap", id: "LIST" }]
    }),

    updateGlobalSettings: builder.mutation({
      query: (data) => ({
        url: UPDATE_GLOBAL_SETTINGS_FOR_USER,
        method: "PUT",
        body: data
      }),
      invalidatesTags: [{ type: "GlobalLocations", id: "LIST" }]
    }),

    deleteLocationMap: builder.mutation({
      query: (id) => {
        return {
          url: `${GET_GLOBAL_SETTINGS_LOCATION_MAP}/${id}`,
          method: "DELETE"
        };
      },
      invalidatesTags: [{ type: "GlobalLocationsMap", id: "LIST" }]
    })
  })
});

export const {
  useGetAllLocationsQuery,
  useCreateLocationMutation,
  useGetGlobalLocationsMapQuery,
  useCreateLocationMapMutation,
  useDeleteLocationMapMutation
} = globalLocationApi;
