import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deviceList: []
};

const deviceSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    setDevices: (state, action) => {
      state.deviceList = action.payload;
    }
  }
});

export const { setDevices } = deviceSlice.actions;
export default deviceSlice.reducer;
