import { useCreateCcmSetupMutation } from "@/store/features/patient/patientApiSlice";

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const useCreateCcm = () => {
  const [isPcm, setIsPcm] = useState(false);
  const [createCcm, { isLoading, isSuccess, isError, data, error }] =
    useCreateCcmSetupMutation();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const { management } = useSelector((state) => state.addPatient);

  const getRedirectUrl = () => {
    if (management?.pcm && !isPcm) {
      navigate(`/patients/add/${patientId}/pcm-setup`);
    } else if (management?.rtm) {
      navigate(`/patients/add/${patientId}/rtm-setup`);
    } else {
      navigate(`/patients/add/${patientId}/caregiver-info`);
    }
  };

  const handleCreateCcmSetup = (body) => {
    createCcm(body);
    if (body?.patientCareTypeId === 2) {
      setIsPcm(true);
    } else {
      setIsPcm(false);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(getRedirectUrl());
    }
  }, [isSuccess, navigate]);

  return { handleCreateCcmSetup, isLoading, isSuccess, isError, data, error };
};

export default useCreateCcm;
