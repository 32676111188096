import styled from 'styled-components'
import { Slider } from 'antd'

export const CustomSlider = styled(Slider)`
  .ant-slider-rail {
    background-color: ${(props) => props.colors.rail || 'red'} !important;
  }

  .ant-slider-track-1 {
    background-color: ${(props) => props.colors.track1 || '#f7c200'} !important;
  }
  .ant-slider-track-2 {
    background-color: ${(props) => props.colors.track2 || '#05bf05'} !important;
  }
  .ant-slider-track-3 {
    background-color: ${(props) => props.colors.track3 || '#f7c200'} !important;
  }
`

export const SliderContainer = styled.div`
  margin-bottom: 40px;
  position: relative;
`

export const SliderLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`
