/**
 * External dependencies
 */
import { CodeTwoTone } from '@ant-design/icons'
import { Button } from 'antd'
import { Link, useRouteError } from 'react-router-dom'
/**
 * Internal dependencies
 */

import errorResponses from '@/constants/errorResponses'
import { DASHBOARD_PATH } from '@/routes/routeDefinitions'
import { StyledAlert, StyleResult } from './style'

const AdminException = ({ errorCode }) => {
  const error = useRouteError()
  const isInDevelopment = import.meta.env.DEV
  const isInProduction = import.meta.env.PROD

  const getResponse = () => {
    if (error?.status) {
      return errorResponses[error.status] || errorResponses.default
    } else if (errorCode) {
      return errorResponses[errorCode]
    } else if (isInProduction) {
      return errorResponses.default
    } else {
      return {
        status: 'error',
        statusText: 'Bug Found',
        message: error?.message,
        trace: error?.stack,
      }
    }
  }

  const response = getResponse()

  return (
    <>
      <StyleResult
        status={response.status}
        icon={<CodeTwoTone size={'large'} />}
        title={`${response.status} | ${response.statusText}`}
        subTitle={response.message}
        extra={
          error?.status && (
            <Link to={DASHBOARD_PATH}>
              <Button>Back to Home</Button>
            </Link>
          )
        }
      />

      {isInDevelopment && response?.trace && (
        <StyledAlert
          type="error"
          message="Error Trace:"
          description={response?.trace}
        />
      )}
    </>
  )
}

export default AdminException
