import { Flex, Typography } from "antd";

const Glucose = ({ rpmData }) => {
  const glucoseData = rpmData.find((item) => item.rpmTypeId === 5);

  const formatRange = (min, max) => `${min}-${max}`;

  return (
    <Flex
      vertical
      style={{
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        width: "70%",
        flex: "1",
      }}
    >
      <Flex vertical style={{ margin: "13px 0px 13px 0px" }}>
        <Typography.Title level={5} style={{ marginBottom: "-3px" }}>
          GLUCOSE
        </Typography.Title>
        <Typography.Text>
          Normal{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(glucoseData?.min, glucoseData?.max)} mg/dL
          </span>
        </Typography.Text>
        <Typography.Text>
          Warning{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(glucoseData?.critMin, glucoseData?.min)} mg/dL &{" "}
            {formatRange(glucoseData?.max, glucoseData?.critMax)} mg/dL
          </span>
        </Typography.Text>
        <Typography.Text>
          Emergency{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(0, glucoseData?.critMin)} mg/dL &{" "}
            {formatRange(glucoseData?.critMax, 600)} mg/dL
          </span>
        </Typography.Text>
      </Flex>
      <Flex vertical>
        <Typography.Title level={5} style={{ marginBottom: "-3px" }}>
          INTERVENTIONS
        </Typography.Title>
        <Typography.Text style={{ width: "500px", textAlign: "start" }}>
          {glucoseData?.inteventionNote}
        </Typography.Text>
      </Flex>
    </Flex>
  );
};

export default Glucose;
