import {
  Table,
  Button,
  Tag,
  Space,
  Popover,
  List,
  Spin,
  Flex,
  Modal
} from "antd";
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusCircleFilled
} from "@ant-design/icons";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  useDeleteDeviceOrdersMutation,
  useGetAllDeviceOrdersQuery
} from "@/store/features/devices/deviceApiSlice";
import EmptyState from "@/components/ui/empty/Empty";
import { useState } from "react";

const Container = styled.div`
  padding: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
`;

const StyledTable = styled(Table)`
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const DeviceOrders = () => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orderToDelete, setOrderToDelete] = useState(null);

  const {
    data: orders,
    isLoading,
    isSuccess,
    isError
  } = useGetAllDeviceOrdersQuery({});

  const [deleteDeviceOrders] = useDeleteDeviceOrdersMutation();

  const showModal = (orderId) => {
    setOrderToDelete(orderId);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    console.log(`Deleting order with ID: ${orderToDelete}`);
    deleteDeviceOrders(orderToDelete);
    setIsModalOpen(false);
    setOrderToDelete(null);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setOrderToDelete(null);
  };

  const columns = [
    {
      title: "Order No",
      dataIndex: "orderNo",
      key: "orderNo"
    },
    {
      title: "Patient ID",
      dataIndex: "patientId",
      key: "patientId"
    },
    {
      title: "Status",
      dataIndex: "deviceOrderStartus",
      key: "deviceOrderStartus",
      render: (status) => (
        <Tag color={status === "Pending" ? "gold" : "green"}>
          {status?.toUpperCase()}
        </Tag>
      )
    },
    {
      title: "Authorization Code",
      dataIndex: "authorizationCode",
      key: "authorizationCode"
    },
    {
      title: "Devices",
      dataIndex: "devices",
      key: "devices",
      render: (devices) => (
        <Popover
          content={
            <List
              size="small"
              dataSource={devices}
              renderItem={(item) => <List.Item>{item?.deviceName}</List.Item>}
            />
          }
          title="Ordered Devices"
          trigger="click"
        >
          <Button icon={<InfoCircleOutlined />}>
            View Devices ({devices?.length})
          </Button>
        </Popover>
      )
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => showModal(record.deviceOrderId)}
          >
            Delete
          </Button>
        </Space>
      )
    }
  ];

  let content = null;
  if (isLoading) {
    content = (
      <Flex
        items="center"
        justify="center"
        style={{ width: "100%", height: "100%" }}
      >
        <Spin />
      </Flex>
    );
  }

  if (isError) {
    content = <EmptyState />;
  }

  console.log(orders);

  if (isSuccess) {
    content = (
      <StyledTable
        columns={columns}
        dataSource={orders}
        rowKey="deviceOrderId"
        pagination={true}
      />
    );
  }

  return (
    <Container>
      {/*Header of the table*/}
      <Header>
        <Title>Device Orders</Title>
        <Button
          type="primary"
          icon={<PlusCircleFilled />}
          onClick={() => navigate("/createNewOrder")}
        >
          Add New Order
        </Button>
      </Header>

      {content}

      <Modal
        title="Confirm Deletion"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Sure Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this order?</p>
      </Modal>
    </Container>
  );
};

export default DeviceOrders;
