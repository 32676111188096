import { useDispatch, useSelector } from "react-redux";
import { StepperContainer, StyledSteps } from "./styles";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";

const steps = [
  { title: "Patient History" },
  { title: "Goals" },
  { title: "Barriers" },
  { title: "Symptoms" },
  { title: "Allergies" },
  { title: "Medications" },
  { title: "Monitoring" },
  { title: "Preventive Care" },
  { title: "Testing" },
  { title: "Self Management" },
  { title: "Additional Notes" },
  { title: "Risk Classification" },
];

const Stepper = () => {
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const onChange = (value) => {
    dispatch(setCurrentStep(value));
  };

  return (
    <StepperContainer>
      <StyledSteps
        direction="vertical"
        size="small"
        current={currentStep}
        onChange={onChange}
        items={steps}
      />
    </StepperContainer>
  );
};

export default Stepper;
