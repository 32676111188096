import { LOGIN_PATH } from '@/routes/routeDefinitions'
import { apiSlice } from '@/store/features/api/apiSlice'
import { setAuth } from '@/store/features/auth/authSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const useLogout = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleLogout = () => {
    localStorage.removeItem('token')
    dispatch(apiSlice.util.resetApiState())
    dispatch(setAuth({}))
    navigate(LOGIN_PATH)
  }

  return { handleLogout }
}
