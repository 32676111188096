import styled from "styled-components";





export const StyledMainContainer  = styled.div`


display:flex;

flex-direction:column;

justify-content:center;

align-items:center;

gap:12px;


`