import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";

const BackButton = (props) => {
  const { showLabel, ...rest } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <Button
      onClick={handleClick}
      type="default"
      icon={<ArrowLeftOutlined />}
      aria-label="Go back to previous page"
      {...rest}
    >
      {showLabel && "Back"}
    </Button>
  );
};

export default BackButton;
