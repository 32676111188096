import { Checkbox, Form, Typography } from "antd";
import { DeviceOption, SerialInput, StyledCard } from "./Style";
const { Title } = Typography;
const RenderDeviceOptions = (props) => {
  const { title, devices, selectedDevices } = props;

  //TODO: This is a temporary solution.
  //This is a real engineering problem. SOlve after MVP launch.
  //Creating an array and pushing the data to the specific index of it.
  //This will cause memory consuming issue once the project is deployed.
  //CRITICAL ISSUE
  return (
    <StyledCard title={<Title level={5}>{title}</Title>}>
      {devices?.map((device) => (
        <DeviceOption key={device.deviceId}>
          <Form.Item
            name={["devices", title, device.deviceId, "isProvided"]}
            valuePropName="checked"
          >
            <Checkbox>{device.deviceName}</Checkbox>
          </Form.Item>
          {selectedDevices?.[title]?.[device.deviceId]?.["isProvided"] && (
            <Form.Item
              name={["devices", title, device.deviceId, "deviceUniqueId"]}
              rules={[
                { required: true, message: "Please input the serial number!" },
              ]}
            >
              <SerialInput placeholder={`Enter device's serial number`} />
            </Form.Item>
          )}
        </DeviceOption>
      ))}
    </StyledCard>
  );
};

export default RenderDeviceOptions;
