import styled from "styled-components";

export const StyledMainContainer = styled.div`
  display: flex;

  flex-direction: column;
`;

export const StyledMainDivContainer = styled.div`
  display: flex;

  flex-direction: column;

  gap: 10px;

  margin-top: 10px;

  padding: 15px;

  border: 1px solid rgba(0, 0, 0, 0.1);

  border-radius: 8px;
`;

export const StyledDivContainer = styled.div`
  display: flex;

  flex-direction: row;

  justify-content: space-between;

  gap: 14px;
`;
