import styled from "styled-components";

export const StyledFormContainer = styled.div`
  display: flex;

  justify-content: center;

  align-items: center;

  flex-direction: column;

  margin-top: 15px;

  width: 100%;

  flex: 1;

  background-color: #fff;

  padding: 21px;

  border-radius: 15px;

  margin-left: 25px;
`;

export const StyledDivContainer = styled.div`
  display: flex;

  justify-content: space-between;

  align-items: center;

  flex-direction: row;

  width: 100%;

  flex: 1;
`;
