import CustomPagination from "@/components/shared/pagination";
import { Flex } from "antd";
import { useState } from "react";

import { useGetDashboardDataQuery } from "@/store/features/dashboard/dashboardApiSlice";
import { useSelector } from "react-redux";
import RPMTab from "./components/tabs/RPMTab";

const Dashboard = () => {
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const { searchTerm, filters } = useSelector((state) => state.dashboard);
  const {
    isLoading: isRPMLoading,
    data: rpmPatients,
    isSuccess: isRmpSuccess,
    isError: isRmpError,
  } = useGetDashboardDataQuery({
    pageNumber: pagination.pageNumber,
    pageSize: pagination.pageSize,
    PatientName: searchTerm,
    ...filters,
  });
  const handlePagination = (pageNumber) => {
    setPagination((prev) => {
      return { ...prev, pageNumber: pageNumber };
    });
  };

  return (
    <Flex vertical style={{ minHeight: "100vh" }}>
      <RPMTab
        patientList={rpmPatients?.data}
        isLoading={isRPMLoading}
        isSuccess={isRmpSuccess}
        isError={isRmpError}
      />

      <CustomPagination
        current={pagination.pageNumber}
        total={rpmPatients?.totalItems}
        pageSize={pagination.pageSize}
        onChange={handlePagination}
      />
    </Flex>
  );
};

export default Dashboard;
