import RichTextEditor from "@/components/shared/richTextEditor";
import { Col, Row, Typography } from "antd";

const PatientHistory = (props) => {
  const { form, initialValue } = props;
  return (
    <Row>
      <Col span={24}>
        <Typography.Title level={4}>Patient History</Typography.Title>
        <RichTextEditor
          keyName="patientHistory"
          form={form}
          initialValue={initialValue}
        />
      </Col>
    </Row>
  );
};

export default PatientHistory;
