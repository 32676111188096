import { VERIFY_PHONE_PATH } from '@/routes/routeDefinitions'
import { apiSlice } from '@/store/features/api/apiSlice'
import { useRegisterMutation } from '@/store/features/auth/authApiSlice'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const useRegistration = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [register, { isLoading, isSuccess, isError, data, error }] =
    useRegisterMutation()

  const handleRegister = (credentials) => {
    register(credentials)
  }

  useEffect(() => {
    if (!isLoading && isSuccess) {
      localStorage.setItem('userId', data?.result?.tempUserId)
      apiSlice.util.resetApiState()
      navigate(VERIFY_PHONE_PATH)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, data, dispatch])

  return { handleRegister, isLoading, isSuccess, isError, data, error }
}

export default useRegistration
