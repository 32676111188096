import { notification } from 'antd'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

const NotificationSnackbar = () => {
  const notificationData = useSelector((state) => state.notification)

  const [notificationAPI, notificationContextHolder] =
    notification.useNotification()

  useEffect(() => {
    if (notificationData?.type) {
      notificationAPI[notificationData?.type]({
        message: notificationData.message,
      })
    }
  }, [notificationData, notificationAPI])

  return <>{notificationContextHolder}</>
}

export default NotificationSnackbar
