import { Flex, Typography } from "antd";

const Temperature = ({ rpmData }) => {
  const temperatureData = rpmData?.find((item) => item.rpmTypeId === 6);

  const formatRange = (min, max) =>
    min !== undefined && max !== undefined ? `${min}-${max}` : "N/A";

  if (!temperatureData) {
    return <Flex>No temperature data available</Flex>;
  }

  return (
    <Flex
      vertical
      style={{
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        width: "70%",
        flex: "1",
      }}
    >
      <Flex vertical style={{ margin: "13px 0px 13px 0px" }}>
        <Typography.Title level={5} style={{ marginBottom: "-3px" }}>
          TEMPERATURE
        </Typography.Title>
        <Typography.Text>
          Normal{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(temperatureData.min, temperatureData.max)}
          </span>
        </Typography.Text>
        <Typography.Text>
          Warning{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(temperatureData.critMin, temperatureData.min)} &{" "}
            {formatRange(temperatureData.max, temperatureData.critMax)}
          </span>
        </Typography.Text>
        <Typography.Text>
          Emergency{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(0, temperatureData.critMin)} &{" "}
            {formatRange(temperatureData.critMax, 999)}
          </span>
        </Typography.Text>
      </Flex>
      <Flex vertical>
        <Typography.Title level={5} style={{ marginBottom: "-3px" }}>
          INTERVENTIONS
        </Typography.Title>
        <Typography.Text style={{ width: "500px", textAlign: "start" }}>
          {temperatureData.inteventionNote || "No intervention note available"}
        </Typography.Text>
      </Flex>
    </Flex>
  );
};

export default Temperature;
