import {
  GENERATE_MEETING_LINK_URL,
  INITIATE_CALL_LINK_URL
} from "@/constants/apiDefinition";
import { apiSlice } from "../api/apiSlice";

export const audioVideoApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAudioMeetingLink: builder.query({
      query: () => ({
        url: GENERATE_MEETING_LINK_URL,
        method: "GET",
        params: { callType: "audio" }
      })
    }),
    getVideoMeetingLink: builder.query({
      query: () => ({
        url: GENERATE_MEETING_LINK_URL,
        method: "GET",
        params: { callType: "video" }
      })
    }),
    createCall: builder.mutation({
      query: (data) => ({
        url: INITIATE_CALL_LINK_URL,
        method: "POST",
        body: data
      })
    })
  })
});

export const {
  useGetAudioMeetingLinkQuery,
  useGetVideoMeetingLinkQuery,
  useCreateCallMutation
} = audioVideoApi;
