import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  management: {
    rpm: false,
    ccm: false,
    pcm: false,
    rtm: false,
  },
  //can be changed from global settings. That's why Written in here.
  defaultValues: {
    bloodPressure: [60, 90, 120, 180],
    pulse: [40, 60, 100, 120],
    weight: [30, 50, 80, 120],
    spo2: [90, 95, 97, 100],
    glucose: [54, 70, 99, 300],
    temperature: [95, 97.7, 99.5, 103.5],
  },
  healthConditionId: null,
};

const addPatientSlice = createSlice({
  name: "addPatient",
  initialState,
  reducers: {
    setManagement: (state, action) => {
      state.management.rpm = action.payload.rpm;
      state.management.ccm = action.payload.ccm;
      state.management.pcm = action.payload.pcm;
      state.management.rtm = action.payload.rtm;
    },
    setHealthConditionsId: (state, action) => {
      state.healthConditionId = action.payload;
    },
  },
});

export const { setManagement, setHealthConditionsId } = addPatientSlice.actions;
export default addPatientSlice.reducer;
