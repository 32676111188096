import { Input, Typography } from 'antd'
import { useState } from 'react'
const { TextArea } = Input
const { Text } = Typography

export default function DiscriptionInput() {
  const [description, setDescription] = useState('')
  const maxChars = 1300

  const handleChange = (e) => {
    const inputText = e.target.value
    const alphabetCount = inputText.replace(/[^a-zA-Z]/g, '').length

    if (alphabetCount <= maxChars) {
      setDescription(inputText)
    } else {
      // Truncate the input to the maximum allowed alphabet count
      const truncatedText = inputText.split('').reduce(
        (acc, char) => {
          if (acc.alphabetCount < maxChars && /[a-zA-Z]/.test(char)) {
            acc.text += char
            acc.alphabetCount++
          } else if (!/[a-zA-Z]/.test(char)) {
            acc.text += char
          }
          return acc
        },
        { text: '', alphabetCount: 0 }
      ).text

      setDescription(truncatedText)
    }
  }

  const alphabetCount = description.replace(/[^a-zA-Z]/g, '').length
  const isNearLimit = alphabetCount >= maxChars * 0.9
  const isAtLimit = alphabetCount === maxChars

  return (
    <div>
      <p style={{ marginBottom: '4px' }}>Additional Notes</p>
      <TextArea
        value={description}
        onChange={handleChange}
        rows={4}
        placeholder="Additional Notes.."
      />
      <Text
        style={{
          display: 'block',
          textAlign: 'right',
          marginTop: '8px',
          color: isAtLimit ? 'red' : isNearLimit ? 'orange' : 'inherit',
        }}
      >
        {alphabetCount} / {maxChars} alphabets
      </Text>
    </div>
  )
}
