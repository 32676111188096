import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";

const BloodPressureLineChart = ({ data }) => {
  const formatXAxis = (tickItem) => {
    return dayjs(tickItem).format("DD-MMM-YY");
  };

  const formatTooltip = (value, name) => {
    if (name === "bloodPressureSystolic") {
      return [`${value} mmHg`, "Systolic"];
    } else if (name === "bloodPressureDiastolic") {
      return [`${value} mmHg`, "Diastolic"];
    }
    return [value, name];
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={Array.isArray(data) ? [...data].reverse() : []}
        margin={{ top: 20, right: 30, left: 20, bottom: 50 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="createdAt"
          tickFormatter={formatXAxis}
          angle={-45}
          textAnchor="end"
          height={70}
          label={{ value: "Date", position: "insideBottom", offset: -10 }}
        />
        <YAxis
          label={{
            value: "Blood Pressure (mmHg)",
            angle: -90,
            position: "insideLeft",
            offset: 0,
          }}
        />
        <Tooltip formatter={formatTooltip} />
        <Legend verticalAlign="top" height={36} />
        <Line
          type="monotone"
          dataKey="bloodPressureSystolic"
          stroke="#8884d8"
          name="Systolic"
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey="bloodPressureDiastolic"
          stroke="#82ca9d"
          name="Diastolic"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default BloodPressureLineChart;
