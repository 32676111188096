import styled from "styled-components";
import {
  Card,
  Button,
  Typography,
  Flex,
  Row,
  Col,
  Table,
  Tooltip,
  message,
} from "antd";
import AppointmentHistory from "./PatientReport";
import StatsCardSection from "./stats-card-section";
import { useDashBoardData } from "./useDashBoardData";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import DoctorRevenueGraph from "./doctor-reviewnue";

const { Title, Paragraph } = Typography;

const StyledCard = styled(Card)`
  background-color: rgb(82 138 200);
  border-radius: 1rem;
  position: relative;
  margin-top: 1rem;
  border: none;
  -webkit-box-shadow: 2px 4px 33px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 4px 33px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 4px 33px -7px rgba(0, 0, 0, 0.3);
`;

const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

const TextContent = styled.div`
  color: white;
`;

const StyledButton = styled(Button)`
  background-color: white;
  color: #01796e;
  border: none;
  &:hover {
    background-color: #f0f0f0;
    color: #01796e;
  }
`;

const DoctorImage = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
  background-color: transparent;
`;

const AnalyticsCard = styled(Card)`
  margin-bottom: 1.5rem;
`;

// Styled component for equal height cards
const EqualHeightCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;

  .ant-card-body {
    flex-grow: 1;
    overflow: auto;
  }
`;

const colors = ["#008080", "#4E79A7", "#FF9DA7", "#59A14F"];

const DoctorDashboardHero = ({ doctorName }) => {
  const {
    isError,
    isSuccess,
    patientData,
    isLoading,
    getAgeDistribution,
    getEthnicityDistribution,
    getHighRiskPatients,
    getActivityDistribution,
  } = useDashBoardData();
  doctorName = doctorName ?? "Doctor";
  return (
    <Flex vertical gap={"large"}>
      <StyledCard>
        <CardContent>
          <TextContent>
            <Title level={2} style={{ color: "white", marginBottom: 0 }}>
              Good Morning, {doctorName}
            </Title>
            <Paragraph style={{ color: "white" }}>
              Have a nice day at work it seems like you are referring to{" "}
              {doctorName}.
            </Paragraph>
            <Flex gap={"large"}>
              <StyledButton
                type="primary"
                size="large"
                onClick={() => {
                  message.warning("Coming Soon");
                }}
              >
                Create Appointment
              </StyledButton>
            </Flex>
          </TextContent>
          <DoctorImage
            src="https://www.shebaonline.org/wp-content/themes/sheba/assets/images/doctor-placeholder.jpg"
            alt="Doctor"
          />
        </CardContent>
      </StyledCard>
      <StatsCardSection />
      <DoctorRevenueGraph />

      <Row gutter={16}>
        <Col md={8}>
          <AnalyticsCard title="Age Distribution">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={getAgeDistribution()}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  fill="#8884d8"
                >
                  {getAgeDistribution().map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </AnalyticsCard>
        </Col>

        <Col md={8}>
          <AnalyticsCard title="Ethnicity Distribution">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={getEthnicityDistribution()}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill={colors[0]}>
                  {getEthnicityDistribution().map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </AnalyticsCard>
        </Col>

        <Col md={8}>
          <AnalyticsCard title="Activity Distribution">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={getActivityDistribution()}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="value" fill={colors[1]}>
                  {getActivityDistribution().map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[(index + 2) % colors.length]}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </AnalyticsCard>
        </Col>
      </Row>

      <Row gutter={[16]}>
        <Col md={12}>
          <EqualHeightCard title="Appointment History">
            <AppointmentHistory />
          </EqualHeightCard>
        </Col>
        <Col md={12}>
          <EqualHeightCard title="High Risk Table">
            <Table
              columns={[
                { title: "Name", dataIndex: "name", key: "name" },
                { title: "Age", dataIndex: "age", key: "age" },
                {
                  title: "Risk Factors",
                  dataIndex: "riskFactors",
                  key: "riskFactors",
                },
              ]}
              dataSource={getHighRiskPatients()}
            />
          </EqualHeightCard>
        </Col>
      </Row>
    </Flex>
  );
};

export default DoctorDashboardHero;
