import { apiSlice } from "@/store/features/api/apiSlice";
import {
  CREATE_PATIENT_HISTORY_URL,
  CREATE_CCM_GOAL_URL,
  CREATE_CCM_BARRIER_URL,
  CREATE_CCM_SYMPTOM_URL,
  CREATE_CCM_ALLERGY_URL,
  CREATE_CCM_MEDICATION_URL,
  CREATE_CCM_MONITORING_URL,
  CREATE_CCM_PREVENTIVE_CARE_URL,
  CREATE_CCM_PSYCHIATRIC_TEST_URL,
  CREATE_CCM_SELF_MANAGEMENT_URL,
  CREATE_CCM_ADDITIONAL_NOTES_URL,
  GET_CCM_GOALS_URL,
  GET_CCM_BARRIERS_URL,
  GET_CCM_SYMPTOMS_URL,
  GET_CCM_PREVENTIVE_CARE_URL,
  GET_CCM_CHRONIC_CONDITIONS_URL
} from "@/constants/apiDefinition";

export const ccmApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createPatientHistory: builder.mutation({
      query: (data) => ({
        url: CREATE_PATIENT_HISTORY_URL,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["CCMPatientHistory"]
    }),
    createCCMGoal: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_GOAL_URL,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["CCMGoals", "CCMGoalMapping"]
    }),
    createCCMBarrier: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_BARRIER_URL,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["CCMBarriers"]
    }),
    createCCMSymptom: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_SYMPTOM_URL,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["CCMSymptoms"]
    }),
    createCCMAllergy: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_ALLERGY_URL,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["CCMAllergies"]
    }),
    createCCMMedication: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_MEDICATION_URL,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["CCMMedications"]
    }),
    createCCMMonitoring: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_MONITORING_URL,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["CCMMonitoring"]
    }),
    createCCMPreventiveCare: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_PREVENTIVE_CARE_URL,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["CCMPreventiveCare"]
    }),
    createCCMPsychiatricTest: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_PSYCHIATRIC_TEST_URL,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["CCMPsychiatricTests"]
    }),
    createCCMSelfManagement: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_SELF_MANAGEMENT_URL,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["CCMSelfManagement"]
    }),
    createCCMAdditionalNotes: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_ADDITIONAL_NOTES_URL,
        method: "POST",
        body: data
      }),
      invalidatesTags: ["CCMAdditionalNotes"]
    }),
    getCCMGoals: builder.query({
      query: () => GET_CCM_GOALS_URL,
      providesTags: ["CCMGoals"]
    }),
    getCCMBarriers: builder.query({
      query: () => GET_CCM_BARRIERS_URL,
      providesTags: ["CCMBarriers"]
    }),
    getCCMSymptoms: builder.query({
      query: () => GET_CCM_SYMPTOMS_URL,
      providesTags: ["CCMSymptoms"]
    }),
    getCCMPreventiveCare: builder.query({
      query: () => GET_CCM_PREVENTIVE_CARE_URL,
      providesTags: ["CCMPreventiveCare"]
    }),
    getCCMChronicConditions: builder.query({
      query: () => GET_CCM_CHRONIC_CONDITIONS_URL,
      providesTags: ["CCMChronicConditions"]
    }),
    getCCMPatientHistory: builder.query({
      query: ({ patientId, date = "" }) => ({
        url: `/api/ccmpatienthistory`,
        params: { patientId, date }
      }),
      providesTags: (result, error, patientId) => [
        { type: "CCMPatientHistory", id: patientId }
      ]
    }),

    getCCMGoalMapping: builder.query({
      query: ({ pageNumber = 1, pageSize = 10, patientId, date = "" }) => ({
        url: `/api/ccmgoalmapping`,
        params: { PageNumber: pageNumber, PageSize: pageSize, patientId, date }
      }),
      providesTags: (result, error, arg) => [
        { type: "CCMGoalMapping", id: arg.patientId },
        { type: "CCMGoalMapping", id: "LIST" }
      ]
    }),

    getCCMBarrierMapping: builder.query({
      query: ({ date = "", pageNumber = 1, pageSize = 10, patientId }) => ({
        url: `/api/CCMBarrierMapping`,
        params: { date, PageNumber: pageNumber, PageSize: pageSize, patientId }
      }),
      providesTags: (result, error, arg) => [
        { type: "CCMBarrierMapping", id: arg.patientId },
        { type: "CCMBarrierMapping", id: "LIST" }
      ]
    }),

    getCCMSymptomMapping: builder.query({
      query: ({ date = "", pageNumber = 1, pageSize = 10, patientId }) => ({
        url: `/api/CCMSymptomMapping`,
        params: { date, PageNumber: pageNumber, PageSize: pageSize, patientId }
      }),
      providesTags: (result, error, arg) => [
        { type: "CCMSymptomMapping", id: arg.patientId },
        { type: "CCMSymptomMapping", id: "LIST" }
      ]
    }),

    getCCMMedication: builder.query({
      query: ({ date = "", pageNumber = 1, pageSize = 10, patientId }) => ({
        url: `/api/CCMMedication`,
        params: { date, PageNumber: pageNumber, PageSize: pageSize, patientId }
      }),
      providesTags: (result, error, arg) => [
        { type: "CCMMedication", id: arg.patientId },
        { type: "CCMMedication", id: "LIST" }
      ]
    }),

    getCCMMonitoring: builder.query({
      query: ({ date = "", pageNumber = 1, pageSize = 10, patientId }) => ({
        url: `/api/CCMMonitoring`,
        params: { date, PageNumber: pageNumber, PageSize: pageSize, patientId }
      }),
      providesTags: (result, error, arg) => [
        { type: "CCMMonitoring", id: arg.patientId },
        { type: "CCMMonitoring", id: "LIST" }
      ]
    }),

    getCCMSelfManagement: builder.query({
      query: ({ date = "", pageNumber = 1, pageSize = 10, patientId }) => ({
        url: `/api/CCMSelfManagement`,
        params: { date, PageNumber: pageNumber, PageSize: pageSize, patientId }
      }),
      providesTags: (result, error, arg) => [
        { type: "CCMSelfManagement", id: arg.patientId },
        { type: "CCMSelfManagement", id: "LIST" }
      ]
    }),

    getCCMPreventiveCareMeasureMapping: builder.query({
      query: ({ date = "", pageNumber = 1, pageSize = 10, patientId }) => ({
        url: `/api/CCMPreventiveCareMeasureMapping`,
        params: { date, PageNumber: pageNumber, PageSize: pageSize, patientId }
      }),
      providesTags: (result, error, arg) => [
        { type: "CCMPreventiveCareMeasureMapping", id: arg.patientId },
        { type: "CCMPreventiveCareMeasureMapping", id: "LIST" }
      ]
    }),

    getCCMAdditionalInfo: builder.query({
      query: ({
        pageNumber = 1,
        pageSize = 10,
        patientId,
        ccmAdditionalInfoTypeId,
        date = ""
      }) => ({
        url: `/api/CCMAdditionalInfo`,
        params: {
          date,
          PageNumber: pageNumber,
          PageSize: pageSize,
          patientId,
          CCMAdditionalInfoTypeId: ccmAdditionalInfoTypeId
        }
      }),
      providesTags: (result, error, arg) => [
        { type: "CCMAdditionalInfo", id: arg.patientId },
        { type: "CCMAdditionalInfo", id: arg.ccmAdditionalInfoTypeId },
        { type: "CCMAdditionalInfo", id: "LIST" }
      ]
    }),
    updatePatientHistory: builder.mutation({
      query: (data) => ({
        url: CREATE_PATIENT_HISTORY_URL,
        method: "PUT",
        body: data
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CCMPatientHistory", id: arg.patientId }
      ]
    }),
    updateCCMGoal: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_GOAL_URL,
        method: "PUT",
        body: data
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CCMGoals", id: arg.ccmGoalMappingId },
        { type: "CCMGoalMapping", id: arg.patientId },
        { type: "CCMGoalMapping", id: "LIST" }
      ]
    }),
    updateCCMBarrier: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_BARRIER_URL,
        method: "PUT",
        body: data
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CCMBarriers", id: arg.ccmBarrierMappingId },
        { type: "CCMBarrierMapping", id: arg.patientId },
        { type: "CCMBarrierMapping", id: "LIST" }
      ]
    }),
    updateCCMSymptom: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_SYMPTOM_URL,
        method: "PUT",
        body: data
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CCMSymptoms", id: arg.ccmSymptomMappingId },
        { type: "CCMSymptomMapping", id: arg.patientId },
        { type: "CCMSymptomMapping", id: "LIST" }
      ]
    }),
    updateCCMAllergy: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_ALLERGY_URL,
        method: "PUT",
        body: data
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CCMAllergies", id: arg.ccmAllergyId },
        { type: "CCMAdditionalInfo", id: arg.patientId },
        { type: "CCMAdditionalInfo", id: "LIST" }
      ]
    }),
    updateCCMMedication: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_MEDICATION_URL,
        method: "PUT",
        body: data
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CCMMedications", id: arg.ccmMedicationId },
        { type: "CCMMedication", id: arg.patientId },
        { type: "CCMMedication", id: "LIST" }
      ]
    }),
    updateCCMMonitoring: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_MONITORING_URL,
        method: "PUT",
        body: data
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CCMMonitoring", id: arg.ccmMonitoringId },
        { type: "CCMMonitoring", id: arg.patientId },
        { type: "CCMMonitoring", id: "LIST" }
      ]
    }),
    updateCCMPreventiveCare: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_PREVENTIVE_CARE_URL,
        method: "PUT",
        body: data
      }),
      invalidatesTags: (result, error, arg) => [
        {
          type: "CCMPreventiveCare",
          id: arg.ccmPreventiveCareMeasureMappingId
        },
        { type: "CCMPreventiveCareMeasureMapping", id: arg.patientId },
        { type: "CCMPreventiveCareMeasureMapping", id: "LIST" }
      ]
    }),
    updateCCMPsychiatricTest: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_PSYCHIATRIC_TEST_URL,
        method: "PUT",
        body: data
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CCMPsychiatricTests", id: arg.ccmPsychiatricTestId },
        { type: "CCMAdditionalInfo", id: arg.patientId },
        { type: "CCMAdditionalInfo", id: "LIST" }
      ]
    }),
    updateCCMSelfManagement: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_SELF_MANAGEMENT_URL,
        method: "PUT",
        body: data
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CCMSelfManagement", id: arg.ccmSelfManagementId },
        { type: "CCMSelfManagement", id: arg.patientId },
        { type: "CCMSelfManagement", id: "LIST" }
      ]
    }),
    updateCCMAdditionalNotes: builder.mutation({
      query: (data) => ({
        url: CREATE_CCM_ADDITIONAL_NOTES_URL,
        method: "PUT",
        body: data
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "CCMAdditionalNotes", id: arg.ccmAdditionalInfoId },
        { type: "CCMAdditionalInfo", id: arg.patientId },
        { type: "CCMAdditionalInfo", id: "LIST" }
      ]
    })
  })
});

export const {
  useCreatePatientHistoryMutation,
  useCreateCCMGoalMutation,
  useCreateCCMBarrierMutation,
  useCreateCCMSymptomMutation,
  useCreateCCMAllergyMutation,
  useCreateCCMMedicationMutation,
  useCreateCCMMonitoringMutation,
  useCreateCCMPreventiveCareMutation,
  useCreateCCMPsychiatricTestMutation,
  useCreateCCMSelfManagementMutation,
  useCreateCCMAdditionalNotesMutation,
  useGetCCMGoalsQuery,
  useGetCCMBarriersQuery,
  useGetCCMSymptomsQuery,
  useGetCCMPreventiveCareQuery,
  useGetCCMChronicConditionsQuery,
  useGetCCMPatientHistoryQuery,
  useGetCCMGoalMappingQuery,
  useGetCCMBarrierMappingQuery,
  useGetCCMSymptomMappingQuery,
  useGetCCMMedicationQuery,
  useGetCCMMonitoringQuery,
  useGetCCMSelfManagementQuery,
  useGetCCMPreventiveCareMeasureMappingQuery,
  useGetCCMAdditionalInfoQuery,
  useUpdatePatientHistoryMutation,
  useUpdateCCMGoalMutation,
  useUpdateCCMBarrierMutation,
  useUpdateCCMSymptomMutation,
  useUpdateCCMAllergyMutation,
  useUpdateCCMMedicationMutation,
  useUpdateCCMMonitoringMutation,
  useUpdateCCMPreventiveCareMutation,
  useUpdateCCMPsychiatricTestMutation,
  useUpdateCCMSelfManagementMutation,
  useUpdateCCMAdditionalNotesMutation
} = ccmApiSlice;
