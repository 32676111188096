import styled from 'styled-components'

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LogoText = styled.h3`
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #0088cc;
  margin: 0;
  padding: 0;

  span {
    color: #20b2aa;
  }
`
