import styled from "styled-components";

export const StyledMainContainer = styled.div`
  diplay: flex;

  justify-content: center;

  align-items: center;

  flex-direction: column;

  margin-top: 15px;

  width: 100%;

  flex: 1;

  background-color: #fff;

  padding: 21px;

  border-radius: 15px;
`;

export const StyledMainNavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledMainTableContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;
export const StyledDivContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 9px;
  border-radius: 15px;
`;
