import { DeleteOutlined } from "@ant-design/icons";
import { StyledContainer, StyledTable, StyledTitle } from "./Style";
import { Button, Flex } from "antd";
import { useState } from "react";
import LocationForm from "@/Pages/GlobalSettings/Component/LocationManagement/LocationForm";
import {
  useCreateLocationMapMutation,
  useDeleteLocationMapMutation,
  useGetAllLocationsQuery,
  useGetGlobalLocationsMapQuery
} from "@/store/features/globalLocations/globalLocationSlice";
import LoadingComponent from "@/components/LoadingComponent";
import EmptyState from "@/components/ui/empty/Empty";

const LocationManagement = (props) => {
  const { globalSettingId } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const {
    data: allLocations,
    isLoading,
    isSuccess,
    isError
  } = useGetAllLocationsQuery();
  const [createLocationMap, { isLoading: isCreateMapLoading }] =
    useCreateLocationMapMutation();
  const {
    data: locationMap,
    isLoading: isGetMapLoading,
    isSuccess: isGetLocationMapSuccess
  } = useGetGlobalLocationsMapQuery();
  const [deleteLocationMap] = useDeleteLocationMapMutation();

  const handleDelete = (globalLocationId) => {
    let mapLocation = locationMap?.find((map) => {
      return map?.globalLocationId === globalLocationId;
    });

    deleteLocationMap(mapLocation?.globalLocationMappingId);
  };

  // columns of the table
  const columns = [
    {
      title: "ID",
      dataIndex: "globalLocationId",
      key: "globalLocationId",
      width: 30,
      sorter: (a, b) => a.globalLocationId - b.globalLocationId
    },
    {
      title: "Display Name",
      dataIndex: "displayName",
      key: "displayName",
      width: 190
    },
    {
      title: "Faculty Api",
      dataIndex: "facultyApi",
      key: "facultyApi"
    },
    {
      title: "Practise/Location Name",
      dataIndex: "locationName",
      key: "locationName"
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address"
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city"
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state"
    },
    {
      title: "Zip Code",
      dataIndex: "zipCode",
      key: "zipCode"
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Flex gap={"small"}>
          <Button
            onClick={() => handleDelete(record.globalLocationId)}
            icon={<DeleteOutlined />}
          />
        </Flex>
      )
    }
  ];

  let content = null;
  if (isLoading || isGetMapLoading) {
    content = <LoadingComponent />;
  }

  if (isError) {
    content = <EmptyState />;
  }

  console.log(locationMap);

  let selectedLocations = allLocations?.filter((location) =>
    locationMap?.some(
      (selected) => selected.globalLocationId === location.globalLocationId
    )
  );
  if (isSuccess && isGetLocationMapSuccess) {
    content = (
      <StyledContainer>
        <Flex
          justify="space-between"
          align="center"
          style={{ margin: "15px 0px" }}
        >
          <StyledTitle level={4}>Location Management</StyledTitle>
          <Button type="primary" onClick={() => setModalOpen(true)}>
            Add Location
          </Button>
        </Flex>
        <StyledTable
          columns={columns}
          dataSource={selectedLocations}
          pagination={{
            pageSize: 5,
            showSizeChanger: false,
            showQuickJumper: true
          }}
        />
        <LocationForm
          initialFacilities={allLocations}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          globalSettingId={globalSettingId}
          isLoading={isCreateMapLoading}
          createLocationMap={createLocationMap}
        />
      </StyledContainer>
    );
  }
  return content;
};

export default LocationManagement;
