import styled from 'styled-components'
import { Card, Typography, Form } from 'antd'
import { Link } from 'react-router-dom'

export const StyledSection = styled.section`
  align-items: center;
  display: flex;
  height: 100vh;
  background-color: #f0f2f5;
`

export const Container = styled(Card)`
  width: 100%;
  max-width: 450px;
  margin: 0 auto;
  padding: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`

export const Header = styled.div`
  text-align: center;
  margin-bottom: 32px;
`

export const StyledTitle = styled(Typography.Title)`
  margin-top: 24px !important;
  margin-bottom: 8px !important;
`

export const StyledText = styled(Typography.Text)`
  display: block;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 24px;
`

export const FormItem = styled(Form.Item)`
  margin-bottom: 24px;

  .ant-input-prefix {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-input-affix-wrapper {
    padding: 12px 11px;
  }
`

export const ForgotPasswordLink = styled(Link)`
  display: block;
  text-align: right;
  margin-bottom: 24px;
`

export const Footer = styled.div`
  text-align: center;
  margin-top: 24px;
`

export const SignUpText = styled(Typography.Text)`
  color: rgba(0, 0, 0, 0.45);

  a {
    color: #1890ff;
    font-weight: 600;
  }
`
