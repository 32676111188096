/**
 * External dependencies
 */
import { Layout } from "antd";
import { useCallback, useEffect, useState } from "react";
/**
 * Internal dependencies
 */

import Header from "@/components/ui/header";
import Sidebar from "@/components/ui/sidebar";
import { Outlet } from "react-router-dom";
import { StyledContent, StyledLayout } from "./style";
import AiChat from "@/components/chat";
import usePubNub from "@/hooks/pubnub/usePubnub";
import AudioVideoCall from "@/components/call";
import { useDispatch, useSelector } from "react-redux";
import { handleIncomingCall } from "@/store/features/audioVideoCall/audioVideoSlice";

const AdminLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const username = useSelector((state) => state.auth.username) || "";
  const { subscribeToChannel, messages } = usePubNub();
  const dispatch = useDispatch();
  const subScribeToCall = useCallback(() => {
    subscribeToChannel("call-admin-mohamed");
  }, [subscribeToChannel]);
  useEffect(() => {
    subScribeToCall();
  }, [subScribeToCall]);
  useEffect(() => {
    const lastMessage = messages?.at(-1);
    if (lastMessage?.content?.receiver === username) {
      dispatch(
        handleIncomingCall({
          callType: lastMessage?.content?.callType,
          meetingUrl: lastMessage?.content?.meetingUrl,
          caller: lastMessage?.content?.caller,
        })
      );
    }
  }, [messages, dispatch, username]);
  return (
    <Layout>
      <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <StyledLayout collapsed={collapsed}>
        <Header />
        <StyledContent>
          <Outlet />
          <AiChat />
          <AudioVideoCall />
        </StyledContent>
        {/* <Footer /> */}
      </StyledLayout>
    </Layout>
  );
};
export default AdminLayout;
