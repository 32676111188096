import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Flex, Form, Upload, message } from "antd";
import imageCompression from "browser-image-compression";
const UploadImage = ({ form, name, imageUrl: image }) => {
  const [imageUrl, setImageUrl] = useState(image);

  const resizeImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error("Error resizing image:", error);
      message.error("Error processing image. Please try again.");
      return null;
    }
  };

  const handleChange = async (info) => {
    const file = info.fileList?.at(-1).originFileObj;

    if (file instanceof File) {
      const resizedFile = await resizeImage(file);
      if (resizedFile) {
        setImageUrl(URL.createObjectURL(resizedFile));
        form.setFieldsValue({ [name]: resizedFile });
      }
    } else {
      setImageUrl(null);
      form.setFieldsValue({ [name]: null });
    }
  };

  useEffect(() => {
    if (!imageUrl && image) {
      setImageUrl(image);
    }
  }, [image, imageUrl]);

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <Flex gap="middle" wrap>
      <Form.Item name={name} getValueFromEvent={(e) => e && e.file}>
        <Upload
          name={name}
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={(file) => {
            const isImage = file.type.startsWith("image/");
            if (!isImage) {
              message.error("You can only upload image files!");
            }
            return false;
          }}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </Form.Item>
    </Flex>
  );
};

export default UploadImage;
