import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isButtonLoading: false,
  currentStep: 0,
  isModalOpen: false,
};

const ccmFormSlice = createSlice({
  name: "ccmFormSlice",
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setIsButtonLoading: (state, action) => {
      state.isButtonLoading = action.payload;
    },
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
  },
});

export const { setCurrentStep, setIsButtonLoading, setIsModalOpen } =
  ccmFormSlice.actions;
export default ccmFormSlice.reducer;
