import { Table, Button, Flex } from "antd";
import {
  useDeletePatientNoteMutation,
  useGetPatientNotesQuery,
} from "@/store/features/patientNotes/patientNoteApiSlice";
import { useState } from "react";
import dayjs from "dayjs";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
const NotesTable = ({ patientId, setIsModalVisible, setPrevData }) => {
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const { data, isLoading } = useGetPatientNotesQuery({
    ...pagination,
    patientId,
  });
  const [deleteNote, { isLoading: deleteLoading }] =
    useDeletePatientNoteMutation();
  const { data: notes, pagination: paginationData } = data || {};
  const columns = [
    {
      title: "Date/Time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => dayjs(value)?.format("DD-MMM-YYYY HH:mm A"),
    },
    { title: "Notes", dataIndex: "notes", key: "notes" },
    { title: "Activity For", dataIndex: "activityFor", key: "activityFor" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Flex gap="10px">
          <Button type="link" onClick={() => handleEditNote(record)}>
            <EditOutlined />
          </Button>
          <Button
            type="link"
            onClick={() => handleDeleteNote(record)}
            loading={deleteLoading}
          >
            <DeleteOutlined />
          </Button>
        </Flex>
      ),
    },
  ];

  const handleEditNote = (record) => {
    setPrevData(record);
    setIsModalVisible(true);
  };

  const handleDeleteNote = (record) => {
    deleteNote(record?.patientNoteId);
  };

  return (
    <Table
      columns={columns}
      dataSource={notes}
      rowKey={(record) => record.id}
      loading={isLoading}
      pagination={{
        pageSize: pagination.pageSize,
        current: pagination.pageNumber,
        total: paginationData?.totalItems || 0,
        onChange: (page) =>
          setPagination((prev) => ({
            ...prev,
            pageNumber: page,
          })),
      }}
    />
  );
};

export default NotesTable;
