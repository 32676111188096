import styled from "styled-components";

export const StyledFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;
  flex: 1;
  background-color: #fff;
  padding: 21px;
  border-radius: 15px;
  overflow: hidden; // This will prevent any potential overflow
`;

export const StyledDivContainer = styled.div`
  width: 71rem;
  padding: 5px 0;
`;
