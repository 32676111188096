import AdditionalNotesForm from "./forms/AdditionalNotesForm";
import AllergiesForm from "./forms/AllergiesForm";
import BarriersForm from "./forms/BarriersForm";
import GoalsForm from "./forms/GoalsForm";
import MedicationsForm from "./forms/MedicationsForm";
import MonitoringForm from "./forms/MonitoringForm";
import PatientHistoryForm from "./forms/PatientHistoryForm";
import PreventiveCareForm from "./forms/PreventiveCareForm";
import PsychoNeuroTestingForm from "./forms/PsychoNeuroTestingForm";
import RiskClassificationForm from "./forms/RiskClassificationForm";
import SelfManagementForm from "./forms/SelfManagementForm";
import SymptomsForm from "./forms/SymptomsForm";
import { ContentWrapper } from "./styles";

const ContentContainer = () => {
  return (
    <ContentWrapper>
      <PatientHistoryForm multiStep={true} />
      <GoalsForm multiStep={true} />
      <BarriersForm multiStep={true} />
      <SymptomsForm multiStep={true} />
      <AllergiesForm multiStep={true} />
      <MedicationsForm multiStep={true} />
      <MonitoringForm multiStep={true} />
      <PreventiveCareForm multiStep={true} />
      <PsychoNeuroTestingForm multiStep={true} />
      <SelfManagementForm multiStep={true} />
      <AdditionalNotesForm multiStep={true} />
      <RiskClassificationForm multiStep={true} />
    </ContentWrapper>
  );
};

export default ContentContainer;
