import Icon from '@ant-design/icons'

const CustomIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        d="M8.026 6.923h.001c.34.114.569.43.724.725.092.176.178.382.256.617l.876-.506c-.35-.935-.854-1.555-1.541-1.785l-.316.949zm0 0c-.451-.15-.93.056-1.323.358-.416.32-.857.836-1.295 1.559C4.215 10.808 3.5 13.733 3.5 17c0 1.133.007 1.627.039 2.005l.035.418.42.037c.379.033.873.04 2.006.04 1.188 0 2.12-.338 2.723-1.164.574-.786.777-1.921.777-3.333l.001-.095 1.008-.582M8.026 6.923l2.483 7.403m0 0c-.005.258-.009.487-.009.674 0 1.46-.246 2.843-.916 3.848C8.938 19.817 7.854 20.5 6 20.5c-1.012 0-1.729 0-2.257-.06-.532-.059-.763-.168-.89-.294-.126-.126-.234-.357-.294-.889C2.501 18.73 2.5 18.012 2.5 17c0-3.68.86-6.71 2.053-8.678 1.219-2.012 2.641-2.73 3.789-2.348l2.167 8.352zM21.502 17v0c0 1.012-.001 1.729-.06 2.257-.06.532-.168.763-.294.89-.126.126-.357.235-.89.294-.527.058-1.244.059-2.256.059-1.854 0-2.938-.683-3.584-1.652-.67-1.005-.916-2.388-.916-3.848 0-.185-.004-.416-.01-.674l1.008.582.001.092v.001c0 1.413.203 2.549.777 3.335.604.826 1.535 1.164 2.723 1.164 1.133 0 1.627-.007 2.007-.04l.418-.037.036-.418c.032-.377.039-.872.039-2.005 0-3.267-.715-6.192-1.907-8.16h0c-.439-.723-.88-1.238-1.296-1.558-.392-.302-.87-.51-1.321-.36h-.002c-.34.115-.57.43-.724.726a4.157 4.157 0 00-.256.617l-.877-.506c.352-.935.855-1.556 1.541-1.785 1.148-.382 2.572.336 3.791 2.348 1.193 1.968 2.053 4.998 2.052 8.678zM11.5 9.422V2.5h1V9.711l.25.144 4.263 2.462-.5.866-4.263-2.461-.25-.144-.25.144-4.263 2.461-.5-.866 4.263-2.462.25-.144v-.289z"
      ></path>
    </svg>
  </svg>
)

const SpO2Icon = (props) => <Icon component={CustomIcon} {...props} />
export default SpO2Icon
