import ErrorAlert from "@/components/ui/errorAlert";
import useCreateProvidedDevices from "@/hooks/patient/useCreateProvidedDevices";
import useGetAllDevices from "@/hooks/patient/useGetAllDevices";
import RenderDeviceOptions from "@/Pages/AddNewPatientCollapse/ProvidedDevice/RenderDeviceOptions";
import { Button, Col, Divider, Form, Row, Skeleton, Typography } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Container } from "./Style";
import { useGetHealthConditionsByPatientIdQuery } from "@/store/features/patient/patientApiSlice";

export default function EditProvidedDevices() {
  const { patientId } = useParams();
  const { handleCreateProvidedDevices, isLoading } = useCreateProvidedDevices();
  const { formattedData: data, isLoading: isDeviceListLoading } =
    useGetAllDevices();
  const { data: healthConditionData, isLoading: isHealthConditionLoading } =
    useGetHealthConditionsByPatientIdQuery(patientId);

  const [form] = Form.useForm();
  const selectedDevices = Form.useWatch("devices", form);

  const onFinish = (values) => {
    const bluetoothDevices = values?.devices?.Bluetooth || [];
    const cellularDevices = values?.devices?.Cellular || [];
    const manualDevices = values?.devices?.Manual || [];
    const tenoviDevices = values?.devices?.Tenovi || [];

    let device_MappingData = [];
    bluetoothDevices.forEach((device, index) => {
      if (device && device.isProvided) {
        device_MappingData.push({
          deviceTypeId: 1,
          patientId: patientId,
          deviceId: index,
          deviceUniqueId: device.deviceUniqueId,
        });
      }
    });
    cellularDevices.forEach((device, index) => {
      if (device && device.isProvided) {
        device_MappingData.push({
          deviceTypeId: 2,
          patientId: patientId,
          deviceId: index,
          deviceUniqueId: device.deviceUniqueId,
        });
      }
    });
    manualDevices.forEach((device, index) => {
      if (device && device.isProvided) {
        device_MappingData.push({
          deviceTypeId: 3,
          patientId: patientId,
          deviceId: index,
          deviceUniqueId: device.deviceUniqueId,
        });
      }
    });
    tenoviDevices.forEach((device, index) => {
      if (device && device.isProvided) {
        device_MappingData.push({
          deviceTypeId: 4,
          patientId: patientId,
          deviceId: index,
          deviceUniqueId: device.deviceUniqueId,
        });
      }
    });
    const payload = {
      device_MappingData,
      healthConditionId: healthConditionData?.healthConditionId,
    };
    handleCreateProvidedDevices(payload);
  };

  return (
    <Container>
      <Form
        form={form}
        name="providedDevices"
        onFinish={onFinish}
        layout="vertical"
      >
        <ErrorAlert />
        <Typography.Title level={4}>
          Add New Device for Patient
        </Typography.Title>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={6}>
            {isDeviceListLoading ? (
              <Skeleton paragraph={{ rows: 3 }} />
            ) : (
              <RenderDeviceOptions
                title={data?.bluetooth?.title}
                devices={data?.bluetooth?.devices}
                selectedDevices={selectedDevices}
              />
            )}
          </Col>
          <Col xs={24} md={6}>
            {isDeviceListLoading ? (
              <Skeleton paragraph={{ rows: 3 }} />
            ) : (
              <RenderDeviceOptions
                title={data?.cellular?.title}
                devices={data?.cellular?.devices}
                selectedDevices={selectedDevices}
              />
            )}
          </Col>
          <Col xs={24} md={6}>
            {isDeviceListLoading ? (
              <Skeleton paragraph={{ rows: 3 }} />
            ) : (
              <RenderDeviceOptions
                title={data?.tenovi?.title}
                devices={data?.tenovi?.devices}
                selectedDevices={selectedDevices}
              />
            )}
          </Col>
          <Col xs={24} md={6}>
            {isDeviceListLoading ? (
              <Skeleton paragraph={{ rows: 3 }} />
            ) : (
              <RenderDeviceOptions
                title={data?.manual?.title}
                devices={data?.manual?.devices}
                selectedDevices={selectedDevices}
              />
            )}
          </Col>
        </Row>

        <Divider />

        <Row gutter={16} justify="end">
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading || isHealthConditionLoading}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
