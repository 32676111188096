import { Button, Flex, Form } from "antd";
import { StyledDivContainer } from "./Style";
import PhoneNumber from "./Component/PhoneNumber";
import SmsReminder from "./Component/SmsReminder";

const GlobalSettingsForm = (props) => {
  const {
    onFinish,
    form,
    userGlobalSettings,
    isLoading,
    isUpdateSettingsLoading,
    isGlobalSettingsExists
  } = props;

  return (
    <Form
      onFinish={onFinish}
      form={form}
      layout="vertical"
      initialValues={userGlobalSettings}
    >
      <StyledDivContainer>
        <PhoneNumber />
        <SmsReminder />
        <Flex justify="end">
          <Button
            loading={isLoading || isUpdateSettingsLoading}
            type="primary"
            htmlType="submit"
          >
            {isGlobalSettingsExists ? "Update" : "Save"}
          </Button>
        </Flex>
      </StyledDivContainer>
    </Form>
  );
};

export default GlobalSettingsForm;
