import BloodPressureMonitoringSetup from "@/components/forms/bloodPressureMonitoringSetup";
import GlucoseMonitoringSetup from "@/components/forms/glucoseMonitoringSetup";
import PulseMonitoringSetup from "@/components/forms/pulseMonitoringSetup";
import Spo2MonitoringSetup from "@/components/forms/sp02MonitoringSetup";
import TemperatureMonitoringSetup from "@/components/forms/temperatureMonitoringSetup";
import WeightMonitoringSetup from "@/components/forms/weightMonitoringSetup";
import ErrorAlert from "@/components/ui/errorAlert";
import useCreateRpmSetup from "@/hooks/patient/useRpmSetup";
import getRpmPayload from "@/utils/getRpmPayload";
import { Button, Checkbox, Col, Divider, Form, Row } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { StyledFormContainer } from "./Style";

const setupOptions = [
  { label: "Blood Pressure", value: 1 },
  { label: "Pulse", value: 2 },
  { label: "Weight", value: 3 },
  { label: "SpO2", value: 4 },
  { label: "Glucose", value: 5 },
  { label: "Temperature", value: 6 },
];

export default function RpmSetup() {
  const { handleCreateRpmSetup, isLoading } = useCreateRpmSetup();
  const [form] = Form.useForm();
  const { healthConditionId, defaultValues } = useSelector(
    (state) => state.addPatient
  );
  const setupValues = Form.useWatch("setupOptions", form);

  //insert into intial values
  useEffect(() => {
    const allValues = form.getFieldsValue(true);
    setupValues?.map((item) => {
      if (item === 1 && !allValues["systolic"]) {
        form.setFieldsValue({
          systolic: defaultValues.bloodPressure,
          diastolic: defaultValues.bloodPressure,
        });
      } else if (item !== 1) {
        const valueKeyMap = {
          2: "pulse",
          3: "weight",
          4: "spo2",
          5: "glucose",
          6: "temperature",
        };
        const key = valueKeyMap[item];
        form.setFieldValue(key, defaultValues[key]);
      }
    });
  }, [setupValues]);

  const onFinish = (values) => {
    // handleCreateRpmSetup(values);
    const payload = getRpmPayload(values, healthConditionId);
    handleCreateRpmSetup(payload);
  };

  return (
    <StyledFormContainer>
      <ErrorAlert />
      <Form
        name="rpmSetup"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        requiredMark={true}
      >
        <Row gutter={19} style={{ marginTop: "10px" }}>
          <Col span={24}>
            <Form.Item name="setupOptions">
              <Checkbox.Group options={setupOptions} />
            </Form.Item>
          </Col>
        </Row>

        <Divider />
        {setupValues?.includes(1) && <BloodPressureMonitoringSetup />}
        {setupValues?.includes(2) && <PulseMonitoringSetup />}
        {setupValues?.includes(3) && <WeightMonitoringSetup />}
        {setupValues?.includes(4) && <Spo2MonitoringSetup />}
        {setupValues?.includes(5) && <GlucoseMonitoringSetup />}
        {setupValues?.includes(6) && <TemperatureMonitoringSetup />}
        <Divider />

        <Row gutter={11}>
          <Col span={3}>
            <Form.Item>
              <Button style={{ borderRadius: "7px", padding: "2px 17px" }}>
                Previous
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button
                type="primary"
                style={{ borderRadius: "7px", padding: "2px 24px" }}
                htmlType="submit"
                loading={isLoading}
              >
                Next
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledFormContainer>
  );
}
