/**
 * External dependencies
 */

import { Flex, Form, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";

function RichTextEditor(props) {
  const { keyName, label, initialValue, form, readOnly, sx } = props;

  const reactQuillRef = useRef();

  const [value, setValue] = useState("");

  //Important:: Read Carefully:
  /**
   * When the editor loads, it tends to simplify the initial data. So What we're doing to counter that is,
   * After the editor loads the data and processes it initially, we'll set the form data again forcefully, causing it to preserve the data it had initially.
   */
  useEffect(() => {
    form.setFieldValue(keyName, initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyName, initialValue]);

  // uses for ReactQuill editor
  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, false] }, { font: [] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],

        ["clean"],
      ],
    },
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];
  return (
    <div
      style={{
        borderRadius: "6px",
        backgroundColor: "white",
        padding: "15px",
      }}
    >
      {label && (
        <Flex alignItems="center" style={{ marginBottom: "20px" }}>
          <Typography.Text
            style={{
              fontSize: "16px",
              color: "#414454",
              fontWeight: 600,
            }}
          >
            {label}
          </Typography.Text>
        </Flex>
      )}

      <Form.Item name={keyName}>
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={value}
          onChange={(value) => {
            setValue(value);
          }}
          ref={reactQuillRef}
          readOnly={readOnly}
          style={{ height: "300px", borderRadius: "6px", ...sx }}
        />
      </Form.Item>
    </div>
  );
}

export default RichTextEditor;
