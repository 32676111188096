import { Divider, Form, Radio } from 'antd'
import styled from 'styled-components'

export const StyledForm = styled(Form)`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`

export const StyledDivider = styled(Divider)`
  margin: 24px 0;
`

export const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;

  .ant-radio-button-wrapper {
    width: 33.33%;
    text-align: center;
  }

  @media (max-width: 768px) {
    .ant-radio-button-wrapper {
      width: 100%;
      margin-bottom: 8px;
    }
  }
`
