import AlertRangePicker from "@/components/shared/alertRangePicker";
import SetupSubTitle from "@/components/shared/SetupSubTitle";
import SetupTitle from "@/components/shared/SetupTitle";
import { Card, Col, Input, Row, Select, Form } from "antd";

const PulseMonitoringSetup = () => {
  return (
    <Card style={{ margin: "10px 0px" }}>
      <Row>
        <Col xs={24} sm={4}>
          <SetupTitle>Pulse</SetupTitle>
          <SetupSubTitle>(bpm)</SetupSubTitle>
        </Col>
        <Col xs={24} sm={20}>
          <AlertRangePicker
            label={"Pulse Rate"}
            name="pulse"
            min={18}
            max={320}
          />

          <Form.Item
            label="Intevention Notes"
            name="interventionNotesForPulse"
            rules={[
              { required: true, message: "Please write your notes here!" },
            ]}
          >
            <Input.TextArea
              placeholder="Please write your notes here"
              rows={5}
            />
          </Form.Item>
          <Form.Item
            label="Frequency"
            name="frequencyTypeIdOfPulse"
            rules={[
              {
                required: true,
                message: "Please select observation frequency!",
              },
            ]}
          >
            <Select
              placeholder="Observation Frequency"
              options={[
                {
                  label: "Once a day",
                  value: 1,
                },
                {
                  label: "Twice a day",
                  value: 2,
                },
                {
                  label: "Thrice a day",
                  value: 3,
                },
              ]}
              style={{ width: "100%", maxWidth: 300 }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default PulseMonitoringSetup;
