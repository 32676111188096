// components/Stopwatch.js
import { setNotification } from "@/store/features/notification/notificationSlice";
import {
  useSendHeartBeatMutation,
  useStartTimeTrackingMutation,
  useStopTimeTrackingMutation,
} from "@/store/features/timeTracking/timeTrackerApiSlice";
import {
  setTimeTracerId,
  startTracking,
  stopTracking,
} from "@/store/features/timeTracking/timeTrackerSlice";
import { Button, notification } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
const { confirm } = Modal;
const StopwatchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const TimeDisplay = styled.div`
  font-size: 24px;
`;

const Stopwatch = () => {
  const { startTime, continueStopWatch, timeTrackerId, cptCode, moduleName } =
    useSelector((state) => state.timeTracker);
  const [elapsedTime, setElapsedTime] = useState(0);
  const dispatch = useDispatch();
  const { patientId } = useParams();
  // Initialize mutations
  const [startTimeTracking, { error: startError }] =
    useStartTimeTrackingMutation();
  const [stopTimeTracking, { error: stopError }] =
    useStopTimeTrackingMutation();
  const [handleSendHeartBeat, { error: heartBeatError }] =
    useSendHeartBeatMutation();

  const handleStartTracking = async () => {
    const startTime = new Date().toISOString();
    try {
      const response = await startTimeTracking({
        startTime,
        moduleName: moduleName,
        patientId: Number(patientId),
        cptCodeId: Number(cptCode),
      });
      if (response?.data?.id) {
        dispatch(startTracking(startTime));
        dispatch(setTimeTracerId(response.data.id));
      }
    } catch {
      // Error is handled in the useEffect
    }
  };

  const handleStopTracking = async () => {
    const endTime = new Date().toISOString();
    try {
      const response = await stopTimeTracking({
        endTime,
        timeTrackerId: Number(timeTrackerId),
      });
      if (response) {
        setElapsedTime(0);
        dispatch(stopTracking());
      }
    } catch {
      dispatch(
        setNotification({
          type: "error",
          message: "Failed to stop time tracking",
        })
      );
    }
  };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hrs = String(Math.floor(totalSeconds / 3600)).padStart(2, "0");
    const mins = String(Math.floor((totalSeconds % 3600) / 60)).padStart(
      2,
      "0"
    );
    const secs = String(totalSeconds % 60).padStart(2, "0");
    return `${hrs}:${mins}:${secs}`;
  };

  const showConfirm = () => {
    confirm({
      title: "Time Tracking",
      icon: <ExclamationCircleFilled />,
      content: "Would you like to stop time tracking?",
      okText: "Yes",
      cancelText: "No",
      onOk: handleStopTracking,
      onCancel() {
        // Do nothing if the user cancels
      },
    });
  };

  useEffect(() => {
    let timer;
    console.log("I am Running");
    if (continueStopWatch && startTime) {
      timer = setInterval(() => {
        const now = Date.now();
        const start = new Date(startTime).getTime();
        setElapsedTime(now - start);
      }, 1000);
    }

    return () => {
      console.log("I have been removed");
      if (timer) clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continueStopWatch, startTime]);

  useEffect(() => {
    if (startError) {
      notification.error({
        message: "Failed to start time tracking",
      });
    }
    if (stopError) {
      notification.error({
        message: "Failed to stop time tracking",
      });
    }
    if (heartBeatError) {
      notification.error({
        message: "Failed to send heartbeat!",
      });
    }
  }, [startError, stopError, heartBeatError]);

  useEffect(() => {
    let intervalId;
    if (continueStopWatch) {
      intervalId = setInterval(() => {
        handleSendHeartBeat({
          timeTrackerId: Number(timeTrackerId),
        });
      }, 1000 * 30);
      return () => {
        if (intervalId) clearInterval(intervalId);
      };
    }
  }, [continueStopWatch, handleSendHeartBeat, timeTrackerId]);

  useEffect(() => {
    if (!patientId && continueStopWatch && timeTrackerId) {
      showConfirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  if (!continueStopWatch) return null;

  return (
    <StopwatchContainer>
      <TimeDisplay>{formatTime(elapsedTime)}</TimeDisplay>
      {continueStopWatch ? (
        <Button type="primary" danger onClick={handleStopTracking}>
          Stop
        </Button>
      ) : (
        <Button type="primary" onClick={handleStartTracking}>
          Start
        </Button>
      )}
    </StopwatchContainer>
  );
};

export default Stopwatch;
