import styled from 'styled-components'

export const StyledMainContainer = styled.div`
  display: flex;

  flex-direction: column;

  width: 60%;

  height: 230px;

  justify-content: center;

  padding: 20px;

  margin-top: 20px;

  background-color: #fff;

  border-radius: 9px;
`

export const StyledTitleDiv = styled.div`
  display: flex;

  justify-content: space-between;
`

export const StyledContaintDiv = styled.div`
  display: flex;

  flex-direction: column;
`
export const StyledButton = styled.div`
  display: flex;

  justify-content: flex-end;
`
