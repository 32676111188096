import { GET_PATIENT_DASHBOARD_DATA } from "@/constants/apiDefinition";
import { apiSlice } from "@/store/features/api/apiSlice";

export const dashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardData: builder.query({
      query: ({ pageNumber = 1, pageSize = 50, ...restArgs }) => ({
        url: GET_PATIENT_DASHBOARD_DATA,
        method: "GET",
        params: { pageNumber, pageSize, ...restArgs },
      }),
      transformResponse: (response, meta) => {
        const paginationHeader = meta.response.headers.get("Pagination");
        let pagination = null;

        try {
          pagination = paginationHeader ? JSON.parse(paginationHeader) : null;
        } catch (error) {
          console.error("Error parsing pagination header:", error);
        }

        return {
          data: response,
          totalItems: pagination?.totalItems,
        };
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: "Dashboard", id })),
              { type: "Dashboard", id: "LIST" },
            ]
          : [{ type: "Dashboard", id: "LIST" }],
    }),
    getLocations: builder.query({
      query: () => "/api/PatientDashboard/locations",
      transformResponse: (response) =>
        response.map((location) => ({ label: location, value: location })),
    }),
    getConditions: builder.query({
      query: () => "/api/PatientDashboard/conditions",
      transformResponse: (response) => {
        const data = response.map((condition) => condition?.conditionName);
        const uniqueData = [...new Set(data)];
        return uniqueData.map((condition) => ({
          label: condition,
          value: condition,
        }));
      },
    }),
  }),
});

export const {
  useGetDashboardDataQuery,
  useGetLocationsQuery,
  useGetConditionsQuery,
} = dashboardApi;
