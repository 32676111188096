import { LOGIN_PATH } from '@/routes/routeDefinitions'
import { apiSlice } from '@/store/features/api/apiSlice'
import { useVerifyPhoneMutation } from '@/store/features/auth/authApiSlice'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const useVerifyPhone = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [verify, { isLoading, isSuccess, isError, data, error }] =
    useVerifyPhoneMutation()

  const handleVerification = (credentials) => {
    verify(credentials)
  }

  useEffect(() => {
    if (!isLoading && isSuccess) {
      const userId = localStorage.getItem('userId')
      localStorage.removeItem('userId')
      localStorage.removeItem(`otpAttempts_${userId}`)
      apiSlice.util.resetApiState()
      navigate(LOGIN_PATH)
    }
  }, [isLoading, isSuccess, data, dispatch, navigate])

  return { handleVerification, isLoading, isSuccess, isError, data, error }
}

export default useVerifyPhone
