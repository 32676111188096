import { Flex } from "antd";
import styled from "styled-components";

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color || "#a8a8aa"};
`;

const ValueText = styled.div`
  font-size: 16px;
  font-weight: ${(props) => (props?.hasValue ? 600 : "normal")};
  color: ${(props) => (props?.hasValue ? "#242235" : "#aaaaaa")};
`;

const StatCounter = ({ icon, value, color, prompt, ...rest }) => {
  return (
    <Flex
      align="center"
      gap="small"
      vertical
      style={{ cursor: prompt && "pointer" }}
      {...rest}
    >
      <IconWrapper color={color}>{icon}</IconWrapper>
      <ValueText hasValue={value !== "N/A"}>{value}</ValueText>
    </Flex>
  );
};

export default StatCounter;
