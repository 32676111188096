import {
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  LOGOUT_URL,
  PROFILE_PASSWORD_UPDATE_URL,
  REGISTER_STEP_1_URL,
  RESET_PASSWORD_URL,
  USER_PROFILE_URL,
  VERIFY_PHONE_URL,
} from "@/constants/apiDefinition";
import { apiSlice } from "@/store/features/api/apiSlice";

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: LOGIN_URL,
        method: "POST",
        body: { ...credentials },
      }),
    }),
    register: builder.mutation({
      query: (data) => ({
        url: REGISTER_STEP_1_URL,
        method: "POST",
        body: { ...data },
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: FORGOT_PASSWORD_URL,
        method: "POST",
        body: { ...data },
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: RESET_PASSWORD_URL,
        method: "POST",
        body: { ...data },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: LOGOUT_URL,
        method: "POST",
        body: {},
      }),
    }),
    userProfile: builder.query({
      query: () => ({
        url: USER_PROFILE_URL,
        method: "GET",
      }),
      providesTags: ["UserProfile"],
      // This is the tag name that will be used by the query hook to invalidate or revalidate cache
    }),
    profileUpdate: builder.mutation({
      query: (data) => ({
        url: USER_PROFILE_URL,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["UserProfile"],
    }),
    profilePasswordUpdate: builder.mutation({
      query: (data) => ({
        url: PROFILE_PASSWORD_UPDATE_URL,
        method: "PUT",
        body: data,
      }),
    }),
    verifyPhone: builder.mutation({
      query: (data) => ({
        url: VERIFY_PHONE_URL,
        method: "POST",
        body: data,
      }),
    }),
    resendOtp: builder.mutation({
      query: (data) => ({
        url: VERIFY_PHONE_URL,
        method: "POST",
        body: data,
      }),
    }),
  }),
});
export const {
  useLoginMutation,
  useUserProfileQuery,
  useLogoutMutation,
  useResetPasswordMutation,
  useRegisterMutation,
  useForgotPasswordMutation,
  useProfileUploadAvatarMutation,
  useProfileUpdateMutation,
  useProfilePasswordUpdateMutation,
  useVerifyPhoneMutation,
  useResendOtpMutation,
} = authApi;
