import { useState, useEffect } from 'react'
import { Button, Form, Input, Typography, notification } from 'antd'
import {
  StyledSection,
  Container,
  Header,
  StyledTitle,
  StyledText,
  FormItem,
  ResendLink,
  TimerText,
} from './style'
import Logo from '@/components/ui/logo'
import useVerifyPhone from '@/hooks/auth/useVerifyPhone'
import useResendOtp from '@/hooks/auth/useResendOtp'
import ErrorAlert from '@/components/ui/errorAlert'

const { Text } = Typography

const OTPVerification = () => {
  const [form] = Form.useForm()
  const [timer, setTimer] = useState(0)
  const [resendCount, setResendCount] = useState(0)
  const [isResendDisabled, setIsResendDisabled] = useState(false)
  const userId = localStorage.getItem('userId')
  const { handleVerification, isLoading } = useVerifyPhone()
  const { handleResend, isResendLoading, isError } = useResendOtp()
  useEffect(() => {
    // Load resend count from localStorage
    const storedData = localStorage.getItem(`otpAttempts_${userId}`)
    if (storedData) {
      const { resendCount: storedCount } = JSON.parse(storedData)
      setResendCount(storedCount)
      if (storedCount >= 5) {
        setIsResendDisabled(true)
      }
    }
  }, [userId])

  useEffect(() => {
    let interval
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1)
      }, 1000)
    } else {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [timer])

  const onFinish = (values) => {
    handleVerification(values)
  }

  const handleOtpResend = () => {
    if (resendCount >= 5) {
      notification.error({
        message: 'Resend limit exceeded',
        description:
          'You have exceeded the maximum number of resend attempts. Please try again in a few hours.',
      })
      return
    }
    handleResend(userId)
    if (isError) {
      console.log('error', isError)
      const newResendCount = resendCount + 1
      // Set cooldown timer
      if (newResendCount === 1) {
        setTimer(30)
      } else if (newResendCount === 2) {
        setTimer(60)
      } else {
        setTimer(300)
      }

      if (newResendCount >= 5) {
        setIsResendDisabled(true)
      }
    }
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`
  }

  return (
    <StyledSection>
      <Container>
        <Header>
          <Logo text={true} />
          <StyledTitle level={2}>Verify Your Account</StyledTitle>
          <StyledText>
            We&apos;ve sent a 6-digit verification code to your email. Please
            enter the code below.
          </StyledText>
        </Header>
        <ErrorAlert />
        <Form
          form={form}
          name="otpForm"
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
        >
          <FormItem
            name="otp"
            rules={[
              {
                required: true,
                message: 'Please input the verification code!',
              },
            ]}
          >
            <Input.OTP inputType="numeric" length={6} autoFocus size="large" />
          </FormItem>
          <FormItem>
            <Button
              block
              type="primary"
              htmlType="submit"
              size="large"
              loading={isLoading}
            >
              Verify
            </Button>
          </FormItem>
        </Form>
        {isResendDisabled ? (
          <Text type="danger">
            Resend limit exceeded. Please try again in a few hours.
          </Text>
        ) : (
          <>
            <ResendLink
              onClick={handleOtpResend}
              disabled={timer > 0 || isResendDisabled || isResendLoading}
            >
              Didn&apos;t receive the code? Resend
            </ResendLink>
            {timer > 0 && (
              <TimerText>Resend available in: {formatTime(timer)}</TimerText>
            )}
          </>
        )}
      </Container>
    </StyledSection>
  )
}

export default OTPVerification
