import Icon from '@ant-design/icons'

const CustomIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.8"
      d="M12 21a9 9 0 100-18 9 9 0 000 18z"
    ></path>
    <path
      fill="transparent"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 6v6l4 2"
    ></path>
  </svg>
)

const BloodPressureIcon = (props) => <Icon component={CustomIcon} {...props} />
export default BloodPressureIcon
