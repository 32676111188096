import { setHasAcceptedCall } from "@/store/features/audioVideoCall/audioVideoSlice";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const CallContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background-color: #f0f2f5;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const CallInfo = styled.h2`
  margin-bottom: 24px;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const AcceptButton = styled(Button)`
  &.ant-btn-primary {
    background-color: #52c41a;
    border-color: #52c41a;
    &:hover,
    &:focus {
      background-color: #73d13d;
      border-color: #73d13d;
    }
  }
`;

const RejectButton = styled(Button)`
  &.ant-btn-primary {
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    &:hover,
    &:focus {
      background-color: #ff7875;
      border-color: #ff7875;
    }
  }
`;

const IncomingCall = ({ callType, caller }) => {
  const dispatch = useDispatch();

  const onAccept = () => {
    dispatch(setHasAcceptedCall(true));
  };
  const onReject = () => {
    dispatch(setHasAcceptedCall(false));
  };
  return (
    <CallContainer>
      <CallInfo>
        Incoming {callType === "audio" ? "Call" : "Video Call"} from {caller}
      </CallInfo>
      <ButtonContainer>
        <AcceptButton type="primary" size="middle" onClick={onAccept}>
          Accept
        </AcceptButton>
        <RejectButton type="primary" size="middle" onClick={onReject}>
          Reject
        </RejectButton>
      </ButtonContainer>
    </CallContainer>
  );
};

export default IncomingCall;
