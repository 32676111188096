import { BASE_URL, GET_RPM_RTM_BY_ID_URL } from "@/constants/apiDefinition";
import { apiSlice } from "@/store/features/api/apiSlice";

export const patientDetailsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPatientBloodPressureById: builder.query({
      query: (id, pageNumber = 1, pageSize = 15) => ({
        url: `${BASE_URL}/api/PatientBloodPressure?PageNumber=${pageNumber}&PageSize=${pageSize}&patientId=${id}`,
        method: "GET",
      }),
    }),
    getPatientPulseById: builder.query({
      query: (id, pageNumber = 1, pageSize = 15) => ({
        url: `${BASE_URL}/api/PatientPulse?PageNumber=${pageNumber}&PageSize=${pageSize}&patientId=${id}`,
        method: "GET",
      }),
    }),
    getPatientWeightById: builder.query({
      query: (id, pageNumber = 1, pageSize = 15) => ({
        url: `${BASE_URL}/api/PatientWeight?PageNumber=${pageNumber}&PageSize=${pageSize}&patientId=${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Weight", id }],
    }),
    getPatientSpO2ById: builder.query({
      query: (id, pageNumber = 1, pageSize = 15) => ({
        url: `${BASE_URL}/api/PatientSPo2?PageNumber=${pageNumber}&PageSize=${pageSize}&patientId=${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "SpO2", id }],
    }),
    getPatientGlucoseById: builder.query({
      query: (id, pageNumber = 1, pageSize = 15) => ({
        url: `${BASE_URL}/api/PatientGlucose?PageNumber=${pageNumber}&PageSize=${pageSize}&patientId=${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Glucose", id }],
    }),
    getRPMByHealthId: builder.query({
      query: ({ pageNumber, pageSize, healthConditionId }) => ({
        url: GET_RPM_RTM_BY_ID_URL,
        method: "GET",
        params: { pageNumber, pageSize, healthConditionId },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "RPMData", id })),
              { type: "RPMData", id: "LIST" },
            ]
          : [{ type: "RPMData", id: "LIST" }],
    }),
    getDevicesByPatientId: builder.query({
      query: ({ pageNumber = 1, pageSize = 50, patientId }) => ({
        url: `${BASE_URL}/api/devicepatientmapping?PageNumber=${pageNumber}&PageSize=${pageSize}&patientId=${patientId}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Devices", id })),
              { type: "Devices", id: "LIST" },
            ]
          : [{ type: "Devices", id: "LIST" }],
    }),
    getTemperatureByPatientId: builder.query({
      query: ({ pageNumber = 1, pageSize = 50, patientId }) => ({
        url: `${BASE_URL}/api/PatientTemperature?PageNumber=${pageNumber}&PageSize=${pageSize}&patientId=${patientId}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Temperatures", id })),
              { type: "Temperatures", id: "LIST" },
            ]
          : [{ type: "Temperatures", id: "LIST" }],
    }),
    getAllCaregiverByPatientId: builder.query({
      query: ({ pageNumber = 1, pageSize = 50, patientId }) => ({
        url: `${BASE_URL}/api/CareGiverInfo`,
        method: "GET",
        params: { PageNumber: pageNumber, PageSize: pageSize, patientId },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Caregiver", id })),
              { type: "Caregiver", id: "LIST" },
            ]
          : [{ type: "Caregiver", id: "LIST" }],
    }),
  }),
});

export const {
  useGetPatientBloodPressureByIdQuery,
  useGetPatientGlucoseByIdQuery,
  useGetPatientSpO2ByIdQuery,
  useGetPatientWeightByIdQuery,
  useGetPatientPulseByIdQuery,
  useGetRPMByHealthIdQuery,
  useGetDevicesByPatientIdQuery,
  useGetTemperatureByPatientIdQuery,
  useGetAllCaregiverByPatientIdQuery,
} = patientDetailsApi;
