/**
 * Generates an object of marks dividing a range into 6 equal parts.
 *
 * @param {number} min - The minimum value of the range.
 * @param {number} max - The maximum value of the range.
 * @returns {Object} An object where keys are the mark positions and values are their string representations.
 *
 * @example
 * const marks = getEquallyDividedMarks(0, 100);
 * // Returns: {0: "0", 20: "20", 40: "40", 60: "60", 80: "80", 100: "100"}
 */
const getEquallyDividedMarks = (min, max) => {
  const step = (max - min) / 5 // We use 5 because we want 6 points total (including min and max)
  const marks = {}

  for (let i = 0; i < 6; i++) {
    const value = Math.round(min + step * i)
    marks[value] = value.toString()
  }

  return marks
}

export default getEquallyDividedMarks
