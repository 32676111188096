import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Empty, Flex, Form, Input, Typography } from "antd";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import {
  useGetCCMAdditionalInfoQuery,
  useCreateCCMAdditionalNotesMutation,
  useUpdateCCMAdditionalNotesMutation,
} from "@/store/features/ccm/ccmApiSlice";
import LoadingComponent from "@/components/LoadingComponent";
import { CcmContext } from "../../withCcmData";

const PsychoNeuroTestingForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);
  const { date } = useContext(CcmContext);
  const {
    data: psychoNeuroInfo,
    isLoading: isPsychoNeuroLoading,
    isSuccess,
  } = useGetCCMAdditionalInfoQuery({
    patientId,
    date,
    pageNumber: 1,
    pageSize: 1,
    ccmAdditionalInfoTypeId: 5,
  });
  const [createPsychoNeuroInfo, { isLoading: isCreating }] =
    useCreateCCMAdditionalNotesMutation();
  const [updatePsychoNeuroInfo, { isLoading: isUpdating }] =
    useUpdateCCMAdditionalNotesMutation();

  useEffect(() => {
    if (psychoNeuroInfo && psychoNeuroInfo.length > 0) {
      form.setFieldsValue({ description: psychoNeuroInfo[0].description });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [psychoNeuroInfo, form, multiStep]);

  const handleFinish = async () => {
    const values = form.getFieldsValue(true);
    const payload = { ...values, ccmAdditionalInfoTypeId: 5, patientId };
    if (psychoNeuroInfo && psychoNeuroInfo.length > 0) {
      await updatePsychoNeuroInfo({
        ...payload,
        ccmAdditionalInfoId: psychoNeuroInfo[0].ccmAdditionalInfoId,
        isInitial: false,
      });
    } else {
      await createPsychoNeuroInfo({ ...payload, isInitial: true });
    }
    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  if (multiStep && currentStep !== 8) return null;

  let content = null;
  if (isPsychoNeuroLoading) {
    content = <LoadingComponent text="   Psychological Info Loading ...." />;
  }
  if (psychoNeuroInfo?.length === 0 && !multiStep) return <Empty />;
  if (isSuccess) {
    content = (
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <ErrorAlert />
        <Flex vertical gap={10}>
          <Typography.Title level={5}>
            Has the patient gone through Psychological and Neuro-Psychological
            Testing?
          </Typography.Title>

          <Form.Item name="description" style={{ margin: "0px 15px" }}>
            <Input.TextArea
              placeholder="Please describe in Details"
              rows={10}
              disabled={multiStep ? false : !isEditing}
            />
          </Form.Item>
        </Flex>

        {multiStep ? (
          <StepControls
            isLoading={isCreating || isUpdating}
            onNextClick={form.submit}
          />
        ) : null}
      </Form>
    );
  }

  return content;
};

export default PsychoNeuroTestingForm;
