import dayjs from "dayjs";
const useCreatePatientValidation = () => {
  const validateMRN = (_, value) => {
    const regex = /^[a-zA-Z0-9]{1,10}$/;
    if (!value) {
      return Promise.reject("This field is required");
    }
    if (!regex.test(value)) {
      return Promise.reject(
        "Must be exactly 10 characters long and contain only letters and numbers"
      );
    }
    return Promise.resolve();
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const disabledDate = () => {
    // Can't select days before today
    const dateFormat = "YYYY-MM-DD";
    return dayjs(getCurrentDate, dateFormat);
  };
  return { validateMRN, disabledDate };
};

export default useCreatePatientValidation;
