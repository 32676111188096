// Import the action creator for setting notifications in our Redux store
import { setNotification } from "@/store/features/notification/notificationSlice";

/**
 * Creates a notification object based on the API response
 *
 * @param {Object} response - The response from an API call
 * @returns {Object} An object with 'type' and 'message' for the notification
 */
const createNotificationObject = (response) => {
  if (response.meta.response.ok) {
    return {
      type: "success",
      message: response?.data?.message || "Operation Successful!",
    };
  } else {
    return {
      type: "error",
      message: response?.error?.errorMessage || "Something went wrong!",
    };
  }
};

/**
 * Handles creating a notification and dispatching it to the Redux store
 *
 * @param {Object} response - The response from an API call
 */
const handleNotification = (response) => {
  const notificationMessage = createNotificationObject(response);

  // Import the store and dispatch the notification
  // We're using dynamic import to avoid circular dependency issues
  import("@/store/store").then(({ store }) => {
    // Dispatch the setNotification action to update our Redux store
    store.dispatch(setNotification(notificationMessage));
  });
};

export default handleNotification;

// Here's a simple explanation of what this code does:
// 1. We have a function that looks at the response from our server.
// 2. It decides if the response is good (success) or bad (error).
// 3. It creates a message to show to the user based on this response.
// 4. Then, it sends this message to our app's central data store (Redux).
// 5. Our app can then show this message to the user as a notification.

// This helps our app keep the user informed about what's happening,
// whether things are working well or if there's a problem.
