import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  message: null,
  showModal: false,
  shouldSendAutomatically: false,
};

const chatContextSlice = createSlice({
  name: "aiChatContext",
  initialState,
  reducers: {
    setAIMessage: (state, action) => {
      state.message = action.payload;
      return state;
    },
    setAIMessageModal: (state, action) => {
      state.showModal = action.payload;
      return state;
    },
    setShouldSendAutomatically: (state, action) => {
      state.shouldSendAutomatically = action.payload;
      return state;
    },
  },
});

export const { setAIMessage, setAIMessageModal, setShouldSendAutomatically } =
  chatContextSlice.actions;
export default chatContextSlice.reducer;
