export function getPatientDataSummary(data) {
  // Create a copy of the data array before sorting
  const sortedData = [...data].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  // 1. Calculate days since the last entry
  const lastCreatedAt = new Date(sortedData[0].createdAt);
  const today = new Date();
  const daysSinceLastEntry = Math.floor(
    (today - lastCreatedAt) / (1000 * 60 * 60 * 24)
  );

  // 2. Find maximum patient data value
  const maxPatientData = Math.max(...data.map((item) => item.patientData));

  // 3. Calculate average patient data value
  const averagePatientData =
    data.reduce((sum, item) => sum + item.patientData, 0) / data.length;

  // 4. Calculate date range
  const oldestDate = new Date(
    Math.min(...data.map((item) => new Date(item.createdAt)))
  );
  const newestDate = new Date(
    Math.max(...data.map((item) => new Date(item.createdAt)))
  );

  const formatDate = (date) => {
    return date.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const dateRange = `${formatDate(oldestDate)} - ${formatDate(newestDate)}`;

  // 5. Calculate the number of days in the date range
  const daysInDateRange =
    Math.floor((newestDate - oldestDate) / (1000 * 60 * 60 * 24)) + 1; // +1 to include both start and end dates

  // 6. Format the last date and days count as requested
  const formatLastDateAndDays = (date, days) => {
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();
    return `(${month} ${year})-${days} Day's`;
  };

  const lastDateAndDaysFormatted = formatLastDateAndDays(
    lastCreatedAt,
    daysInDateRange
  );

  // Function to format date as "Month DD, YYYY"
  const formatAllCreatedAtDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("en-US", { month: "short" });
    const day = date.toLocaleString("en-US", { day: "numeric" });
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
    return `${month} ${day}, ${year}`;
  };

  // Create a new array with formatted createdAt
  const formattedData = data.map((item) => ({
    ...item,
    createdAt: formatAllCreatedAtDate(item.createdAt),
  }));

  // 5. Calculate the number of days in the date range
  const totalDaysInRange =
    Math.floor((newestDate - oldestDate) / (1000 * 60 * 60 * 24)) + 1; // +1 to include both start and end dates

  return {
    daysSinceLastEntry,
    maxPatientData,
    averagePatientData: averagePatientData.toFixed(2),
    dateRange,
    lastDateAndDays: lastDateAndDaysFormatted,
    formattedData,
    totalDaysInRange,
  };
}

export function analyzeBloodPressure(data) {
  if (!data || data.length === 0) {
    return {
      error: "No data provided or empty dataset",
    };
  }

  let maxSystolic = -Infinity;
  let minDiastolic = Infinity;
  let maxEntry = null;
  let minEntry = null;

  for (const entry of data) {
    const { bloodPressureSystolic, bloodPressureDiastolic } = entry;

    if (bloodPressureSystolic > maxSystolic) {
      maxSystolic = bloodPressureSystolic;
      maxEntry = entry;
    }

    if (bloodPressureDiastolic < minDiastolic) {
      minDiastolic = bloodPressureDiastolic;
      minEntry = entry;
    }
  }

  return {
    max: {
      systolic: maxEntry.bloodPressureSystolic,
      diastolic: maxEntry.bloodPressureDiastolic,
      date: maxEntry.createdAt,
    },
    min: {
      systolic: minEntry.bloodPressureSystolic,
      diastolic: minEntry.bloodPressureDiastolic,
      date: minEntry.createdAt,
    },
  };
}
