import { useState } from "react";
import { Button, message } from "antd";
import { CopyOutlined, CheckOutlined } from "@ant-design/icons";

const CopyMRNButton = ({ mrn }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    if (!mrn) {
      message.error("No MRN available to copy");
      return;
    }

    try {
      await navigator.clipboard.writeText(mrn);
      setCopied(true);
      message.success("MRN copied to clipboard");
      setTimeout(() => setCopied(false), 3000); // Reset after 3 seconds
    } catch (err) {
      console.error("Failed to copy MRN:", err);
      message.error("Failed to copy MRN");
    }
  };

  return (
    <Button
      type="primary"
      icon={copied ? <CheckOutlined /> : <CopyOutlined />}
      onClick={handleCopy}
      style={{
        borderRadius: "25px",
        position: "relative",
        top: "-30px",
      }}
    >
      {copied ? "Copied!" : `#MRN: ${mrn || "N/A"}`}
    </Button>
  );
};

export default CopyMRNButton;
