import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Empty, Flex, Form, Radio, Typography } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";
import { setIsModalOpen } from "@/store/features/ccm/ccmFormSlice";
import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import RiskClassificationModal from "@/components/forms/patientRiskClassification/riskClassificationModal";
import {
  StyledCheckBoxContainer,
  StyledMainContainer,
} from "@/components/forms/patientRiskClassification/styles";
import {
  useGetCCMAdditionalInfoQuery,
  useCreateCCMAdditionalNotesMutation,
} from "@/store/features/ccm/ccmApiSlice";
import LoadingComponent from "@/components/LoadingComponent";
import { CcmContext } from "../../withCcmData";

const options = [
  { label: "High Risk", value: 1 },
  { label: "Moderate Risk", value: 2 },
  { label: "Low Risk", value: 3 },
];

const RiskClassificationForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);
  const [isClassificationModalOpen, setIsClassificationModalOpen] =
    useState(false);

  const { date } = useContext(CcmContext);

  const {
    data: riskClassification,
    isLoading: isRiskClassificationLoading,
    isSuccess,
  } = useGetCCMAdditionalInfoQuery({
    date,
    patientId,
    pageNumber: 1,
    pageSize: 1,
    ccmAdditionalInfoTypeId: 2,
  });
  const [createRiskClassification, { isLoading: isCreating }] =
    useCreateCCMAdditionalNotesMutation();

  useEffect(() => {
    if (riskClassification && riskClassification.length > 0) {
      form.setFieldsValue({
        riskClassificationTypeId:
          riskClassification[0].riskClassificationTypeId,
        isInitial: false,
      });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [riskClassification, form, multiStep]);

  const handleSubmit = async () => {
    const values = form.getFieldsValue(true);
    const payload = { ...values, ccmAdditionalInfoTypeId: 2, patientId };

    await createRiskClassification({ ...payload });
    setIsEditing(false);
    if (multiStep) {
      dispatch(setIsModalOpen(false));
    }
  };

  if (multiStep && currentStep !== 11) return null;

  let content = null;

  if (isRiskClassificationLoading) {
    content = <LoadingComponent text="  Risk Classification Loading ...." />;
  }
  if (riskClassification?.length === 0 && !multiStep) return <Empty />;
  if (isSuccess) {
    content = (
      <StyledMainContainer>
        <ErrorAlert />
        <Flex align="middle" gap="10px">
          <Typography.Title level={4}>
            What is the patient risk classification?
            <span
              onClick={() => setIsClassificationModalOpen(true)}
              style={{
                color: "#747474",
                cursor: "pointer",
                marginLeft: "10px",
              }}
            >
              <InfoCircleFilled style={{ fontSize: "18px" }} />
            </span>
          </Typography.Title>
        </Flex>
        <StyledCheckBoxContainer>
          <Form
            style={{ margin: "0px 15px" }}
            form={form}
            onFinish={handleSubmit}
          >
            <Form.Item name="riskClassificationTypeId">
              <Radio.Group
                options={options}
                disabled={multiStep ? false : !isEditing}
              />
            </Form.Item>
          </Form>
        </StyledCheckBoxContainer>

        <RiskClassificationModal
          visible={isClassificationModalOpen}
          onClose={() => setIsClassificationModalOpen(false)}
        />

        {multiStep ? (
          <StepControls isLoading={isCreating} onNextClick={form.submit} />
        ) : null}
      </StyledMainContainer>
    );
  }

  return content;
};

export default RiskClassificationForm;
