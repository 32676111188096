import React from 'react'
import { Form, Flex } from 'antd'
import { SliderContainer, SliderLabel, CustomSlider } from './styles'
import getEquallyDividedMarks from '@/utils/getEquallyDividedMarks'

const AlertRangePicker = (props) => {
  const { label, name, min = 30, max = 260, ...rest } = props

  const sliderColors = {
    rail: 'red',
    track1: '#f7c200',
    track2: '#05bf05',
    track3: '#f7c200',
  }

  const sliderProps = {
    range: true,
    min: min,
    max: max,
    step: 1,
    tooltip: {
      open: true,
    },
    marks: getEquallyDividedMarks(min, max),
  }

  return (
    <SliderContainer>
      <SliderLabel>{label}</SliderLabel>
      <Flex align="middle" justify="space-between" style={{ marginBottom: 10 }}>
        <div>Min {min}</div>
        <div>Max {max}</div>
      </Flex>
      <Form.Item name={name} {...rest}>
        <CustomSlider {...sliderProps} colors={sliderColors} />
      </Form.Item>
    </SliderContainer>
  )
}

export default AlertRangePicker
