import makeNotificationMessage from '@/utils/makeNotificationMessage'
import makeErrorAlertMessage from '@/utils/makeErrorAlertMessage'
const messageMiddleWare = (baseQuery) => async (args, api, extraOptions) => {
  // Execute the request using the baseQuery
  const response = await baseQuery(args, api, extraOptions)
  const isQuery = response.meta.request.method?.toUpperCase() === 'GET'
  const isErrorResponse = !!response.error

  const showNotification =
    (!isQuery && !isErrorResponse) || (isQuery && isErrorResponse)

  if (showNotification) {
    makeNotificationMessage(response)
  }

  if (!isQuery) {
    makeErrorAlertMessage(response)
  }

  return response
}

export default messageMiddleWare
