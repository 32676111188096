import CopyMRNButton from "@/components/shared/CopyButton";
import CallInterface from "@/Pages/Messages/CallInterface";
import {
  useGetHealthConditionsByPatientIdQuery,
  useGetPatientByIdQuery,
} from "@/store/features/patient/patientApiSlice";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import {
  Avatar,
  Card,
  Flex,
  Skeleton,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import {
  StyledCoverPhoto,
  StyledMainConatiner,
  StyledProfileDiv,
  StyledProfileEmailDiv,
  StyledUpperDiv,
} from "./styles";
import { useGetAllCaregiverByPatientIdQuery } from "@/store/features/patientDetails/patientDetailsApiSlice";

export default function SidebarDashboardComponent() {
  const { patientId } = useParams();
  const { data, isLoading } = useGetPatientByIdQuery(patientId);
  const { data: healthConditionsData, isLoading: isHealthConditionsLoading } =
    useGetHealthConditionsByPatientIdQuery(patientId);
  const { data: caregiverData, isLoading: isCaregiverLoading } =
    useGetAllCaregiverByPatientIdQuery({
      pageNumber: 1,
      pageSize: 50,
      patientId: patientId,
    });
  return (
    <StyledMainConatiner>
      <Spin spinning={isLoading}>
        <StyledUpperDiv>
          <StyledCoverPhoto>
            <img
              src="https://images.unsplash.com/photo-1683064325134-3acfdef9c6d7?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="Img Can't Load"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
            />
          </StyledCoverPhoto>

          <StyledProfileDiv>
            <div style={{ width: "160px", height: "160px" }}>
              <Avatar
                src={data?.photoUrl}
                alt={data?.firstName}
                shape="circle"
                size={160}
              >
                {data?.firstName[0]?.toUpperCase()}
                {data?.lastName[0]?.toUpperCase()}
              </Avatar>
              <CopyMRNButton mrn={data?.mrn} />
            </div>
            <Space
              direction="vertical"
              style={{ textAlign: "center", marginTop: "10px" }}
            >
              <Typography.Title level={4}>
                {data?.firstName} {data?.middleName} {data?.lastName}
              </Typography.Title>
              <Typography.Text type="secondary">
                (DOB: {dayjs(data?.dob).format("DD-MMM-YYYY")})
              </Typography.Text>
              {/* unsure about the next line what to show here  */}
              {/* <Typography.Text type="">
                Alcohol Abuse, Aliginitics Rigin
              </Typography.Text> */}
              <div>
                <Skeleton
                  active
                  loading={isHealthConditionsLoading}
                  paragraph={false}
                >
                  <Flex gap={15} justify="center">
                    {healthConditionsData?.[0]?.hasActivityRPM && (
                      <Tag color="green">RPM</Tag>
                    )}
                    {healthConditionsData?.[0]?.hasActivityCCM && (
                      <Tag color="blue">CCM</Tag>
                    )}
                    {healthConditionsData?.[0]?.hasActivityPCM && (
                      <Tag color="yellow">PCM</Tag>
                    )}
                    {healthConditionsData?.[0]?.hasActivityRTM && (
                      <Tag color="cyan">RTM</Tag>
                    )}
                  </Flex>
                </Skeleton>
              </div>
            </Space>
          </StyledProfileDiv>

          <Flex
            justify="center"
            align="center"
            vertical
            style={{ padding: "10px", marginTop: "40px" }}
            gap={10}
          >
            {data?.email && (
              <Typography.Text style={{ padding: "10px" }}>
                <Flex gap={10} align="center">
                  <MailOutlined size={50} color="#1677ff" />{" "}
                  <span>{data?.email}</span>
                </Flex>
              </Typography.Text>
            )}

            {data?.phone && (
              <Typography.Text style={{ padding: "10px" }}>
                <Flex gap={10} align="center">
                  <PhoneOutlined rotate={90} size={50} color="#1677ff" />{" "}
                  {data?.phone}
                </Flex>
              </Typography.Text>
            )}
          </Flex>
          <StyledProfileEmailDiv>
            <Space>
              <CallInterface recipientUsername={data?.firstName} />
            </Space>
          </StyledProfileEmailDiv>
          <Typography.Title level={4} align="center">
            Caregivers
          </Typography.Title>
          <Flex
            vertical
            justify="center"
            align="center"
            gap={15}
            style={{ marginTop: "20px" }}
          >
            <Skeleton loading={isCaregiverLoading} active paragraph={false}>
              {caregiverData?.map((caregiver) => (
                <Card
                  key={caregiver?.careGiverInfoId}
                  style={{
                    width: "90%",
                    boxShadow: "5px 5px rgba(50,50,50,0.05)",
                  }}
                  title={`${caregiver.firstName} ${caregiver.lastName}`}
                >
                  <p>{caregiver?.emailAddress}</p>
                  <p>{caregiver?.phoneNumber}</p>
                  <p>Relation with Patient: {caregiver?.relationshipType}</p>
                </Card>
              ))}
            </Skeleton>
          </Flex>
        </StyledUpperDiv>
      </Spin>
    </StyledMainConatiner>
  );
}
