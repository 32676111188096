import styled from "styled-components";

// Wrapper for the entire pagination section
export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 16px;
  background-color: #f0f2f5;
  border-radius: 8px;
`;

// Styled component for displaying page info
export const PageInfo = styled.span`
  font-size: 14px;
  color: #8c8c8c;
`;
