import { Flex, Typography } from "antd";

const BloodPressure = ({ rpmData }) => {
  const systolicData = rpmData?.find(
    (item) => item.rpmTypeId === 1 && item.bloodPressureTypeId === 1
  );
  const diastolicData = rpmData?.find(
    (item) => item.rpmTypeId === 1 && item.bloodPressureTypeId === 2
  );

  const formatRange = (min, max) => (min && max ? `${min}/${max}` : "N/A");

  if (!systolicData || !diastolicData) {
    return <Flex>No blood pressure data available</Flex>;
  }

  return (
    <Flex
      vertical
      style={{
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        width: "70%",
        flex: "1 ",
      }}
    >
      <Flex vertical>
        <Typography.Title level={5} style={{ marginBottom: "-3px" }}>
          BLOODPRESSURE
        </Typography.Title>
        <Typography.Text>
          Systolic Normal{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(systolicData.min, systolicData.max)}
          </span>{" "}
        </Typography.Text>
        <Typography.Text>
          Systolic Warning{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(systolicData.critMin, systolicData.min)} &{" "}
            {formatRange(systolicData.max, systolicData.critMax)}
          </span>{" "}
        </Typography.Text>
        <Typography.Text>
          Systolic Emergency{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(0, systolicData.critMin)} &{" "}
            {formatRange(systolicData.critMax, 999)}
          </span>
        </Typography.Text>
      </Flex>
      <Flex vertical style={{ margin: "13px 0px 13px 0px" }}>
        <Typography.Text>
          Diastolic Normal{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(diastolicData.min, diastolicData.max)}
          </span>
        </Typography.Text>
        <Typography.Text>
          Diastolic Warning{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(diastolicData.critMin, diastolicData.min)} &{" "}
            {formatRange(diastolicData.max, diastolicData.critMax)}
          </span>{" "}
        </Typography.Text>
        <Typography.Text>
          Diastolic Emergency{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(0, diastolicData.critMin)} &{" "}
            {formatRange(diastolicData.critMax, 999)}
          </span>
        </Typography.Text>
      </Flex>
      <Flex vertical>
        <Typography.Title level={5} style={{ marginBottom: "-3px" }}>
          INTERVENTIONS
        </Typography.Title>
        <Typography.Text style={{ width: "500px", textAlign: "start" }}>
          {systolicData.inteventionNote || "No intervention note available"}
        </Typography.Text>
      </Flex>
    </Flex>
  );
};

export default BloodPressure;
