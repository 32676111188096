import Logo from '@/components/ui/logo'
import useLogin from '@/hooks/auth/useLogin'
import { FORGOT_PASSWORD_PATH, SIGN_UP_PATH } from '@/routes/routeDefinitions'
import { LockOutlined, MailOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { Link } from 'react-router-dom'
import {
  Container,
  Footer,
  ForgotPasswordLink,
  FormItem,
  Header,
  SignUpText,
  StyledSection,
  StyledText,
  StyledTitle,
} from './Style'
import ErrorAlert from '@/components/ui/errorAlert'

const Login = () => {
  const { handleLogin, isLoading } = useLogin()

  const onFinish = (values) => {
    const payload = {
      username: values.username,
      password: values.password,
    }
    handleLogin(payload)
  }

  const validationRules = {
    username: [
      {
        type: 'string',
        required: true,
        message: 'Please enter a valid email address',
      },
    ],
    password: [
      {
        required: true,
        message: 'Please enter your password',
      },
    ],
  }

  return (
    <StyledSection>
      <Container>
        <Header>
          <Logo text={true} />
          <StyledTitle level={2}>Welcome Back</StyledTitle>
          <StyledText>
            Please enter your details to access your account.
          </StyledText>
        </Header>
        <ErrorAlert />
        <Form
          name="loginForm"
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
        >
          <FormItem name="username" rules={validationRules.username}>
            <Input prefix={<MailOutlined />} placeholder="Email" size="large" />
          </FormItem>
          <FormItem name="password" rules={validationRules.password}>
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Password"
              size="large"
            />
          </FormItem>
          <ForgotPasswordLink to={FORGOT_PASSWORD_PATH}>
            Forgot password?
          </ForgotPasswordLink>
          <FormItem>
            <Button
              block
              type="primary"
              htmlType="submit"
              size="large"
              loading={isLoading}
            >
              Log in
            </Button>
          </FormItem>
        </Form>
        <Footer>
          <SignUpText>
            Don&apos;t have an account?{' '}
            <Link to={SIGN_UP_PATH}>Sign up now</Link>
          </SignUpText>
        </Footer>
      </Container>
    </StyledSection>
  )
}

export default Login
