import { Col, Form, Input, Modal, Row, Select } from "antd";
import { createDeviceInputs, deviceTypeOptions } from "./constant";
import { useEffect, useState } from "react";
import {
  useCreateDeviceMutation,
  useUpdateDeviceMutation
} from "@/store/features/devices/deviceApiSlice";
const DeviceModal = (props) => {
  const { open, setOpen, initialValues } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [
    createDevice
    // { isLoading: isCreateDeviceLoading, isSuccess: isCreateDeviceSuccess }
  ] = useCreateDeviceMutation();
  const [
    updateDevice
    // { isLoading: isUpdateDeviceLoading, isSuccess: isUpdateDeviceSuccess }
  ] = useUpdateDeviceMutation();
  const [form] = Form.useForm();

  const isCreateModal = Object.keys(initialValues).length === 0;

  const handleOk = async () => {
    const values = form.getFieldsValue();
    setConfirmLoading(true);

    try {
      if (isCreateModal) {
        await createDevice(values);
      } else {
        await updateDevice({ ...values, deviceId: initialValues.deviceId });
      }
    } catch (error) {
      console.log(error);
    } finally {
      form.resetFields();
      setConfirmLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
    return () => form.resetFields();
  }, [initialValues, form]);

  return (
    <Modal
      title="Create New Device"
      open={open}
      onOk={handleOk}
      okType="submit"
      okText={isCreateModal ? "Create Device" : "Update Device"}
      confirmLoading={confirmLoading}
      onCancel={() => {
        form.resetFields();
        setOpen(false);
      }}
      okButtonProps={{
        type: "primary"
      }}
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Row gutter={[12, 12]}>
          {createDeviceInputs.map((item) => (
            <Col key={item.formItemProps.name} md={12}>
              <Form.Item {...item.formItemProps}>
                <Input {...item.inputProps} />
              </Form.Item>
            </Col>
          ))}

          <Col md={12}>
            <Form.Item label="Device Type" name="deviceTypeId">
              <Select placeholder="Select Device" options={deviceTypeOptions} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default DeviceModal;
