import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL } from "@/constants/apiDefinition";
import authMiddleware from "@/middlewares/authMiddleware";
import messageMiddleWare from "@/middlewares/messageMiddleware";

const customBaseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: async (headers) => {
    const token = localStorage.getItem("token");
    headers.set("Accept", "application/json");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithMessage = messageMiddleWare(customBaseQuery);
const baseQueryWithMessageAndAuth = authMiddleware(baseQueryWithMessage);

export const apiSlice = createApi({
  reducerPath: "api",
  tagTypes: [
    "Devices",
    "Glucose",
    "SpO2",
    "Weight",
    "Pulse",
    "DeviceOrders",
    "GlobalLocations",
    "GlobalSettings",
    "Temperatures",
  ],
  baseQuery: baseQueryWithMessageAndAuth,
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: 0,
  endpoints: () => ({}),
});
