import { useLocation, useNavigate } from 'react-router-dom'
import { DASHBOARD_PATH } from '@/routes/routeDefinitions'

export const useRedirectToStateUrl = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const redirect = () => {
    const redirectUrl = location.state?.redirectUrl || DASHBOARD_PATH
    navigate(redirectUrl)
  }
  return { redirect }
}
