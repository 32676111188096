import Icon from '@ant-design/icons'

const CustomIcon = () => (
  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="currentColor">
    <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H6l-2 2V4h16v12z" />
  </svg>
)

const MessageIcon = (props) => <Icon component={CustomIcon} {...props} />
export default MessageIcon
