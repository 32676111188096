import styled from 'styled-components'
import { Layout } from 'antd'
const { Content } = Layout
export const StyledLayout = styled(Layout)`
  transition: padding-left 0.2s;
  padding-left: ${(props) => (props.collapsed ? '80px' : '200px')};
`

export const StyledContent = styled(Content)`
  margin: 0 2rem;
  min-height: calc(100vh - 128px);
`
