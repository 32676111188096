import ErrorAlert from '@/components/ui/errorAlert'
import Logo from '@/components/ui/logo'
import useRegistration from '@/hooks/auth/useRegistration'
import { LOGIN_PATH } from '@/routes/routeDefinitions'
import {
  HomeOutlined,
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Button, Col, Form, Input, Row } from 'antd'
import { Link } from 'react-router-dom'
import {
  Container,
  Footer,
  FormItem,
  Header,
  SignInText,
  StyledSection,
  StyledText,
  StyledTitle,
} from './Style'

const Signup = () => {
  const { handleRegister, isLoading } = useRegistration()
  const [form] = Form.useForm()

  const onFinish = async (values) => {
    const payload = { ...values, role: 'User' }
    handleRegister(payload)
  }

  return (
    <StyledSection>
      <Container>
        <Header>
          <Logo text={true} />
          <StyledTitle level={2}>Create an Account</StyledTitle>
          <StyledText>Join us! Create an account to get started.</StyledText>
        </Header>
        <ErrorAlert />
        <Form
          form={form}
          name="signupForm"
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
        >
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <FormItem
                name="userName"
                rules={[
                  { required: true, message: 'Please input your username!' },
                ]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder="Username"
                  size="large"
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                name="name"
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder="Full Name"
                  size="large"
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <FormItem
                name="email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  { type: 'email', message: 'Please enter a valid email!' },
                ]}
              >
                <Input
                  prefix={<MailOutlined />}
                  placeholder="Email"
                  size="large"
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: 'Please input your phone number!',
                  },
                ]}
              >
                <Input
                  prefix={<PhoneOutlined rotate={90} />}
                  placeholder="Phone Number"
                  size="large"
                />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <FormItem
                name="password"
                rules={[
                  { required: true, message: 'Please input your password!' },
                  {
                    min: 8,
                    message: 'Password must be at least 8 characters long!',
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Password"
                  size="large"
                />
              </FormItem>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                name="confirmPassword"
                dependencies={['password']}
                rules={[
                  { required: true, message: 'Please confirm your password!' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error('The two passwords do not match!')
                      )
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Confirm Password"
                  size="large"
                />
              </FormItem>
            </Col>
          </Row>
          <FormItem
            name="address"
            rules={[{ required: true, message: 'Please input your address!' }]}
          >
            <Input
              prefix={<HomeOutlined />}
              placeholder="Address"
              size="large"
            />
          </FormItem>
          <FormItem>
            <Button
              block
              type="primary"
              htmlType="submit"
              size="large"
              loading={isLoading}
            >
              Sign up
            </Button>
          </FormItem>
        </Form>
        <Footer>
          <SignInText>
            Already have an account? <Link to={LOGIN_PATH}>Sign in</Link>
          </SignInText>
        </Footer>
      </Container>
    </StyledSection>
  )
}

export default Signup
