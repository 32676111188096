import CCMCarePlan from "@/Pages/Patients/patientDetails/carePlan/ccm";
import RPMCarePlan from "@/Pages/Patients/patientDetails/carePlan/rpm";
import { useGetHealthConditionsByPatientIdQuery } from "@/store/features/patient/patientApiSlice";
import { setCurrentPatientHealthId } from "@/store/features/patientDetails/patientDetailsSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "./header";
import { StyledMainContainer } from "./styles";
import { Spin } from "antd";
const PatientCarePlan = () => {
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { data: healthConditionsData, isLoading: isHealthConditionsLoading } =
    useGetHealthConditionsByPatientIdQuery(patientId);
  useEffect(() => {
    dispatch(
      setCurrentPatientHealthId(healthConditionsData?.[0]?.healthConditionId)
    );
  }, [healthConditionsData, isHealthConditionsLoading, dispatch]);

  return (
    <StyledMainContainer>
      <Header />
      <Spin spinning={isHealthConditionsLoading}>
        <RPMCarePlan />
        <CCMCarePlan />
      </Spin>
    </StyledMainContainer>
  );
};

export default PatientCarePlan;
