import ErrorAlert from "@/components/ui/errorAlert";
import useCreateProvidedDevices from "@/hooks/patient/useCreateProvidedDevices";
import useGetAllDevices from "@/hooks/patient/useGetAllDevices";
import RenderDeviceOptions from "@/Pages/AddNewPatientCollapse/ProvidedDevice/RenderDeviceOptions";
import { Button, Col, Divider, Form, Row, Skeleton } from "antd";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function ProvidedDevices() {
  const { handleCreateProvidedDevices, isLoading } = useCreateProvidedDevices();
  const { formattedData: data, isLoading: isDeviceListLoading } =
    useGetAllDevices();
  const { healthConditionId } = useSelector((state) => state.addPatient);
  const { patientId } = useParams();
  const [form] = Form.useForm();
  const selectedDevices = Form.useWatch("devices", form);

  const onFinish = (values) => {
    const bluetoothDevices = values?.devices?.Bluetooth || [];
    const cellularDevices = values?.devices?.Cellular || [];
    const manualDevices = values?.devices?.Manual || [];
    const tenoviDevices = values?.devices?.Tenovi || [];

    let device_MappingData = [];
    bluetoothDevices.forEach((device, index) => {
      if (device && device.isProvided) {
        device_MappingData.push({
          deviceTypeId: 1,
          patientId: patientId,
          deviceId: index,
          deviceUniqueId: device.deviceUniqueId,
        });
      }
    });
    cellularDevices.forEach((device, index) => {
      if (device && device.isProvided) {
        device_MappingData.push({
          deviceTypeId: 2,
          patientId: patientId,
          deviceId: index,
          deviceUniqueId: device.deviceUniqueId,
        });
      }
    });
    manualDevices.forEach((device, index) => {
      if (device && device.isProvided) {
        device_MappingData.push({
          deviceTypeId: 3,
          patientId: patientId,
          deviceId: index,
          deviceUniqueId: device.deviceUniqueId,
        });
      }
    });
    tenoviDevices.forEach((device, index) => {
      if (device && device.isProvided) {
        device_MappingData.push({
          deviceTypeId: 4,
          patientId: patientId,
          deviceId: index,
          deviceUniqueId: device.deviceUniqueId,
        });
      }
    });
    const payload = {
      device_MappingData,
      healthConditionId: healthConditionId,
    };
    handleCreateProvidedDevices(payload);
  };

  return (
    <Form
      form={form}
      name="providedDevices"
      onFinish={onFinish}
      layout="vertical"
    >
      <ErrorAlert />

      <Row gutter={[16, 16]}>
        <Col xs={24} md={6}>
          {isDeviceListLoading ? (
            <Skeleton paragraph={{ rows: 3 }} />
          ) : (
            <RenderDeviceOptions
              title={data?.bluetooth?.title}
              devices={data?.bluetooth?.devices}
              selectedDevices={selectedDevices}
            />
          )}
        </Col>
        <Col xs={24} md={6}>
          {isDeviceListLoading ? (
            <Skeleton paragraph={{ rows: 3 }} />
          ) : (
            <RenderDeviceOptions
              title={data?.cellular?.title}
              devices={data?.cellular?.devices}
              selectedDevices={selectedDevices}
            />
          )}
        </Col>
        <Col xs={24} md={6}>
          {isDeviceListLoading ? (
            <Skeleton paragraph={{ rows: 3 }} />
          ) : (
            <RenderDeviceOptions
              title={data?.tenovi?.title}
              devices={data?.tenovi?.devices}
              selectedDevices={selectedDevices}
            />
          )}
        </Col>
        <Col xs={24} md={6}>
          {isDeviceListLoading ? (
            <Skeleton paragraph={{ rows: 3 }} />
          ) : (
            <RenderDeviceOptions
              title={data?.manual?.title}
              devices={data?.manual?.devices}
              selectedDevices={selectedDevices}
            />
          )}
        </Col>
      </Row>

      <Divider />

      <Row gutter={16} justify="space-between">
        <Col>
          <Button>Previous</Button>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Next
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
