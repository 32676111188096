import { Navigate } from 'react-router-dom'
import { DASHBOARD_PATH } from '@/routes/routeDefinitions'

const PublicRoute = ({ children }) => {
  const token = !!localStorage.getItem('token')
  if (token) {
    return <Navigate to={DASHBOARD_PATH} />
  } else {
    return children
  }
}

export default PublicRoute
