import { Flex, Typography } from "antd";

const Spo2 = ({ rpmData }) => {
  const spo2Data = rpmData?.find((item) => item.rpmTypeId === 4);

  const formatRange = (min, max) =>
    min !== undefined && max !== undefined ? `${min}-${max}` : "N/A";

  if (!spo2Data) {
    return <Flex>No SpO2 data available</Flex>;
  }

  return (
    <Flex
      vertical
      style={{
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        width: "70%",
        flex: "1",
      }}
    >
      <Flex vertical style={{ margin: "13px 0px 13px 0px" }}>
        <Typography.Title level={5} style={{ marginBottom: "-3px" }}>
          SPO2
        </Typography.Title>
        <Typography.Text>
          Normal{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(spo2Data.min, spo2Data.max)}%
          </span>
        </Typography.Text>
        <Typography.Text>
          Warning{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(spo2Data.critMin, spo2Data.min)}% &{" "}
            {formatRange(spo2Data.max, spo2Data.critMax)}%
          </span>
        </Typography.Text>
        <Typography.Text>
          Emergency{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(0, spo2Data.critMin)}% &{" "}
            {formatRange(spo2Data.critMax, 100)}%
          </span>
        </Typography.Text>
      </Flex>
      <Flex vertical>
        <Typography.Title level={5} style={{ marginBottom: "-3px" }}>
          INTERVENTIONS
        </Typography.Title>
        <Typography.Text style={{ width: "500px", textAlign: "start" }}>
          {spo2Data.inteventionNote || "No intervention note available"}
        </Typography.Text>
      </Flex>
    </Flex>
  );
};

export default Spo2;
