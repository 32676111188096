import { GET_ALL_TIME_LOGS_URL } from "@/constants/apiDefinition";
import { apiSlice } from "@/store/features/api/apiSlice";

export const timelogApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTimeLogByPatientId: builder.query({
      query: ({ pageNumber = 1, pageSize = 50, patientId }) => ({
        url: GET_ALL_TIME_LOGS_URL,
        method: "GET",
        params: { pageNumber, pageSize, patientId },
      }),
      transformResponse: (response, meta) => {
        const paginationHeader = meta.response.headers.get("Pagination");
        let pagination = null;

        try {
          pagination = paginationHeader ? JSON.parse(paginationHeader) : null;
        } catch (error) {
          console.error("Error parsing pagination header:", error);
        }

        return {
          data: response,
          totalItems: pagination?.totalItems,
        };
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: "Timelog", id })),
              { type: "Timelog", id: "LIST" },
            ]
          : [{ type: "Timelog", id: "LIST" }],
    }),
  }),
});

export const { useGetAllTimeLogByPatientIdQuery } = timelogApi;
