const getCcmPayload = (
  formData,
  patientCareTypeId = 1,
  healthConditionId = 1,
  companyId = 1
) => {
  const goalMap = {
    notApplicable: "isGoalNotApplicable",
    ideal: "isGoalMaintainedIdealHeightandWeight",
    alchol: "isGoalBeAlcholFree",
    complete: "isGoalCompleteTherapyDetox",
    support: "isGoalAttendSupporteSsions",
    anxiety: "isGoalReduceAnxietyDepression",
    others: "isGoalIsOther",
  };

  const apiData = {
    isComplex: formData.isComplex,
    patientCareTypeId: patientCareTypeId,
    meetingDate: formData.meetingDate,
    patientHistory: formData.patientHistory,
    riskClassificaitonTypeId: formData.riskClassificaitonTypeId,
    note: formData.careplanGoalsDescription || "NA",
    healthConditionId: healthConditionId,
    companyId: companyId,
  };

  // Initialize all goal fields to false
  Object.values(goalMap).forEach((key) => {
    apiData[key] = false;
  });

  // Set selected goals to true
  formData.careplanGoals.forEach((goal) => {
    if (goalMap[goal]) {
      apiData[goalMap[goal]] = true;
    }
  });
  return apiData;
};

export default getCcmPayload;
