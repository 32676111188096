import styled from 'styled-components'
import { Modal } from 'antd'

export const StyledMainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 15px;
`

export const StyledCheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 24px;
  }
`

export const Title = styled.h2`
  font-size: 18px;
  margin-bottom: 16px;
`

export const RiskTag = styled.span`
  border-radius: 4px;
  font-weight: bold;
  color: white;

  @media (min-width: 500px) {
    padding: 4px 8px;
  }
`

export const HighRiskTag = styled(RiskTag)`
  background-color: #ff7875;
`

export const ModerateRiskTag = styled(RiskTag)`
  background-color: #ffc069;
`

export const LowRiskTag = styled(RiskTag)`
  background-color: #95de64;
`
