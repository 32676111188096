// services/timeTrackerApi.js

import { BASE_URL } from "@/constants/apiDefinition";
import { apiSlice } from "@/store/features/api/apiSlice";

export const timeTrackerApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    startTimeTracking: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/api/TimeTracker`,
        method: "POST",
        body: body,
      }),
    }),
    stopTimeTracking: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/api/TimeTracker`,
        method: "PUT",
        body: body,
      }),
    }),
    sendHeartBeat: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/api/TimeTracker/heartbeat`,
        method: "PUT",
        params: { timeTrackerId: body.timeTrackerId },
      }),
    }),
    manualTimeTracking: builder.mutation({
      query: (body) => ({
        url: `${BASE_URL}/api/TimeTracker/manual-time-track`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: [{ type: "Timelog", id: "LIST" }],
    }),
  }),
});

export const {
  useStartTimeTrackingMutation,
  useStopTimeTrackingMutation,
  useSendHeartBeatMutation,
  useManualTimeTrackingMutation,
} = timeTrackerApi;
