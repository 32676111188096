const ETHNICITY_LIST = [
  { value: 1, label: "Asian" },
  { value: 2, label: "Black or African American" },
  { value: 3, label: "Hispanic or Latino" },
  { value: 4, label: "White" },
  { value: 5, label: "Native American or Alaska Native" },
  { value: 6, label: "Native Hawaiian or Other Pacific Islander" },
  { value: 7, label: "Middle Eastern or North African" },
  { value: 8, label: "Mixed or Multiracial" },
  { value: 9, label: "Other" },
];

export default ETHNICITY_LIST;
