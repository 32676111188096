import NoteForm from "@/Pages/Patients/patientDetails/details/forms/NotesForm";
import TaskForm from "@/Pages/Patients/patientDetails/details/forms/TasksForm";
import NotesTable from "@/Pages/Patients/patientDetails/details/tables/NotesTable";
import TasksTable from "@/Pages/Patients/patientDetails/details/tables/TasksTable";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal, Tabs } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { StyledDivContainer, StyledFormContainer } from "./styles";
const { TabPane } = Tabs;

const PatientNotesAndTasks = () => {
  const [activeTab, setActiveTab] = useState("notes");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [prevData, setPrevData] = useState(null);
  const { patientId } = useParams();

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setPrevData(null);
  };

  const addButton = (
    <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
      Add {activeTab === "notes" ? "Note" : "Task"}
    </Button>
  );

  return (
    <StyledFormContainer>
      <StyledDivContainer>
        <Tabs
          activeKey={activeTab}
          onChange={handleTabChange}
          tabBarExtraContent={{ right: addButton }}
        >
          <TabPane tab="Notes" key="notes">
            <NotesTable
              patientId={patientId}
              setIsModalVisible={setIsModalVisible}
              setPrevData={setPrevData}
            />
          </TabPane>
          <TabPane tab="Tasks" key="tasks">
            <TasksTable
              patientId={patientId}
              setIsModalVisible={setIsModalVisible}
              setPrevData={setPrevData}
            />
          </TabPane>
        </Tabs>
        <Modal
          title={`Add ${activeTab === "notes" ? "Note" : "Task"}`}
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          {activeTab === "notes" ? (
            <NoteForm
              patientId={patientId}
              onSuccess={handleCancel}
              prevData={prevData}
            />
          ) : (
            <TaskForm
              patientId={patientId}
              onSuccess={handleCancel}
              prevData={prevData}
            />
          )}
        </Modal>
      </StyledDivContainer>{" "}
    </StyledFormContainer>
  );
};

export default PatientNotesAndTasks;
