import LoadingComponent from "@/components/LoadingComponent";
import EmptyState from "@/components/ui/empty/Empty";
import { Fragment } from "react";
import PatientCard from "../PatientCard";

const RPMTab = (props) => {
  const { patientList, isLoading, isSuccess } = props;
  let content = null;
  if (isLoading) {
    content = <LoadingComponent text="  Patient's List Loading ..." />;
  }
  if (isSuccess && patientList?.length === 0) {
    content = <EmptyState link={"/patients/add"} />;
  }

  if (isSuccess && patientList?.length !== 0) {
    content = (
      <Fragment>
        {patientList?.map((patient) => (
          <PatientCard key={patient.id} patient={patient} loading={isLoading} />
        ))}
      </Fragment>
    );
  }

  return content;
};

export default RPMTab;

{
  /* <div style={{ overflow: "auto" }}>
  <Flex justify="center">
        <Typography.Title level={4}>
          <TypewriterEffect> Patients DashBoard</TypewriterEffect>
        </Typography.Title>
      </Flex>
  {content}
</div>; */
}
