import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from 'antd'
import DiscriptionInput from './DiscriptionInput'
import { StyledFormContainer } from './Style'
const { Option } = Select

export default function CarePlanApproval() {
  const onFinish = (values) => {
    console.log('Received values of form: ', values)
  }

  return (
    <StyledFormContainer>
      <Form
        name="carePlanApproval"
        onFinish={onFinish}
        layout="vertical"
        requiredMark="optional"
      >
        {/* Care Plan Title*/}
        <Typography.Title level={3}>Care Plan Approval</Typography.Title>

        {/* Approved By*/}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="approvedBy"
              label="Approved By"
              rules={[
                {
                  required: true,
                  message: 'Please select the Approval!',
                },
              ]}
            >
              <Select placeholder="Select Approval">
                <Option value="selfApproval">Self Approval</Option>
                <Option value="providerApproval">
                  Approved on Behalf of Provider
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Approving provider,Approval Date*/}
        <Row gutter={16}>
          <Col span={13}>
            <Form.Item
              name="approvingProvider"
              label="Approving Provider"
              rules={[
                {
                  type: 'text',
                  required: true,
                  message: 'Please Enter the Approving Provider!',
                },
              ]}
            >
              <Input placeholder="Approving Provider" />
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item
              name="approvalDate"
              label="Approval Date"
              rules={[
                {
                  required: true,
                  message: 'Please select the date!',
                },
              ]}
            >
              <DatePicker placeholder="Select Date" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        {/* DicriptionInupt */}
        <DiscriptionInput />

        <Divider />

        {/* Submit Button */}
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item>
              <Button type="" htmlType="submit" style={{ borderRadius: '8px' }}>
                Clear All
              </Button>
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Filter & Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledFormContainer>
  )
}
