import Icon from '@ant-design/icons'

const CustomIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="transparent">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="2"
      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
    ></path>
    <circle cx="18" cy="6" r="4" fill="currentColor"></circle>
  </svg>
)

const PendingTaskIcon = (props) => <Icon component={CustomIcon} {...props} />
export default PendingTaskIcon
