import PatientHistory from "@/components/forms/patientHistory";
import PatientRiskClassification from "@/components/forms/patientRiskClassification";
import ErrorAlert from "@/components/ui/errorAlert";
import { Button, Col, DatePicker, Divider, Form, Row, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import { StyledFormContainer } from "./Style";
import CareGoals from "@/components/forms/careGoals";
import { useSelector } from "react-redux";
import getCcmPayload from "@/utils/getCCMPayload";
import useCreateCcm from "@/hooks/patient/useCreateCcm";

export default function CcmSetup() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { handleCreateCcmSetup, isLoading } = useCreateCcm();
  const { healthConditionId } = useSelector((state) => state.addPatient);
  const isComplexCcm = Form.useWatch("isComplex", form);

  const onFinish = (values) => {
    const payload = getCcmPayload(values, 2, healthConditionId);
    handleCreateCcmSetup(payload);
  };

  return (
    <StyledFormContainer>
      <ErrorAlert />
      <Form
        name="ccmSetup"
        form={form}
        onFinish={onFinish}
        layout="horizontal"
        requiredMark={true}
      >
        <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
          <Col span={24}>
            <Form.Item
              name="isComplex"
              label="Complex CCM"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="meetingDate" label="Care Plan Meeting">
              <DatePicker
                showTime
                format="DD-MM-YYYY hh:mm a"
                placeholder="Select a date"
                needConfirm={false}
              />
            </Form.Item>
          </Col>
          {isComplexCcm && (
            <Col span={24}>
              <PatientHistory form={form} initialValue="" />
            </Col>
          )}
          {isComplexCcm && (
            <Col span={24}>
              <PatientRiskClassification />
            </Col>
          )}
          {isComplexCcm && (
            <Col span={24}>
              <CareGoals />
            </Col>
          )}
        </Row>

        <Divider />

        <Divider />

        <Row gutter={[16, 16]} justify="end">
          <Col xs={6} sm={3}>
            <Button
              style={{ borderRadius: "7px", padding: "2px 17px" }}
              onClick={() => navigate(-1)}
            >
              Previous
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              style={{ borderRadius: "7px", padding: "2px 24px" }}
              htmlType="submit"
              loading={isLoading}
            >
              Next
            </Button>
          </Col>
        </Row>
      </Form>
    </StyledFormContainer>
  );
}
