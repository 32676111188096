import { createContext, useState } from "react";

export const CcmContext = createContext();

const WithCcmData = (WrappedComponent) => {
  const HOC = (props) => {
    const [ccmState, setCcmState] = useState({
      date: ""
    });

    return (
      <CcmContext.Provider value={{ ...ccmState, setCcmState }}>
        <WrappedComponent {...props} />
      </CcmContext.Provider>
    );
  };

  HOC.displayName = `WithCcmData(${getDisplayName(WrappedComponent)})`;
  return HOC;
};

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default WithCcmData;
