import styled from "styled-components";

export const StyledMainContainer = styled.div`
  width: 100%;
  padding: 20px;
`;

export const StyledDivContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
`;
