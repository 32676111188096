import AddPatientForm from "@/Pages/Patients/addPatientForm/Form";
import { Card, Typography } from "antd";

const { Title } = Typography;

const AddPatient = () => {
  return (
    <Card style={{ margin: "16px auto", maxWidth: "800px" }}>
      <Title level={4} style={{ marginBottom: "16px", color: "#1890ff" }}>
        Add Patient Information
      </Title>
      <AddPatientForm />
    </Card>
  );
};

export default AddPatient;
