import styled from "styled-components";
import { Typography, Input } from "antd";

const { Text } = Typography;

export const StyledFormContainer = styled.div`
  margin: 1rem auto;
  padding: 24px;
  max-width: 800px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

export const StyledFormTitle = styled(Text)`
  font-size: 1.5rem;
  font-weight: 500;
`;
export const OrderInput = styled(Input)`
  width: 33%;
`;
