import { apiSlice } from "@/store/features/api/apiSlice";

export const CHAT_BASE_URL = "https://www.medikick.xyz";
export const aiChatApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendChatMessage: builder.mutation({
      query: (body) => ({
        url: `${CHAT_BASE_URL}/chat`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useSendChatMessageMutation } = aiChatApi;
