import { useRedirectToStateUrl } from "@/hooks/auth/useRedirectToStateUrl";
import { apiSlice } from "@/store/features/api/apiSlice";
import { useLoginMutation } from "@/store/features/auth/authApiSlice";
import { setAuth } from "@/store/features/auth/authSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const useLogin = () => {
  const dispatch = useDispatch();
  const { redirect } = useRedirectToStateUrl();

  const [login, { isLoading, isSuccess, isError, data, error }] =
    useLoginMutation();

  const handleLogin = (credentials) => {
    login(credentials);
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(setAuth(data));
      localStorage.setItem("token", data?.token);
      apiSlice.util.resetApiState();

      redirect();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isSuccess, data, dispatch]);

  return { handleLogin, isLoading, isSuccess, isError, data, error };
};

export default useLogin;
