// import useGetAllPatients from "@/hooks/dashboard/useGetAllPatients";
import DevicesList from "./DevicesList";
import { Skeleton } from "antd";
import EmptyState from "@/components/ui/empty/Empty";
import useGetAllDevices from "@/hooks/patient/useGetAllDevices";

const Devices = () => {
  const { data, isSuccess, isLoading, isError } = useGetAllDevices();
  let content = null;
  if (isLoading) {
    content = (
      <Skeleton
        avatar
        paragraph={{
          rows: 2
        }}
      />
    );
  }

  if (isSuccess) {
    content = <DevicesList data={data} />;
  }
  if (isError) {
    content = <EmptyState />;
  }

  return content;
};
export default Devices;
