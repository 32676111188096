import styled from 'styled-components'
import { Alert as AntAlert } from 'antd'

export const AlertWrapper = styled.div`
  scroll-margin-top: 20px; // Adds some space at the top when scrolling
  scroll-behavior: smooth;
`

export const StyledAlert = styled(AntAlert)`
  margin-bottom: 16px;

  .ant-alert-message {
    font-weight: 500;
  }

  .ant-alert-description {
    font-size: 14px;
  }

  &.ant-alert-error {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
  }

  &.ant-alert-with-description {
    padding: 12px 16px;
  }
`
