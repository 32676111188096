import { Button, Flex } from "antd";
import Title from "antd/es/typography/Title";
import AddPatientForm from "../Patients/addPatientForm/Form";
import TypewriterEffect from "@/components/shared/TypeWriterEffect";
import { Link } from "react-router-dom";
import { PlusCircleFilled } from "@ant-design/icons";
import PatientCreationOptions from "./PatientCreationOptions";

const AddPatientWithInfo = () => {
  return (
    <Flex vertical>
      <Title style={{ margin: "1.5rem 0px" }} level={5} italic>
        <TypewriterEffect>
          Thhere is No Patient with The MRN. Please create a patient or try
          Again!
        </TypewriterEffect>
      </Title>

      <PatientCreationOptions />
    </Flex>
  );
};

export default AddPatientWithInfo;
