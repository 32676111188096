import UploadImage from "@/components/shared/uploadImage";
import ErrorAlert from "@/components/ui/errorAlert";
import ETHNICITY_LIST from "@/data/ethnicityList";
import NATIONALITIES from "@/data/nationalities.json";
import useCreatePatient from "@/hooks/patient/useCreatePatient";
import useCreatePatientValidation from "@/hooks/validation/useCreatePatientValidation";
import { DASHBOARD_PATH } from "@/routes/routeDefinitions";
import {
  useGetPatientByIdQuery,
  useUpdatePatientMutation,
} from "@/store/features/patient/patientApiSlice";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
const { TextArea } = Input;

const AddPatientForm = () => {
  const [form] = Form.useForm();
  const isMobileAvailable = Form.useWatch("mobileAvailable", form);
  const { handleCreatePatient, isLoading, data } = useCreatePatient();
  const { validateMRN } = useCreatePatientValidation();
  const navigate = useNavigate();
  const { patientId } = useParams();
  const {
    data: patientData,
    isLoading: patientDataLoading,
    isSuccess,
  } = useGetPatientByIdQuery(patientId, { skip: !patientId });

  const [handleUpdate, { loading: updateLoading, isSuccess: updateSuccess }] =
    useUpdatePatientMutation();
  const onFinish = () => {
    const payload = form.getFieldsValue(true);
    if (patientId) {
      handleUpdate(payload);
    } else {
      handleCreatePatient(payload);
    }
  };

  useEffect(() => {
    if (updateSuccess) {
      navigate(`/patients/add/${patientId}/health-conditions`);
      return;
    }
    if (data?.isSuccess) {
      navigate(`/patients/add/${data?.id || 1}/health-conditions`);
    }
  }, [data, updateSuccess, navigate, patientId]);

  useEffect(() => {
    if (isSuccess && patientData) {
      form.setFieldsValue({
        ...patientData,
        dob: dayjs(patientData.dob),
        mobileAvailable: !!patientData?.phone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData, isSuccess]);

  return (
    <Spin spinning={patientDataLoading}>
      <Form
        form={form}
        name="patient_form"
        onFinish={onFinish}
        layout="vertical"
        requiredMark="optional"
      >
        <ErrorAlert />
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Form.Item
              name="mrn"
              label="MRN"
              rules={[{ required: true, validator: validateMRN }]}
            >
              <Input placeholder="MRN" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true, message: "First Name is Required!" }]}
            >
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="middleName" label="Middle Name">
              <Input placeholder="Middle Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true, message: "Last Name is Required!" }]}
            >
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="dob"
              label="Date of Birth"
              rules={[
                { required: true, message: "Date of Birth is Required!" },
              ]}
            >
              <DatePicker style={{ width: "100%" }} placeholder="MM-DD-YYYY" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="genderId"
              label="Gender"
              rules={[{ required: true, message: "Gender is Required!" }]}
            >
              <Select
                placeholder="Select Gender"
                options={[
                  { label: "Male", value: 1 },
                  { label: "Female", value: 2 },
                  { label: "Non-Binary", value: 3 },
                  { label: "Other", value: 4 },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="ethnicityId" label="Ethnicity">
              <Select
                placeholder="Select Ethnicity"
                options={ETHNICITY_LIST}
                showSearch
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="maritalStatusId" label="Marital Status">
              <Select
                placeholder="Select Status"
                options={[
                  { value: 1, label: "Single" },
                  { value: 2, label: "Married" },
                  { value: 3, label: "Divorced" },
                  { value: 4, label: "Widowed" },
                  { value: 5, label: "Separated" },
                  { value: 6, label: "Other" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="nationality" label="Nationality">
              <Select
                placeholder="Select Nationality"
                options={NATIONALITIES}
                showSearch
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="languageId" label="Language">
              <Select
                placeholder="Select Language"
                options={[
                  { value: 1, label: "English" },
                  { value: 2, label: "Spanish" },
                  { value: 3, label: "French" },
                  { value: 4, label: "German" },
                  { value: 5, label: "Chinese" },
                  { value: 6, label: "Japanese" },
                  { value: 7, label: "Russian" },
                  { value: 8, label: "Arabic" },
                  { value: 9, label: "Hindi" },
                  { value: 10, label: "Bengali" },
                  { value: 11, label: "Portuguese" },
                  { value: 12, label: "Italian" },
                  { value: 13, label: "Dutch" },
                  { value: 14, label: "Korean" },
                  { value: 15, label: "Other" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="address"
          label="Address"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input placeholder="Address" />
        </Form.Item>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="city"
              label="City"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="City" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="state"
              label="State"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="State" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="zipcode"
              label="Zipcode"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="Zipcode" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="email"
              label="Email Address"
              rules={[{ type: "email", message: "Invalid email" }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="mobileAvailable" label="Mobile Available">
              <Radio.Group size="small">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        {isMobileAvailable && (
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="mobileNumber"
                label="Mobile Number"
                rules={[{ required: true, message: "Required" }]}
              >
                <Input placeholder="Mobile Number" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="landlineNumber" label="Landline Number">
                <Input placeholder="Landline" />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={16}>
          <Col span={16}>
            <Form.Item name="payerId" label="Payer">
              <Select
                placeholder="Select Payer"
                options={[
                  { value: 1, label: "Insurance" },
                  { value: 2, label: "Self Pay" },
                  { value: 3, label: "Medicare" },
                  { value: 4, label: "Medicaid" },
                  { value: 5, label: "Commercial" },
                  { value: 6, label: "Other" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={16} md={8}>
            <UploadImage
              form={form}
              name="ImageFile"
              imageUrl={patientData?.photoUrl}
            />
          </Col>
        </Row>
        <Form.Item name="additionalDetails" label="Additional Details">
          <TextArea rows={2} placeholder="Add details" />
        </Form.Item>

        <Form.Item>
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
          >
            <Link to={DASHBOARD_PATH}>
              <Button type="default">Cancel</Button>
            </Link>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading || updateLoading}
            >
              Next
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default AddPatientForm;
