/* eslint-disable react-refresh/only-export-components */
import AdditionalNotesForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/AdditionalNotesForm";
import AllergiesForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/AllergiesForm";
import BarriersForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/BarriersForm";
import GoalsForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/GoalsForm";
import MedicationsForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/MedicationsForm";
import MonitoringForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/MonitoringForm";
import PatientHistoryForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/PatientHistoryForm";
import PreventiveCareForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/PreventiveCareForm";
import PsychoNeuroTestingForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/PsychoNeuroTestingForm";
import RiskClassificationForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/RiskClassificationForm";
import SelfManagementForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/SelfManagementForm";
import SymptomsForm from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/forms/SymptomsForm";
import { setIsModalOpen } from "@/store/features/ccm/ccmFormSlice";
import { Button, Collapse, DatePicker, Flex } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ContainerModal from "./multiStepForm/ContainerModal";
import { StyledDivContainer, StyledMainTableContent } from "./styles";
import { useContext, useEffect } from "react";
import { setModule } from "@/store/features/timeTracking/timeTrackerSlice";
import WithCcmData, { CcmContext } from "./withCcmData";
const CCMCarePlan = () => {
  const dispatch = useDispatch();
  const { setCcmState } = useContext(CcmContext);

  const handleEditClick = () => {
    dispatch(setIsModalOpen(true));
  };
  const selectedCarePlan = useSelector(
    (state) => state.patientDetails.carePlan
  );

  const handleDateChange = (date) => {
    setCcmState((prev) => ({ ...prev, date: new Date(date).toISOString() }));
  };

  useEffect(() => {
    if (selectedCarePlan !== "ccm") return;
    dispatch(
      setModule({
        moduleName: "CCM",
        cptCode: 17,
      })
    );
  }, [selectedCarePlan, dispatch]);
  if (selectedCarePlan !== "ccm") return null;

  return (
    <>
      <StyledMainTableContent>
        <StyledDivContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "rgba(0,0,0,0.05)",
              padding: "1.2rem",
              borderRadius: "8px 8px 0px 0px",
            }}
          >
            <h2>Patient CCM Care Plan</h2>

            <Flex gap={"middle"}>
              <Button
                type="primary"
                style={{ padding: "3px 23px 3px 23px", borderRadius: "16px" }}
                onClick={handleEditClick}
              >
                Edit
              </Button>

              <DatePicker
                picker="month"
                format="YYYY-MM"
                onChange={handleDateChange}
              />
            </Flex>
          </div>

          {/* Collapse Content */}

          <div>
            <CollapseForm />
          </div>
        </StyledDivContainer>
      </StyledMainTableContent>
      <ContainerModal />
    </>
  );
};

export default WithCcmData(CCMCarePlan);

const CollapseForm = () => {
  const items = [
    {
      key: "1",
      label: <span style={{ fontWeight: "bold" }}>Patient History</span>,
      children: <PatientHistoryForm />,
    },
    {
      key: "2",
      label: <span style={{ fontWeight: "bold" }}>Goals</span>,
      children: <GoalsForm />,
    },
    {
      key: "3",
      label: <span style={{ fontWeight: "bold" }}>Barriers</span>,
      children: <BarriersForm />,
    },
    {
      key: "4",
      label: <span style={{ fontWeight: "bold" }}>Symptoms</span>,
      children: <SymptomsForm />,
    },
    {
      key: "5",
      label: <span style={{ fontWeight: "bold" }}>Allergies</span>,
      children: <AllergiesForm />,
    },
    {
      key: "6",
      label: <span style={{ fontWeight: "bold" }}>Medications</span>,
      children: <MedicationsForm />,
    },
    {
      key: "7",
      label: <span style={{ fontWeight: "bold" }}>Monitoring</span>,
      children: <MonitoringForm />,
    },
    {
      key: "8",
      label: <span style={{ fontWeight: "bold" }}>Preventive Care</span>,
      children: <PreventiveCareForm />,
    },
    {
      key: "9",
      label: (
        <span style={{ fontWeight: "bold" }}>
          Psychological and Neuro-Psychological Testing{" "}
        </span>
      ),
      children: <PsychoNeuroTestingForm />,
    },
    {
      key: "10",
      label: <span style={{ fontWeight: "bold" }}>Self Management</span>,
      children: <SelfManagementForm />,
    },
    {
      key: "11",
      label: <span style={{ fontWeight: "bold" }}>Additional Notes</span>,
      children: <AdditionalNotesForm />,
    },
    {
      key: "12",
      label: <span style={{ fontWeight: "bold" }}>Risk Classification</span>,
      children: <RiskClassificationForm />,
    },
  ];

  return (
    <Collapse
      accordion
      items={items}
      style={{
        padding: "4px 9px 4px 9px",
        backgroundColor: "white",
        fontSize: "17px",
      }}
    />
  );
};
