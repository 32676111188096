import { Table, Button, Flex } from "antd";
import {
  useDeletePatientTaskMutation,
  useGetPatientTasksQuery,
} from "@/store/features/patientTasks/patientTaskApiSlice";
import { useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
const TasksTable = ({ patientId, setIsModalVisible, setPrevData }) => {
  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });
  const { data, isLoading } = useGetPatientTasksQuery({
    ...pagination,
    patientId,
  });
  const [deleteTask, { isLoading: deleteLoading }] =
    useDeletePatientTaskMutation();
  const { data: tasks, pagination: paginationData } = data || {};
  const columns = [
    { title: "Title", dataIndex: "title", key: "title" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "Due Date", dataIndex: "dueDate", key: "dueDate" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Flex gap="10px">
          <Button type="link" onClick={() => handleEditTask(record)}>
            <EditOutlined />
          </Button>
          <Button
            type="link"
            onClick={() => handleDeleteTask(record)}
            loading={deleteLoading}
          >
            <DeleteOutlined />
          </Button>
        </Flex>
      ),
    },
  ];

  const handleEditTask = (record) => {
    setPrevData(record);
    setIsModalVisible(true);
  };
  const handleDeleteTask = (record) => {
    deleteTask(record?.patientTaskId);
  };
  return (
    <Table
      columns={columns}
      dataSource={tasks}
      rowKey={(record) => record.id}
      loading={isLoading}
      pagination={{
        pageSize: pagination.pageSize,
        current: pagination.pageNumber,
        total: paginationData?.totalItems || 0,
        onChange: (page) =>
          setPagination((prev) => ({
            ...prev,
            pageNumber: page,
          })),
      }}
    />
  );
};

export default TasksTable;
