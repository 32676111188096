import NoteAndTime from "@/components/shared/NoteAndTime";
import PatientInfo from "@/components/shared/PatientInfo";
import { Card, Col, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";

const StyledCard = styled(Card)`
  margin-bottom: 8px;
  border-radius: 12px;
  .ant-card-body {
    padding: 16px;
  }
`;

const PatientCard = ({ patient, loading }) => {
  const [patientData, setPatientData] = useState({});
  useEffect(() => {
    const patientName = patient?.middleName
      ? `${patient?.firstName} ${patient?.middleName} ${patient?.lastName}`
      : `${patient?.firstName} ${patient?.lastName}`;
    setPatientData({
      name: patientName,
      photoUrl: patient?.photoUrl,
      priority: patient?.priority,
      room: patient?.room || "N/A",
    });
  }, [patient]);

  return (
    <StyledCard>
      <Skeleton loading={loading} active avatar paragraph={{ rows: 1 }}>
        <Row gutter={[16, 16]} align="middle" justify="space-between">
          <Col xs={24} sm={12} md={12} lg={6} xl={5} xxl={4}>
            <PatientInfo
              name={patientData.name}
              room={patientData.room}
              photoUrl={patientData.photoUrl}
            />
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={3} xxl={2}>
            Something Here
          </Col>

          <Col xs={24} sm={24} md={12} lg={12} xl={4} xxl={7}>
            <NoteAndTime time="00:10:24" note="This is a note" />
          </Col>
        </Row>
      </Skeleton>
    </StyledCard>
  );
};

export default PatientCard;
