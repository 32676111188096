export const RPMType = [
  {
    label: "Blood Pressure",
    value: 1
  },
  {
    label: "Pulse",
    value: 2
  },
  {
    label: "Weight",
    value: 3
  },
  {
    label: "SpO2",
    value: 4
  },
  {
    label: "Glucose",
    value: 5
  },
  {
    label: "Temperature",
    value: 6
  }
];
