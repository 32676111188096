import ErrorAlert from "@/components/ui/errorAlert";
import {
  useAddPatientNoteMutation,
  useUpdatePatientNoteMutation,
} from "@/store/features/patientNotes/patientNoteApiSlice";
import { Button, Flex, Form, Input, message, Select } from "antd";
import { useEffect } from "react";
const { TextArea } = Input;
const { Option } = Select;

const NoteForm = ({ patientId, onSuccess = () => {}, prevData = null }) => {
  const [form] = Form.useForm();
  const [addNote, { isLoading }] = useAddPatientNoteMutation();
  const [updateNote, { isLoading: updateLoading }] =
    useUpdatePatientNoteMutation();
  const handleSubmit = async (values) => {
    let response;
    try {
      if (prevData) {
        response = await updateNote({ ...prevData, ...values });
      } else {
        response = await addNote({ ...values, patientId });
      }

      if (!response?.error) {
        form.resetFields();
        onSuccess();
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to add note");
    }
  };

  useEffect(() => {
    if (prevData?.notes) {
      form.setFieldsValue({ ...prevData });
    } else {
      form.setFieldsValue({
        notes: null,
        activityFor: null,
      });
    }
  }, [prevData, form]);
  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <ErrorAlert />
      <Form.Item
        name="notes"
        rules={[{ required: true, message: "Please enter notes" }]}
      >
        <TextArea rows={4} placeholder="Enter notes" />
      </Form.Item>
      <Form.Item
        name="activityFor"
        rules={[{ required: true, message: "Please select activity" }]}
      >
        <Select placeholder="Select activity">
          <Option value="RPM">RPM</Option>
          <Option value="CCM">CCM</Option>
          <Option value="Other">Other</Option>
        </Select>
      </Form.Item>

      <Flex justify="end" gap="10px">
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading || updateLoading}
        >
          {prevData ? "Update" : "Create"}
        </Button>
      </Flex>
    </Form>
  );
};

export default NoteForm;
