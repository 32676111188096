import { Button, Table, Typography } from "antd";
import { StyledDivContainer, StyledFormContainer } from "./styles";

const data = [
  {
    key: "1",
    question: "Little intrest or pleasure in doing things",
    answer: "Several days",
    score: "1",
  },
  {
    key: "2",
    question: "Feeling down deppressed or hopeless",
    answer: "More than the half days",
    score: "2",
  },
  {
    key: "3",
    question: "Trouble falling or strap sleeps, sleeping too much",
    answer: "More than the half days",
    score: "2",
  },
  {
    key: "4",
    question: "Feeling tired or having little energy",
    answer: "Several days",
    score: "1",
  },
  {
    key: "5",
    question: "Poor appitite or overeating",
    answer: "Several days",
    score: "1",
  },
];

const PatientAssessmentDetails = () => {
  const columns = [
    {
      title: (
        <span style={{ fontWeight: "bold", color: "#1677ff" }}>Question</span>
      ),
      dataIndex: "question",
      key: "question",
    },
    {
      title: (
        <span style={{ fontWeight: "bold", color: "#1677ff" }}>Answer</span>
      ),
      dataIndex: "answer",
      key: "answer",
    },
    {
      title: (
        <span style={{ fontWeight: "bold", color: "#1677ff" }}>Score</span>
      ),
      dataIndex: "score",
      key: "score",
    },
  ];

  return (
    <div
      className="MainContainer"
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        paddingRight: "12px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Typography.Title level={3} style={{ marginTop: "15px" }}>
          {" "}
          &#60; Survey Details{" "}
        </Typography.Title>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            padding: "12px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography.Title level={5}>
              Patient Health Questionnaire(PHQ-9)
            </Typography.Title>
            <Typography.Text>
              Survey Completed Date: June 05,2023 04:30PM
            </Typography.Text>
          </div>
          <Button
            style={{
              padding: "12px",
              borderRadius: "20px",
              backgroundColor: "rgba(0,255,0,0.4)",
              color: "white",
            }}
          >
            Completed
          </Button>
        </div>
      </div>
      <StyledFormContainer>
        <StyledDivContainer>
          <Typography.Text style={{ fontSize: "20px", fontWeight: "bold" }}>
            Over the last two Weeks, how often have you been bothered by the
            following problems?
          </Typography.Text>
        </StyledDivContainer>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          style={{
            marginTop: "25px",
            borderTop: "1px solid black",
            borderRight: "1px solid black",
          }}
        />
      </StyledFormContainer>
    </div>
  );
};

export default PatientAssessmentDetails;
