import { useState } from "react";
import styled from "styled-components";
import { Input, Button } from "antd";
import { SendOutlined } from "@ant-design/icons";

const InputContainer = styled.div`
  display: flex;
  padding: 10px;
  background-color: #f0f0f0;
`;

const StyledInput = styled(Input)`
  flex-grow: 1;
  margin-right: 10px;
`;

const ChatInput = ({ onSendMessage, loading }) => {
  const [message, setMessage] = useState("");

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage("");
    }
  };

  return (
    <InputContainer>
      <StyledInput
        placeholder="Type a message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onPressEnter={handleSend}
      />
      <Button
        type="primary"
        icon={<SendOutlined />}
        onClick={handleSend}
        loading={loading}
      >
        Send
      </Button>
    </InputContainer>
  );
};

export default ChatInput;
