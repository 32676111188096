import Icon from '@ant-design/icons'

const CustomIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="currentColor">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3817 6.57705L12 6.12596L11.6183 6.57705C11.5594 6.64672 11.5008 6.71511 11.4416 6.78431C11.0598 7.23043 10.6491 7.71031 9.90818 8.78647L9.90798 8.78675C9.28897 9.68714 8.69448 10.7114 8.25261 11.6648C7.82074 12.5965 7.5 13.5324 7.5 14.23C7.5 16.7262 9.50386 18.73 12 18.73C14.4961 18.73 16.5 16.7262 16.5 14.23C16.5 13.5324 16.1793 12.5965 15.7474 11.6648C15.3055 10.7114 14.711 9.68714 14.092 8.78675L14.0918 8.78647C13.3509 7.71031 12.9402 7.23043 12.5584 6.78431C12.4992 6.71511 12.4406 6.64672 12.3817 6.57705ZM11.6248 4.94097L12 4.52075L12.3752 4.94097L12.3757 4.94155L12.3759 4.94178L12.3781 4.94428L12.3883 4.95593L12.4296 5.00334C12.466 5.04541 12.5199 5.10806 12.5888 5.1893C12.7266 5.35181 12.9242 5.58856 13.1619 5.88366C13.6376 6.47436 14.2719 7.29633 14.9077 8.22291C15.5496 9.15836 16.2012 10.1645 16.6924 11.1951C17.1845 12.2278 17.5 13.2535 17.5 14.23C17.5 15.6887 16.9205 17.0877 15.8891 18.1191C14.8576 19.1506 13.4587 19.73 12 19.73C10.5413 19.73 9.14236 19.1506 8.11091 18.1191C7.07946 17.0877 6.5 15.6887 6.5 14.23C6.5 13.2535 6.81547 12.2278 7.30761 11.1951C7.79881 10.1645 8.4504 9.15836 9.09228 8.22291C9.72807 7.29633 10.3624 6.47436 10.8381 5.88366C11.0758 5.58856 11.2734 5.35181 11.4112 5.1893C11.4801 5.10806 11.534 5.04541 11.5704 5.00334L11.6117 4.95593L11.6219 4.94428L11.6241 4.94178L11.6243 4.94155L11.6248 4.94097Z"
        fill="#242235"
        stroke="currentColor"
      />
    </svg>
  </svg>
)

const GlucoseIcon = (props) => <Icon component={CustomIcon} {...props} />
export default GlucoseIcon
