import {
  BarChartOutlined,
  CalendarOutlined,
  FieldTimeOutlined,
  LineChartOutlined
} from "@ant-design/icons";
import { Card, Flex, Radio, Space, Typography } from "antd";

import { StyledDivContainer, StyledFormContainer } from "./styles";
import { useGetPatientSpO2ByIdQuery } from "@/store/features/patientDetails/patientDetailsApiSlice";
import { useParams } from "react-router-dom";
import EmptyState from "@/components/ui/empty/Empty";
import { getPatientDataSummary } from "./../constant";
import { useState } from "react";
import { PatientBarChart, PatientLineChart } from "../chart";
import LoadingComponent from "@/components/LoadingComponent";

export default function PatientPulseDetails() {
  const { patientId } = useParams();
  const [chartType, setChartType] = useState("line");

  const { data, isLoading, isError, isSuccess } = useGetPatientSpO2ByIdQuery(
    parseInt(patientId)
  );

  const handleChartTypeChange = (e) => {
    setChartType(e.target.value);
  };

  let content = null;

  if (isLoading) {
    content = <LoadingComponent text={"  SpO2 Report Loading...."} />;
  }

  if (isError || (isSuccess && data.length === 0)) {
    content = (
      <EmptyState link={`/patients/add/${patientId}/provided-devices`} />
    );
  }

  if (isSuccess && data.length !== 0) {
    let summary = getPatientDataSummary(data);
    content = (
      <div
        className="MainContainer"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          paddingRight: "12px"
        }}
      >
        {/* Text $ Filters BTNS */}

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "space-between",
            marginTop: "20px",
            marginBottom: "10px"
          }}
        >
          <Typography.Title level={4}>
            Result Summary {summary.lastDateAndDays}
          </Typography.Title>
          <Space>
            <Radio.Group onChange={handleChartTypeChange} value={chartType}>
              <Radio.Button value="line">
                <LineChartOutlined />
              </Radio.Button>
              <Radio.Button value="bar">
                <BarChartOutlined />
              </Radio.Button>
            </Radio.Group>
          </Space>
        </div>

        {/* {Cards.....} */}

        <Flex gap={"middle"} justify="space-between">
          <Card
            style={{
              width: 280,
              height: 90,
              paddingBottom: "4px"
            }}
          >
            <Space
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <Space style={{ display: "flex", flexDirection: "column" }}>
                <p>Last Reading</p>
                <h3>{summary.daysSinceLastEntry} Days Ago</h3>
              </Space>
              <FieldTimeOutlined
                style={{
                  fontSize: "28px",
                  color: "white",
                  backgroundColor: "#1677ff",
                  padding: "10px",
                  borderRadius: "15px"
                }}
              />
            </Space>
          </Card>

          <Card
            style={{
              width: 280,
              height: 90,
              paddingBottom: "4px"
            }}
          >
            <Flex gap={"middle"}>
              <Space>
                <p>Next Reading</p>
                <h3>June 05 at 10:00 am</h3>
              </Space>

              <CalendarOutlined
                style={{
                  fontSize: "28px",
                  color: "white",
                  backgroundColor: "#1677ff",
                  padding: "10px",
                  borderRadius: "15px"
                }}
              />
            </Flex>
          </Card>

          <Card
            style={{
              width: 280,
              height: 90,
              paddingBottom: "4px"
            }}
          >
            <Space
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <Space
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start"
                }}
              >
                <p>Maximum SpO2(Last {summary.totalDaysInRange} days)</p>
                <h3>{summary.maxPatientData}</h3>
              </Space>
            </Space>
          </Card>

          <Card
            style={{
              width: 280,
              height: 90,
              paddingBottom: "4px"
            }}
          >
            <Space
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <Space
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start"
                }}
              >
                <p>Average SpO2(Last {summary.totalDaysInRange} days)</p>
                <h3>{summary.averagePatientData}</h3>
              </Space>
            </Space>
          </Card>
        </Flex>

        {/* Text & Charts */}

        <StyledFormContainer>
          <Flex vertical>
            <Typography.Text style={{ fontSize: "20px", fontWeight: "bold" }}>
              SpO2 Trend Over Last {summary.formattedData.length} Reading(s)
            </Typography.Text>
            <Typography.Text>Time Period : {summary.dateRange}</Typography.Text>
          </Flex>
          <StyledDivContainer>
            {/* Text Above the Chart */}

            {/* Line chart */}

            {chartType === "line" ? (
              <PatientLineChart
                data={summary.formattedData}
                yLabel={"Patient SpO2"}
              />
            ) : (
              <PatientBarChart
                data={summary.formattedData}
                yLabel={"Patient SpO2"}
              />
            )}
          </StyledDivContainer>
        </StyledFormContainer>
      </div>
    );
  }

  return content;
}
