import {
  AudioOutlined,
  CheckSquareOutlined,
  PrinterOutlined,
  ShareAltOutlined,
  VideoCameraFilled,
} from "@ant-design/icons";
import { Button, Skeleton } from "antd";
import MonitoringSelector from "./MonitoringSelector";
import { StyledMainNavbarContent } from "./styles";
import { setCarePlan } from "@/store/features/patientDetails/patientDetailsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetHealthConditionsByPatientIdQuery } from "@/store/features/patient/patientApiSlice";
const Header = () => {
  const { patientId } = useParams();
  const { data: healthConditionsData, isLoading: isHealthConditionsLoading } =
    useGetHealthConditionsByPatientIdQuery(patientId);

  const selectedCarePlan = useSelector(
    (state) => state.patientDetails.carePlan
  );
  const dispatch = useDispatch();
  const handleCarePlanChange = (event) => {
    dispatch(setCarePlan(event.target.value));
  };

  const monitoringOptions = [
    healthConditionsData?.[0]?.hasActivityRPM && {
      label: "RPM",
      value: "rpm",
    },
    healthConditionsData?.[0]?.hasActivityCCM && {
      label: "CCM",
      value: "ccm",
    },
    healthConditionsData?.[0]?.hasActivityPCM && {
      label: "PCM",
      value: "pcm",
    },
    healthConditionsData?.[0]?.hasActivityRTM && {
      label: "RTM",
      value: "rtm",
    },
  ]?.filter((option) => option);

  return (
    <StyledMainNavbarContent>
      {/* Segment Button(RPM,CCM) Div Container */}

      <div>
        <Skeleton loading={isHealthConditionsLoading} active paragraph={false}>
          <MonitoringSelector
            options={monitoringOptions}
            value={selectedCarePlan}
            onChange={handleCarePlanChange}
          />
        </Skeleton>
      </div>

      {/* Buttons Div Container */}

      <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
        {/* Checked Button */}

        <Button
          style={{ backgroundColor: "rgba(0,255,0,0.5)", color: "white" }}
          shape="square"
          icon={
            <CheckSquareOutlined
              style={{ fontSize: "24px", fontWeight: "bold" }}
            />
          }
        ></Button>

        {/* Share Button */}

        <Button
          shape="square"
          icon={
            <ShareAltOutlined
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#1677ff",
              }}
            />
          }
        ></Button>

        {/*Printer Button */}

        <Button
          shape="square"
          icon={
            <PrinterOutlined
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "#1677ff",
              }}
            />
          }
        ></Button>

        {/* Add Button */}

        {/* Calls Buttons */}

        <div
          style={{
            borderLeft: "1px solid rgba(0,0,0,0.3)",
            paddingLeft: "3px",
            display: "flex",
            gap: "4px",
          }}
        >
          <Button
            style={{ backgroundColor: "orange", color: "white" }}
            shape="circle"
            icon={
              <VideoCameraFilled
                style={{ fontSize: "20px", fontWeight: "bold" }}
              />
            }
          ></Button>
          <Button
            style={{ backgroundColor: "rgba(0,255,0,0.5)", color: "white" }}
            shape="circle"
            icon={
              <AudioOutlined style={{ fontSize: "20px", fontWeight: "bold" }} />
            }
          ></Button>
        </div>
      </div>
    </StyledMainNavbarContent>
  );
};

export default Header;
