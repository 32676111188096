import { useState, useEffect, useRef } from "react";
import { Modal, Button } from "antd";
import { FullscreenOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { JitsiMeeting } from "@jitsi/react-sdk";

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-body {
    padding: 0;
  }
`;

const JitsiWrapper = styled.div`
  iframe {
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
`;

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
`;

const FullscreenContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const JitsiCallModal = ({ jitsiUrl, callType, onCallEnd }) => {
  const [api, setApi] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(callType === "audio");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const fullscreenContainerRef = useRef(null);

  useEffect(() => {
    return () => {
      if (api) {
        api.dispose();
      }
    };
  }, [api]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handleApiReady = (apiInstance) => {
    setApi(apiInstance);

    if (callType === "audio") {
      apiInstance.executeCommand("toggleVideo");
    }

    apiInstance.on("videoConferenceLeft", () => {
      console.log("Call ended");
      onCallEnd();
    });

    apiInstance.on("audioMuteStatusChanged", (muted) => {
      setIsMuted(muted);
    });

    apiInstance.on("videoMuteStatusChanged", (muted) => {
      setIsVideoMuted(muted);
    });
  };

  const getJitsiRoomName = (url) => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.pathname.split("/").pop();
    } catch (error) {
      console.error("Invalid URL:", url, error);
      return "";
    }
  };

  const toggleAudio = () => {
    if (api) {
      api.executeCommand("toggleAudio");
    }
  };

  const toggleVideo = () => {
    if (api) {
      api.executeCommand("toggleVideo");
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      fullscreenContainerRef.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <StyledModal
      open={true}
      onCancel={null}
      maskClosable={false}
      footer={null}
      width={832}
      destroyOnClose
      zIndex={1400}
      centered
    >
      <FullscreenContainer ref={fullscreenContainerRef}>
        <JitsiWrapper>
          <JitsiMeeting
            domain="video.medikick.xyz"
            roomName={getJitsiRoomName(jitsiUrl)}
            configOverwrite={{
              startWithAudioMuted: false,
              startWithVideoMuted: callType === "audio",
              prejoinPageEnabled: false,
            }}
            interfaceConfigOverwrite={{
              TOOLBAR_BUTTONS: [],
            }}
            onApiReady={handleApiReady}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.width = "100%";
              iframeRef.style.height = isFullscreen ? "100vh" : "600px";
            }}
          />
        </JitsiWrapper>
        <ControlsWrapper>
          <Button onClick={toggleAudio}>
            {isMuted ? "Unmute" : "Mute"} Audio
          </Button>
          <Button onClick={toggleVideo}>
            {isVideoMuted ? "Start" : "Stop"} Video
          </Button>
          <Button onClick={toggleFullscreen}>
            {isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
          </Button>
          <Button onClick={onCallEnd}>End Call</Button>
        </ControlsWrapper>
      </FullscreenContainer>
    </StyledModal>
  );
};

export default JitsiCallModal;
