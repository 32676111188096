import Header from "./Components/Header";
import TableContent from "./Components/TableContent";
import { StyledMainContainer } from "./Style";


const UserDetail=()=>{

    return(
        <StyledMainContainer>
            <Header/>
            <TableContent/>
        </StyledMainContainer>
    )
}


export default UserDetail;





