import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import AddPatientForm from "@/Pages/Patients/addPatientForm/Form";
import { useSelector } from "react-redux";
import CareGiverInfo from "../CareGiverInfo";
import CcmSetup from "../ccmSetup";
import HealthConditions from "../HealthConditions";
import PatientSetting from "../PatientSetting";
import PcmSetup from "../pcmSetup";
import ProvideDevices from "../ProvidedDevice";
import RpmSetup from "../rpmSetup";
import RtmSetup from "../rtmSetup";
const StyledCollapse = styled(Collapse)`
  background-color: white;
  padding: 35px 23px;

  .ant-collapse-item {
    margin-bottom: 24px;
    border: none;
  }

  .ant-collapse-header {
    background-color: #f0f2f5;
    border-radius: 4px !important;
    padding: 12px 16px !important;
  }

  .ant-collapse-content {
    border-top: none;
  }
`;

const CollapseForm = () => {
  const { patientId, tabKey } = useParams();
  const navigate = useNavigate();
  const managementMethods = useSelector((state) => state.addPatient.management);

  const items = [
    {
      key: "patient-information",
      label: <Typography.Title level={4}>Patient Information</Typography.Title>,
      children: <AddPatientForm />,
    },
    {
      key: "health-conditions",
      label: <Typography.Title level={4}>Health Conditions</Typography.Title>,
      children: <HealthConditions />,
    },
    managementMethods.rpm && {
      key: "rpm-setup",
      label: <Typography.Title level={4}>RPM Setup</Typography.Title>,
      children: <RpmSetup />,
    },
    managementMethods.ccm && {
      key: "ccm-setup",
      label: <Typography.Title level={4}>CCM Setup</Typography.Title>,
      children: <CcmSetup />,
    },
    managementMethods.pcm && {
      key: "pcm-setup",
      label: <Typography.Title level={4}>PCM Setup</Typography.Title>,
      children: <PcmSetup />,
    },
    managementMethods.rtm && {
      key: "rtm-setup",
      label: <Typography.Title level={4}>RTM Setup</Typography.Title>,
      children: <RtmSetup />,
    },
    {
      key: "caregiver-info",
      label: <Typography.Title level={4}>Care Giver Info</Typography.Title>,
      children: <CareGiverInfo />,
    },
    {
      key: "patient-settings",
      label: <Typography.Title level={4}>Patient Setting</Typography.Title>,
      children: <PatientSetting />,
    },
    {
      key: "provided-devices",
      label: <Typography.Title level={4}>Provided Device</Typography.Title>,
      children: <ProvideDevices />,
    },
  ].filter((item) => !!item && item);

  const onChange = (key) => {
    if (key?.length) {
      const newTabKey = key[0];
      navigate(`/patients/add/${patientId}/${newTabKey}`);
    }
  };

  return (
    <StyledCollapse
      accordion
      items={items}
      activeKey={tabKey}
      onChange={onChange}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      destroyInactivePanel={true}
    />
  );
};

export default CollapseForm;
