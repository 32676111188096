import { setErrorMessage } from '@/store/features/errorMessage/errorMessageSlice'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AlertWrapper, StyledAlert } from './styles'

const ErrorAlert = () => {
  const alertRef = useRef(null)
  const { show, message } = useSelector((state) => state.errorMessage)
  const dispatch = useDispatch()
  const onClose = () => {
    dispatch(setErrorMessage({ show: false, message: '' }))
  }
  useEffect(() => {
    if (alertRef.current) {
      alertRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [message, show]) // Re-run effect if message or description changes
  if (!show) return null
  return (
    <AlertWrapper ref={alertRef}>
      <StyledAlert
        message="Error!"
        description={message}
        type="error"
        closable={true}
        onClose={onClose}
        showIcon
      />
    </AlertWrapper>
  )
}

export default ErrorAlert
