import { Table, Grid } from "antd";
import {
  HighRiskTag,
  LowRiskTag,
  ModerateRiskTag,
  StyledModal,
} from "./styles";

const { useBreakpoint } = Grid;

const columns = [
  {
    title: "Risk Type",
    dataIndex: "riskType",
    key: "riskType",
    width: 150,
    render: (text) => {
      switch (text) {
        case "High Risk":
          return <HighRiskTag>{text}</HighRiskTag>;
        case "Moderate Risk":
          return <ModerateRiskTag>{text}</ModerateRiskTag>;
        case "Low Risk":
          return <LowRiskTag>{text}</LowRiskTag>;
        default:
          return text;
      }
    },
  },
  {
    title: "Clinical Condition",
    dataIndex: "clinicalCondition",
    key: "clinicalCondition",
  },
  {
    title: "Utilization",
    dataIndex: "utilization",
    key: "utilization",
  },
];

const data = [
  {
    key: "1",
    riskType: "High Risk",
    clinicalCondition:
      "3 Or More Active Chronic Conditions\nClinical Metric Criticality Out Of Bounds (E.G. A1C > 9, Stage D Heart Failure)\nActive Cancer",
    utilization:
      "Three Of More ED Visits\nAny Readmission\nAny Hospitalization Related To Chronic Conditions\nAdmission To Hospice Or Palliative Care",
  },
  {
    key: "2",
    riskType: "Moderate Risk",
    clinicalCondition:
      "Fewer Than 3 Active Chronic Conditions\nHistory Of Cancer\nClinical Metrics Moderately Out Of Bounds (E.G. A1C < 9, Stage B Or C Heart Failure)",
    utilization:
      "One ED Visit Related To Chronic Condition\nAny Hospitalization",
  },
  {
    key: "3",
    riskType: "Low Risk",
    clinicalCondition:
      "1 major chronic condition & 1 minor chronic condition\nClinical Metrics Within Normal Range",
    utilization: "No Recent ED Visits Or Hospitalization",
  },
];

const RiskClassificationModal = ({ visible, onClose }) => {
  const { xs, sm, md } = useBreakpoint();
  return (
    <StyledModal
      open={visible}
      onCancel={onClose}
      footer={null}
      title="Three or more ED visits"
      width={md ? 800 : xs ? 400 : sm ? 600 : 500}
      centered
    >
      <Table columns={columns} dataSource={data} pagination={false} />
    </StyledModal>
  );
};

export default RiskClassificationModal;
