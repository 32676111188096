import { useState, useCallback } from "react";
import { Button, Form, Select, Row, Col, Divider, Flex, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { StyledFormContainer, StyledFormTitle } from "./Style";
import TypewriterEffect from "@/components/shared/TypeWriterEffect";
import styled from "styled-components";
import {
  useGetPatientBillingReportQuery,
  useGetPatientDeviceReportQuery,
  useGetPatientsListReportQuery,
  useGetPatientsReportQuery
} from "@/store/features/reports/reportsApiSlice";

const { Option } = Select;

const DownloadButton = styled(Button)`
  /* margin-top: 20px; */
`;

export default function ReportsForm() {
  const [selectedReportType, setSelectedReportType] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState({
    type: null,
    trigger: false
  });

  const { data: patientReport, isLoading: isPatientReportLoading } =
    useGetPatientsReportQuery(undefined, {
      skip: !(triggerFetch.type === "billing" && triggerFetch.trigger)
    });
  const {
    data: patientBillingReport,
    isLoading: isPatientBillingReportLoading
  } = useGetPatientBillingReportQuery(undefined, {
    skip: !(triggerFetch.type === "billingReport" && triggerFetch.trigger)
  });
  const { data: patientDeviceReport, isLoading: isPatientDeviceReportLoading } =
    useGetPatientDeviceReportQuery(undefined, {
      skip: !(triggerFetch.type === "patientDevices" && triggerFetch.trigger)
    });
  const { data: patientListReport, isLoading: isPatientListReportLoading } =
    useGetPatientsListReportQuery(undefined, {
      skip: !(triggerFetch.type === "patientList" && triggerFetch.trigger)
    });

  const onFinish = (values) => {
    setSelectedReportType(values.reportType);
    setTriggerFetch({ type: values.reportType, trigger: true });
  };

  const isLoading =
    isPatientReportLoading ||
    isPatientDeviceReportLoading ||
    isPatientListReportLoading ||
    isPatientBillingReportLoading;

  const getReportData = () => {
    switch (selectedReportType) {
      case "billing":
        return patientReport;
      case "billingReport":
        return patientBillingReport;
      case "patientDevices":
        return patientDeviceReport;
      case "patientList":
        return patientListReport;
      default:
        return null;
    }
  };

  const getFileExtension = useCallback((reportType) => {
    switch (reportType) {
      case "patientList":
      case "patientDevices":
      case "billingReport":
        return "xlsx";
      case "billing":
        return "pdf";
      default:
        return "bin";
    }
  }, []);

  const getMimeType = useCallback((extension) => {
    switch (extension) {
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "pdf":
        return "application/pdf";
      default:
        return "application/octet-stream";
    }
  }, []);

  const handleDownload = () => {
    const reportData = getReportData();
    if (!reportData) {
      message.error("No report data available.");
      return;
    }

    const fileExtension = getFileExtension(selectedReportType);
    const mimeType = getMimeType(fileExtension);

    // Create a Blob with dynamic type
    const blob = new Blob([reportData], { type: mimeType });

    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${selectedReportType}_report.${fileExtension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    message.success("File downloaded successfully");
    setTriggerFetch({ type: null, trigger: false });
  };

  return (
    <StyledFormContainer>
      <StyledFormTitle level={3} style={{ marginBottom: "22px" }}>
        <TypewriterEffect>
          {"  Select Type To Generate Reports"}
        </TypewriterEffect>
      </StyledFormTitle>
      <Flex align="center" direction="column">
        <Form
          justify="center"
          style={{ width: "40rem" }}
          name="reportsForm"
          onFinish={onFinish}
          layout="vertical"
          requiredMark="optional"
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="reportType"
                label="Report Type"
                rules={[
                  {
                    required: true,
                    message: "Please select the Report Type!"
                  }
                ]}
              >
                <Select placeholder="Select Report Type">
                  <Option value="billing">Patient Sample Report</Option>
                  <Option value="billingReport">Patient Billing Report</Option>
                  <Option value="patientList">Patients List</Option>
                  <Option value="patientDevices">Patient Devices</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Flex gap={"large"} justify="flex-end">
            <Button
              style={{ borderRadius: "7px", padding: "2px 17px" }}
              onClick={() => {
                setSelectedReportType(null);
                setTriggerFetch({ type: null, trigger: false });
              }}
            >
              Cancel
            </Button>

            <Button
              type="primary"
              style={{ borderRadius: "7px", padding: "2px 17px" }}
              htmlType="submit"
              loading={isLoading}
            >
              Generate
            </Button>

            {getReportData() && (
              <DownloadButton
                type="primary"
                icon={<DownloadOutlined />}
                onClick={handleDownload}
              >
                Download Report
              </DownloadButton>
            )}
          </Flex>
        </Form>
      </Flex>
    </StyledFormContainer>
  );
}
