//{ External dependencies}
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
  Typography,
} from 'antd'
import { useState } from 'react'
const { Option } = Select
const { TextArea } = Input
const { Text } = Typography
// {internal dependencies}
import { StyledFormContainer } from './Style'

export default function DashboardNotesComponent() {
  const onFinish = (values) => {
    console.log('Received values of form: ', values)
  }

  return (
    <StyledFormContainer>
      <Typography.Title level={3} style={{ marginBottom: '23px' }}>
        Add Notes
      </Typography.Title>
      <Form
        name="dashboardNotesComponent"
        onFinish={onFinish}
        layout="vertical"
        requiredMark="optional"
      >
        {/*Add Description Component*/}
        <DescriptionInput placeholder={'Add Notes'} p={'Patient Notes'} />

        {/* Add time,classification*/}
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="time"
              label="Add Extra Time"
              rules={[
                {
                  required: true,
                  message: 'Please select Time!',
                },
              ]}
            >
              <Select placeholder="5">
                <Option value="4">4</Option>
                <Option value="5">5</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={15}>
            <Form.Item
              name="classification"
              label="Classificiation"
              rules={[
                {
                  required: true,
                  message: 'Please select users!',
                },
              ]}
            >
              <Select placeholder="Telephone Call with Patient">
                <Option value="maleUser">Male</Option>
                <Option value="femaleUser">Female</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* date,time*/}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="date"
              label="Select Date"
              rules={[
                {
                  required: true,
                  message: 'Please select the date!',
                },
              ]}
            >
              <DatePicker placeholder="Select Date" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Select Time"
              name="time"
              rules={[{ required: true, message: 'Please select time!' }]}
            >
              <TimePicker format="HH:mm:ss" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        {/* Add Task Checkbox */}
        <Form.Item
          name="addTask"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject('Should Check Add Tasks'),
            },
          ]}
        >
          <Checkbox>Add Tasks</Checkbox>
        </Form.Item>

        {/*Add Description Component*/}
        <DescriptionInput placeholder={'Add Tasks'} p={'Task Notes'} />

        {/* Assign to provider Checkbox */}
        <Form.Item
          name="assignToProvider"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject('Should Check Assign to provider'),
            },
          ]}
        >
          <Checkbox>Assign to Provider</Checkbox>
        </Form.Item>

        <Divider />

        {/* Cancel & Save Button */}
        <Row gutter={16}>
          <Col span={5}>
            <Form.Item>
              <Button type="" htmlType="submit">
                Cancel
              </Button>
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledFormContainer>
  )
}

// {DecscritpionInput function}

export const DescriptionInput = ({ placeholder, p }) => {
  const [description, setDescription] = useState('')
  const maxChars = 1300

  const handleChange = (e) => {
    const inputText = e.target.value
    const alphabetCount = inputText.replace(/[^a-zA-Z]/g, '').length

    if (alphabetCount <= maxChars) {
      setDescription(inputText)
    } else {
      // Truncate the input to the maximum allowed alphabet count
      const truncatedText = inputText.split('').reduce(
        (acc, char) => {
          if (acc.alphabetCount < maxChars && /[a-zA-Z]/.test(char)) {
            acc.text += char
            acc.alphabetCount++
          } else if (!/[a-zA-Z]/.test(char)) {
            acc.text += char
          }
          return acc
        },
        { text: '', alphabetCount: 0 }
      ).text

      setDescription(truncatedText)
    }
  }

  const alphabetCount = description.replace(/[^a-zA-Z]/g, '').length
  const isNearLimit = alphabetCount >= maxChars * 0.9
  const isAtLimit = alphabetCount === maxChars

  return (
    <div>
      <p style={{ marginBottom: '4px' }}>{p}</p>
      <TextArea
        value={description}
        onChange={handleChange}
        rows={4}
        placeholder={placeholder}
      />
      <Text
        style={{
          display: 'block',
          textAlign: 'right',
          marginTop: '8px',
          color: isAtLimit ? 'red' : isNearLimit ? 'orange' : 'inherit',
        }}
      >
        {alphabetCount} / {maxChars} alphabets
      </Text>
    </div>
  )
}
