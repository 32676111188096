import { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Avatar, Button, Flex, Input, message, Typography, Upload } from "antd";
import { SendOutlined, UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import useGetAllPatients from "@/hooks/dashboard/useGetAllPatients";
import usePubNub from "@/hooks/pubnub/usePubnub";
import PatientList from "@/Pages/Messages/PatientList";
import CallInterface from "./CallInterface";
import {
  Container,
  Sidebar,
  ChatContainer,
  ChatHeader,
  MessageList,
  MessageBubble,
  MessageInputWrapper,
} from "./Style";

dayjs.extend(relativeTime);

const { Text } = Typography;

const MessagingUI = () => {
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [fileList, setFileList] = useState([]);
  const userName = useSelector((state) => state.auth.username) || "";

  const { receiver } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 20;
  const messagesEndRef = useRef(null);

  const {
    isLoading: isLoadingPatients,
    patientList,
    totalCount,
    error: patientsError,
  } = useGetAllPatients({ pageNumber, pageSize });
  const [infinitePatientList, setInfinitePatientList] = useState([]);

  const {
    messages,
    currentChannel,
    sendMessage,
    createChannel,
    subscribeToChannel,
    fetchHistory,
    error: pubNubError,
  } = usePubNub();

  useEffect(() => {
    setInfinitePatientList((prev) => [...prev, ...patientList]);
  }, [patientList]);

  useEffect(() => {
    if (pubNubError) {
      message.error("An error occurred while sending/receiving messages.");
    }
  }, [pubNubError]);

  useEffect(() => {
    if (patientsError) {
      message.error("An error occurred while fetching patients.");
    }
  }, [patientsError]);

  const handlePersonSelect = useCallback(
    async (person) => {
      setSelectedPerson(person);
      const channelName = createChannel(person.firstName + person.lastName);
      subscribeToChannel(channelName);
      await fetchHistory(channelName);
    },
    [createChannel, subscribeToChannel, fetchHistory]
  );

  useEffect(() => {
    if (receiver) {
      const person = infinitePatientList.find(
        (p) => p.patientId.toString() === receiver
      );
      if (person) {
        handlePersonSelect(person);
      }
    }
  }, [receiver, infinitePatientList, handlePersonSelect]);

  const handleSend = useCallback(async () => {
    if (inputValue.trim() && currentChannel) {
      await sendMessage(inputValue);
      setInputValue("");
    }
  }, [inputValue, currentChannel, sendMessage]);

  const loadMorePatients = useCallback(() => {
    if (patientList.length < totalCount) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  }, [patientList.length, totalCount]);

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(scrollToBottom, [scrollToBottom]);

  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  return (
    <Container>
      <Sidebar>
        <PatientList
          patientList={infinitePatientList}
          totalCount={totalCount}
          isLoadingPatients={isLoadingPatients}
          loadMorePatients={loadMorePatients}
          handlePersonSelect={handlePersonSelect}
          userName={userName}
        />
      </Sidebar>
      <ChatContainer>
        {selectedPerson && currentChannel ? (
          <>
            <ChatHeader>
              <Flex align="center">
                <Avatar
                  style={{ marginRight: 16 }}
                  src={selectedPerson?.photoUrl}
                  alt={selectedPerson?.firstName}
                />
                <Text strong>{selectedPerson.firstName}</Text>
              </Flex>
              <Flex gap="middle">
                <CallInterface recipientUsername={selectedPerson?.firstName} />
              </Flex>
            </ChatHeader>
            <MessageList>
              {messages.map((message, index) => (
                <MessageBubble
                  key={`${message.timestamp}-${index}`}
                  isUser={message.sender === userName}
                >
                  {message.content}
                </MessageBubble>
              ))}
              <div ref={messagesEndRef} />
            </MessageList>
            <MessageInputWrapper>
              <Upload {...uploadProps}>
                <Button type="text" icon={<UploadOutlined />} />
              </Upload>
              <Input
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onPressEnter={handleSend}
                suffix={
                  <Button
                    type="primary"
                    icon={<SendOutlined />}
                    onClick={handleSend}
                    disabled={!currentChannel}
                  />
                }
                placeholder={
                  currentChannel
                    ? "Type your message here..."
                    : "Select a person to start chatting"
                }
                disabled={!currentChannel}
              />
            </MessageInputWrapper>
          </>
        ) : (
          <Flex justify="center" align="center" style={{ height: "100%" }}>
            <Text>Select a person to start chatting</Text>
          </Flex>
        )}
      </ChatContainer>
    </Container>
  );
};

export default MessagingUI;
