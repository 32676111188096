import { Col, Divider, Form, Modal, Row, Select, Input, Spin } from "antd";
import { StyledFormContainer } from "./Style";
import {
  useGetLocationsQuery,
  useGetConditionsQuery,
} from "@/store/features/dashboard/dashboardApiSlice";
import { useDispatch } from "react-redux";
import { setFilters } from "@/store/features/dashboard/dashboardSlice";

export default function FilterForm({ open, setOpen }) {
  const [form] = Form.useForm();
  const { data: locations, isLoading: isLoadingLocations } =
    useGetLocationsQuery();
  const { data: conditions, isLoading: isLoadingConditions } =
    useGetConditionsQuery();
  const dispatch = useDispatch();
  const handleOk = () => {
    const values = form.getFieldsValue(true);
    dispatch(setFilters(values));
    setOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(setFilters({}));
    setOpen(false);
  };

  const filterConfig = [
    {
      label: "Condition",
      name: "condition",
      type: "select",
      options: conditions || [],
    },
    {
      label: "Payer",
      name: "payerId",
      type: "select",
      options: [
        {
          value: 1,
          label: "Insurance",
        },
        {
          value: 2,
          label: "Self Pay",
        },
        {
          value: 3,
          label: "Medicare",
        },
        {
          value: 4,
          label: "Medicaid",
        },
        {
          value: 5,
          label: "Commercial",
        },
        {
          value: 6,
          label: "Other",
        },
      ],
    },
    {
      label: "Location",
      name: "location",
      type: "select",
      options: locations || [],
    },
    {
      label: "MRN",
      name: "mrn",
      type: "input",
    },
  ];

  return (
    <Modal
      title="Filter Patients"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      cancelText="Reset"
      okText="Apply"
      centered
    >
      <StyledFormContainer>
        <Form name="filter_form" layout="vertical" form={form}>
          <Row gutter={16}>
            {filterConfig.map((item) => (
              <Col xs={24} sm={12} key={item.name}>
                <Form.Item name={item.name} label={item.label}>
                  {item.type === "select" ? (
                    <Select
                      placeholder={`Select ${item.label}`}
                      options={item.options}
                      loading={
                        item.name === "location"
                          ? isLoadingLocations
                          : isLoadingConditions
                      }
                      notFoundContent={
                        item.name === "location" ? (
                          isLoadingLocations
                        ) : isLoadingConditions ? (
                          <Spin size="small" />
                        ) : null
                      }
                      allowClear
                    />
                  ) : (
                    <Input placeholder={`Enter ${item.label}`} />
                  )}
                </Form.Item>
              </Col>
            ))}
          </Row>
          <Divider />
        </Form>
      </StyledFormContainer>
    </Modal>
  );
}
