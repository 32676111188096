import logo from '@/assets/images/logo.svg'
import { LogoContainer, LogoText } from './style'

const Logo = ({ text }) => (
  <LogoContainer>
    <img src={logo} alt="logo" width={50} height={50} />
    {text && (
      <LogoText>
        Med<span>Kick</span>
      </LogoText>
    )}
  </LogoContainer>
)

export default Logo
