import {
  BloodPressureIcon,
  GlucoseIcon,
  HeartRateIcon,
  MessageBubbleIcon,
  NotificationBellIcon,
  PendingTaskIcon,
  SpO2Icon,
  TasksIcon,
  TemperatureIcon,
  WeightIcon,
} from "@/assets/icons";
import {
  setAIMessage,
  setAIMessageModal,
  setShouldSendAutomatically,
} from "@/store/features/chat/chatContextSlice";
import { Card, Col, Flex, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import NoteAndTime from "../../../../components/shared/NoteAndTime";
import PatientInfo from "../../../../components/shared/PatientInfo";
import PriorityTag from "./PriorityTag";
import StatCounter from "./StatCounter";

const StyledCard = styled(Card)`
  margin-bottom: 8px;
  border-radius: 12px;
  .ant-card-body {
    padding: 16px;
  }
`;

const PatientCard = ({ patient, loading }) => {
  const [patientVitals, setPatientVitals] = useState([]);
  const [patientAlerts, setPatientAlerts] = useState([]);
  const [patientData, setPatientData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    const vitals = [
      {
        icon: <HeartRateIcon />,
        value: patient?.heartRate || "N/A",
        color: patient?.heartRateColorCode,
        prompt: patient?.heartRateAIPrompt,
      },
      {
        icon: <BloodPressureIcon />,
        value: patient?.systolicPressure
          ? `${patient?.systolicPressure}/${patient?.diastolicPressure}`
          : "N/A",
        color: patient?.bloodPressureColorCode,
        prompt: patient?.bloodPressureAIPrompt,
      },
      {
        icon: <GlucoseIcon />,
        value: patient.bloodGlucose || "N/A",
        color: patient?.bloodGlucoseColorCode,
        prompt: patient?.bloodGlucoseAIPrompt,
      },
      {
        icon: <SpO2Icon />,
        value: patient.spO2 || "N/A",
        color: patient?.spO2ColorCode,
        prompt: patient?.spO2AIPrompt,
      },
      {
        icon: <WeightIcon />,
        value: patient.weight || "N/A",
        color: patient?.weightColorCode,
        prompt: patient?.weightAIPrompt,
      },
      {
        icon: <TemperatureIcon />,
        value: patient.temperature || "N/A",
        color: patient?.temperatureColorCode,
        prompt: patient?.temperatureAIPrompt,
      },
    ];
    const alerts = [
      {
        icon: <TasksIcon />,
        value: patient.notesCount || "N/A",
        onClick: () => navigate(`/patients/${patient.patientId}/details`),
      },
      {
        icon: <PendingTaskIcon />,
        value: patient.pendingTaskCount || "N/A",
      },
      {
        icon: <NotificationBellIcon />,
        value: patient.notifications || "N/A",
      },
      {
        icon: <MessageBubbleIcon />,
        value: patient.messages || "N/A",
      },
    ];

    const patientName = patient?.patientName;
    setPatientAlerts(alerts);
    setPatientVitals(vitals);
    setPatientData({
      vitals: vitals,
      alerts: alerts,
      name: patientName,
      photoUrl: patient?.photoUrl,
      priority: patient?.priority,
      mrn: patient?.mrn,
      riskClassificationName: patient?.riskClassificationName,
    });
  }, [patient, navigate]);

  const dispatch = useDispatch();
  const handleVitalClick = (vital) => {
    if (vital?.prompt) {
      dispatch(setAIMessage(vital.prompt));
      dispatch(setAIMessageModal(true));
      dispatch(setShouldSendAutomatically(true));
    }
  };

  return (
    <StyledCard>
      <Skeleton loading={loading} active avatar paragraph={{ rows: 1 }}>
        <Row gutter={[16, 16]} align="middle" justify="space-between">
          <Col xs={24} sm={12} md={12} lg={6} xl={5} xxl={4}>
            <Link to={`/patients/${patient.patientId}/details`}>
              <PatientInfo
                name={patientData.name}
                mrn={patientData.mrn}
                photoUrl={patientData.photoUrl}
              />
            </Link>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={3} xxl={2}>
            <PriorityTag
              priority={patientData?.riskClassificationName || "N/A"}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={7} xxl={6}>
            <Flex gap="middle" align="center" justify="space-around" wrap>
              {patientVitals.map((vital, index) => (
                <StatCounter
                  key={index}
                  icon={vital.icon}
                  value={vital.value}
                  color={vital?.color}
                  prompt={vital?.prompt}
                  onClick={() => handleVitalClick(vital)}
                />
              ))}
            </Flex>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={4} xxl={3}>
            <Flex gap="middle" align="center" justify="space-around" wrap>
              {patientAlerts.map((alert, index) => (
                <StatCounter
                  key={index}
                  icon={alert.icon}
                  value={alert.value}
                  onClick={alert?.onClick}
                  style={{ cursor: alert?.onClick && "pointer" }}
                />
              ))}
            </Flex>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={4} xxl={7}>
            <NoteAndTime
              patientId={patient.patientId}
              time="N/A"
              note={patient?.lastCreatedNote ? patient?.lastCreatedNote : "N/A"}
            />
          </Col>
        </Row>
      </Skeleton>
    </StyledCard>
  );
};

export default PatientCard;
