export const BASE_URL = import.meta.env.VITE_API_URL;

// Auth Routes
export const LOGIN_URL = `${BASE_URL}/api/account/login`;
export const REGISTER_STEP_1_URL = `${BASE_URL}/api/temporaryuserregistration`;
export const REGISTER_STEP_2_URL = `${BASE_URL}/api/account/register`;
export const USER_PROFILE_URL = `${BASE_URL}/api/Account/currentUser`;
// Patient Routes
export const CREATE_PATIENT_URL = `${BASE_URL}/api/patient`;
export const UPDATE_PATIENT_URL = `${BASE_URL}/api/patient`;
export const GET_ALL_PATIENTS_URL = `${BASE_URL}/api/patient`;
export const GET_PATIENT_BY_ID_URL = `${BASE_URL}/api/patient`;
export const DELETE_PATIENT_URL = `${BASE_URL}/api/patient`;
export const GET_PATIENT_BY_MRN_URL = `${BASE_URL}/api/patient/getpatient-by-mrn-no?mrn=`;

// Health Condition Routes
export const CREATE_HEALTH_CONDITION_URL = `${BASE_URL}/api/healthcondition`;
export const UPDATE_HEALTH_CONDITION_URL = `${BASE_URL}/api/healthcondition`;
export const GET_HEALTH_CONDITION_BY_ID_URL = `${BASE_URL}/api/healthcondition`;
export const DELETE_HEALTH_CONDITION_URL = `${BASE_URL}/api/healthcondition`;

// RPM/RTM Routes
export const CREATE_RPM_RTM_URL = `${BASE_URL}/api/patientrpm`;
export const UPDATE_RPM_RTM_URL = `${BASE_URL}/api/patientrpm`;
export const GET_ALL_RPM_RTM_URL = `${BASE_URL}/api/patientrpm`;
export const GET_RPM_RTM_BY_ID_URL = `${BASE_URL}/api/patientrpm`;
export const DELETE_RPM_RTM_URL = `${BASE_URL}/api/patientrpm`;

// CCM/PCM Routes
export const CREATE_CCM_PCM_URL = `${BASE_URL}/api/patientcaremanagement`;
export const UPDATE_CCM_PCM_URL = `${BASE_URL}/api/patientcaremanagement`;
export const GET_ALL_CCM_PCM_URL = `${BASE_URL}/api/patientcaremanagement`;
export const GET_CCM_PCM_BY_ID_URL = `${BASE_URL}/api/patientcaremanagement`;

// CareGiver Routes
export const CREATE_CAREGIVER_URL = `${BASE_URL}/api/CareGiverInfo`;
export const UPDATE_CAREGIVER_URL = `${BASE_URL}/api/CareGiverInfo`;
export const GET_ALL_CAREGIVERS_URL = `${BASE_URL}/api/CareGiverInfo`;
export const GET_CAREGIVER_BY_ID_URL = `${BASE_URL}/api/CareGiverInfo`;
export const DELETE_CAREGIVER_URL = `${BASE_URL}/api/CareGiverInfo`;

// Device Routes
export const CREATE_DEVICE_URL = `${BASE_URL}/api/device`;
export const CREATE_DEVICE_ORDER_URL = `${BASE_URL}/api/DeviceOrder`;
export const UPDATE_DEVICE_URL = `${BASE_URL}/api/device`;
export const GET_ALL_DEVICES_URL = `${BASE_URL}/api/device`;
export const GET_DEVICE_BY_ID_URL = `${BASE_URL}/api/device`;
export const DELETE_DEVICE_BY_ID_URL = `${BASE_URL}/api/device`;
export const GET_MIOLAB_DEVICE_URL = `https://api.connect.mio-labs.com/v1/devices`;
export const GET_ALL_DEVICE_ORDERS_URL = `${BASE_URL}/api/DeviceOrder`;
export const DELETE_DEVICE_ORDERS_URL = `${BASE_URL}/api/DeviceOrder`;

// Patient Setting Routes
export const CREATE_PATIENT_SETTING_URL = `${BASE_URL}/api/patientsetting`;

// Device Patient Mapping Routes
export const CREATE_DEVICE_PATIENT_MAPPING_URL = `${BASE_URL}/api/devicepatientmapping`;
export const UPDATE_DEVICE_PATIENT_MAPPING_URL = `${BASE_URL}/api/devicepatientmapping`;
export const GET_ALL_DEVICE_PATIENT_MAPPINGS_URL = `${BASE_URL}/api/devicepatientmapping`;
export const DELETE_DEVICE_PATIENT_MAPPING_URL = `${BASE_URL}/api/devicepatientmapping`;

// Keeping the following routes as they were not in the Postman collection but might still be needed

export const LOGOUT_URL = `${BASE_URL}/auth/logout`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}/auth/forgot-password`;
export const RESET_PASSWORD_URL = `${BASE_URL}/auth/reset-password`;
export const PROFILE_UPLOAD_AVATAR_URL = `${BASE_URL}/auth/profile/upload-avatar`;
export const PROFILE_PASSWORD_UPDATE_URL = `${BASE_URL}/auth/profile/password-update`;
export const VERIFY_PHONE_URL = `${BASE_URL}/auth/verify-phone`;

export const USERS_URL = `${BASE_URL}/users`;
export const USERS_ME_URL = `${BASE_URL}/users/me`;
export const USERS_UPDATE_URL = `${BASE_URL}/users/update`;
export const USERS_DELETE_URL = `${BASE_URL}/users/delete`;
export const USERS_UPLOAD_AVATAR_URL = `${BASE_URL}/users/upload-avatar`;
export const USERS_UPDATE_PASSWORD_URL = `${BASE_URL}/users/update-password`;

export const ROLES_URL = `${BASE_URL}/roles`;
export const ROLES_CREATE_URL = `${BASE_URL}/roles/create`;
export const ROLES_UPDATE_URL = `${BASE_URL}/roles/update`;
export const ROLES_DELETE_URL = `${BASE_URL}/roles/delete`;

export const PERMISSIONS_URL = `${BASE_URL}/permissions`;
export const PERMISSIONS_CREATE_URL = `${BASE_URL}/permissions/create`;
export const PERMISSIONS_UPDATE_URL = `${BASE_URL}/permissions/update`;
export const PERMISSIONS_DELETE_URL = `${BASE_URL}/permissions/delete`;

export const CREATE_DEVICE_PROVIDER_URL = `${BASE_URL}/api/provider/create`;
export const CREATE_ADD_USER_URL = `${BASE_URL}/api/add-user/create`;

//CCM Routes
export const CREATE_PATIENT_HISTORY_URL = `${BASE_URL}/api/ccmpatienthistory`;
export const CREATE_CCM_GOAL_URL = `${BASE_URL}/api/ccmgoalmapping`;
export const CREATE_CCM_BARRIER_URL = `${BASE_URL}/api/CCMBarrierMapping`;
export const CREATE_CCM_SYMPTOM_URL = `${BASE_URL}/api/CCMSymptomMapping`;
export const CREATE_CCM_ALLERGY_URL = `${BASE_URL}/api/ccmallergymapping`;
export const CREATE_CCM_MEDICATION_URL = `${BASE_URL}/api/CCMMedication`;
export const CREATE_CCM_MONITORING_URL = `${BASE_URL}/api/CCMMonitoring`;
export const CREATE_CCM_PREVENTIVE_CARE_URL = `${BASE_URL}/api/CCMPreventiveCareMeasureMapping`;
export const CREATE_CCM_PSYCHIATRIC_TEST_URL = `${BASE_URL}/api/ccmphysiciattestmapping`;
export const CREATE_CCM_SELF_MANAGEMENT_URL = `${BASE_URL}/api/CCMSelfManagement`;
export const CREATE_CCM_ADDITIONAL_NOTES_URL = `${BASE_URL}/api/CCMAdditionalInfo`;
export const CREATE_CCM_RISK_URL = `${BASE_URL}/api/ccmriskmapping`;

export const GET_CCM_GOALS_URL = `${BASE_URL}/api/CCMGoal`;
export const GET_CCM_BARRIERS_URL = `${BASE_URL}/api/CCMBarrier`;
export const GET_CCM_SYMPTOMS_URL = `${BASE_URL}/api/CCMSymptom`;
export const GET_CCM_PREVENTIVE_CARE_URL = `${BASE_URL}/api/CCMPreventiveCareMeasure`;
export const GET_CCM_CHRONIC_CONDITIONS_URL = `${BASE_URL}/api/CCMChronicCondition`;

// users route
export const GET_USER_PROFILE_URL = `${BASE_URL}/api/User/profile`;

// jitsi calling URL
export const INITIATE_CALL_LINK_URL = `${BASE_URL}/api/UserCall/initiatecall`;
export const GENERATE_MEETING_LINK_URL = `${BASE_URL}/api/Jitsi/generate-meeting-url`;

// patients details URL

export const GET_PATIENT_PULSE_ID = `${BASE_URL}/api/PatientPulse?PageNumber=1&PageSize=10&patientId=`;
export const GET_PATIENT_WEIGHT_ID = `${BASE_URL}/api/PatientWeight`;
export const GET_PATIENT_SPO2_ID = `${BASE_URL}/api/PatientSPo2`;
export const GET_PATIENT_GLUCOSE_ID = `${BASE_URL}/api/PatientGlucose`;

//patient tasks
export const PATIENT_TASK_URL = `${BASE_URL}/api/PatientTask`;

//patient notes
export const PATIENT_NOTE_URL = `${BASE_URL}/api/PatientNote`;

//dashboard
export const GET_PATIENT_DASHBOARD_DATA = `${BASE_URL}/api/patientDashboard`;

//time log
export const GET_ALL_TIME_LOGS_URL = `${BASE_URL}/api/TimeTracker`;

//global location

export const GET_ALL_LOCATIONS = `${BASE_URL}/api/GlobalLocation`;
export const CREATE_GLOBAL_LOCATION = `${BASE_URL}/api/GlobalLocation`;
export const UPDATE_GLOBAL_LOCATION = `${BASE_URL}/api/GlobalSetting`;
export const CREATE_GLOBAL_SETTINGS_FOR_USER = `${BASE_URL}/api/GlobalSetting`;
export const UPDATE_GLOBAL_SETTINGS_FOR_USER = `${BASE_URL}/api/GlobalSetting`;
export const GET_GLOBAL_SETTINGS_FOR_USER = `${BASE_URL}/api/GlobalSetting`;
export const CREATE_GLOBAL_SETTINGS_LOCATION_MAP = `${BASE_URL}/api/GlobalLocationMapping`;
export const GET_GLOBAL_SETTINGS_LOCATION_MAP = `${BASE_URL}/api/GlobalLocationMapping`;

// reports

export const GET_PATIENT_BILL = `${BASE_URL}/api/Report/samplepdf`;
export const GET_PATIENT_BILL_REPORT = `${BASE_URL}/api/Report/samplebilling`;
export const GET_PATIENT_LIST = `${BASE_URL}/api/Report/get-patient-list-excel`;
export const GET_PATIENT_DEVICE = `${BASE_URL}/api/Report/get-patient-devices-excel`;
