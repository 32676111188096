import styled from "styled-components";
import { Card, Input } from "antd";

export const Container = styled(Card)`
  background-color: #ffffff;
`;
export const StyledCard = styled(Card)`
  margin-bottom: 16px;
`;

export const DeviceOption = styled.div`
  margin-bottom: 8px;
`;

export const SerialInput = styled(Input)`
  margin-top: 4px;
  margin-bottom: 8px;
  margin-left: 24px; // Aligns with the checkbox label
`;
