import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Checkbox, Flex, Form, Space, Typography } from "antd";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import ErrorAlert from "@/components/ui/errorAlert";
import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import EmptyState from "@/components/ui/empty/Empty";
import {
  useGetCCMSymptomsQuery,
  useGetCCMSymptomMappingQuery,
  useCreateCCMSymptomMutation,
} from "@/store/features/ccm/ccmApiSlice";
import LoadingComponent from "@/components/LoadingComponent";
import { CcmContext } from "../../withCcmData";

const SymptomsForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);
  const { date } = useContext(CcmContext);
  const {
    data: symptoms,
    isLoading: isSymptomsLoading,
    isError: isSymptomsError,
    isSuccess: isSymptomsSuccess,
  } = useGetCCMSymptomsQuery();
  const {
    data: patientSymptoms,
    isLoading: isPatientSymptomsLoading,
    isSuccess: isPatientSymptomsSuccess,
  } = useGetCCMSymptomMappingQuery({
    date,
    patientId,
    pageNumber: 1,
    pageSize: 100,
  });
  const [createSymptom, { isLoading: isCreating }] =
    useCreateCCMSymptomMutation();

  useEffect(() => {
    if (patientSymptoms) {
      const selectedSymptoms = patientSymptoms.map(
        (symptom) => symptom.ccmSymptomId
      );
      form.setFieldsValue({ selectedSymptoms, isInitial: false });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [patientSymptoms, form, multiStep]);

  const handleFinish = async () => {
    const values = form.getFieldsValue(true);
    const payload = {
      ccmSymptomMappingList:
        values.selectedSymptoms?.map((ccmSymptomId) => ({ ccmSymptomId })) ||
        [],
      patientId,
    };

    await createSymptom(payload);

    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  if (multiStep && currentStep !== 3) return null;

  let content = null;
  if (patientSymptoms?.length && multiStep === false) {
    content = <EmptyState />;
  }
  if (isSymptomsLoading || isPatientSymptomsLoading) {
    content = <LoadingComponent text="   Patient Symptoms Loading ...." />;
  }

  if (isSymptomsError) {
    return <EmptyState />;
  }
  if (patientSymptoms?.length === 0 && !multiStep) return <EmptyState />;
  if (isPatientSymptomsSuccess && isSymptomsSuccess) {
    content = (
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <ErrorAlert />
        <Flex style={{ margin: "0px 15px" }} vertical gap={10}>
          <Typography.Title level={5}>
            What are some of the symptoms we will be able to reduce or prevent?
          </Typography.Title>

          <Form.Item name="selectedSymptoms" style={{ margin: "0px 15px" }}>
            <Checkbox.Group disabled={multiStep ? false : !isEditing}>
              <Space direction="vertical">
                {symptoms?.map((option) => (
                  <Checkbox
                    key={option.ccmSymptomId}
                    value={option.ccmSymptomId}
                  >
                    <span style={{ color: "black", opacity: 1 }}>
                      {option.name}
                    </span>
                  </Checkbox>
                ))}
              </Space>
            </Checkbox.Group>
          </Form.Item>
        </Flex>

        {multiStep ? (
          <StepControls isLoading={isCreating} onNextClick={form.submit} />
        ) : null}
      </Form>
    );
  }

  return content;
};

export default SymptomsForm;
