import { Flex, Typography } from "antd";

const Pulse = ({ rpmData }) => {
  const pulseData = rpmData?.find((item) => item.rpmTypeId === 2);

  const formatRange = (min, max) =>
    min !== undefined && max !== undefined ? `${min}-${max}` : "N/A";

  if (!pulseData) {
    return <Flex>No pulse data available</Flex>;
  }

  return (
    <Flex
      vertical
      style={{
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        width: "70%",
        flex: "1",
      }}
    >
      <Flex vertical style={{ margin: "13px 0px 13px 0px" }}>
        <Typography.Title level={5} style={{ marginBottom: "-3px" }}>
          PULSE
        </Typography.Title>
        <Typography.Text>
          Normal{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(pulseData.min, pulseData.max)}
          </span>
        </Typography.Text>
        <Typography.Text>
          Warning{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(pulseData.critMin, pulseData.min)} &{" "}
            {formatRange(pulseData.max, pulseData.critMax)}
          </span>
        </Typography.Text>
        <Typography.Text>
          Emergency{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(0, pulseData.critMin)} &{" "}
            {formatRange(pulseData.critMax, 999)}
          </span>
        </Typography.Text>
      </Flex>
      <Flex vertical>
        <Typography.Title level={5} style={{ marginBottom: "-3px" }}>
          INTERVENTIONS
        </Typography.Title>
        <Typography.Text style={{ width: "500px", textAlign: "start" }}>
          {pulseData.inteventionNote || "No intervention note available"}
        </Typography.Text>
      </Flex>
    </Flex>
  );
};

export default Pulse;
