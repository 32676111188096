import StepControls from "@/Pages/Patients/patientDetails/carePlan/ccm/multiStepForm/StepControls";
import LoadingComponent from "@/components/LoadingComponent";
import ErrorAlert from "@/components/ui/errorAlert";
import {
  useCreateCCMSelfManagementMutation,
  useGetCCMChronicConditionsQuery,
  useGetCCMSelfManagementQuery,
} from "@/store/features/ccm/ccmApiSlice";
import { setCurrentStep } from "@/store/features/ccm/ccmFormSlice";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Empty,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Table,
  Typography,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { CcmContext } from "../../withCcmData";

const StyledTable = styled(Table)`
  .ant-table-cell {
    padding: 8px 16px;
  }
`;

const SelfManagementForm = ({ multiStep = false }) => {
  const [form] = Form.useForm();
  const { patientId } = useParams();
  const dispatch = useDispatch();
  const { currentStep } = useSelector((state) => state.ccmForm);
  const [isEditing, setIsEditing] = useState(false);
  const { date } = useContext(CcmContext);
  const {
    data: chronicConditions,
    isLoading: ccmListLoading,
    isSuccess: isCcmSuccess,
  } = useGetCCMChronicConditionsQuery();
  const {
    data: selfManagement,
    isLoading: isSelfManagementLoading,
    isSuccess,
  } = useGetCCMSelfManagementQuery({
    date,
    patientId,
    pageNumber: 1,
    pageSize: 100,
  });
  const [createSelfManagement, { isLoading: isCreating }] =
    useCreateCCMSelfManagementMutation();

  useEffect(() => {
    if (selfManagement) {
      form.setFieldsValue({
        createCCMSelfManagementList: selfManagement,
        isInitial: false,
      });
      setIsEditing(false);
    } else {
      setIsEditing(multiStep);
    }
  }, [selfManagement, form, multiStep]);

  const handleSubmit = async () => {
    const values = form.getFieldsValue(true);
    const payload = {
      ...values,
      patientId,
    };

    await createSelfManagement(payload);

    setIsEditing(false);
    if (multiStep) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  if (multiStep && currentStep !== 9) return null;

  let content = null;

  if (isSelfManagementLoading || ccmListLoading) {
    content = <LoadingComponent text="  Self Manage Table Loading ........" />;
  }
  if (selfManagement?.length === 0 && !multiStep) return <Empty />;
  if (isSuccess && isCcmSuccess) {
    content = (
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <ErrorAlert />
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Typography.Title level={4}>
              Self Management By Patient
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.List name="createCCMSelfManagementList">
              {(fields, { add }) => (
                <>
                  <StyledTable
                    dataSource={fields}
                    columns={columns}
                    pagination={false}
                    rowKey={(record) => record.key}
                    size="small"
                  />
                  {(multiStep || isEditing) && (
                    <Flex justify="end" gap="small" style={{ marginTop: 16 }}>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        Add New
                      </Button>
                    </Flex>
                  )}
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        {multiStep ? (
          <StepControls isLoading={isCreating} onNextClick={form.submit} />
        ) : null}
      </Form>
    );
  }

  const columns = [
    {
      title: "Chronic Condition",
      dataIndex: "ccmChronicConditionId",
      key: "ccmChronicConditionId",
      width: "30%",
      render: (_, __, index) => (
        <Skeleton active loading={ccmListLoading} paragraph={false}>
          <Form.Item
            name={[index, "ccmChronicConditionId"]}
            rules={[
              {
                required: true,
                message: "Please select the chronic Condition",
              },
            ]}
          >
            <Select
              placeholder="Select chronic Condition"
              options={chronicConditions}
              fieldNames={{ label: "name", value: "ccmChronicConditionId" }}
              disabled={multiStep ? false : !isEditing}
            />
          </Form.Item>
        </Skeleton>
      ),
    },
    {
      title: "Goals",
      dataIndex: "goals",
      key: "goals",
      width: "40%",
      render: (_, __, index) => (
        <Form.Item
          name={[index, "goals"]}
          rules={[{ required: true, message: "Please input the goals" }]}
        >
          <Input
            placeholder="Management Goals"
            disabled={multiStep ? false : !isEditing}
          />
        </Form.Item>
      ),
    },
    {
      title: "Progress Status",
      dataIndex: "progressStatusId",
      key: "progressStatusId",
      render: (_, __, index) => (
        <Form.Item
          name={[index, "progressStatusId"]}
          rules={[{ required: true, message: "Please select the status" }]}
        >
          <Select
            placeholder="Select Status"
            options={[
              { label: "Pending", value: 1 },
              { label: "In-Progress", value: 2 },
              { label: "Achieved", value: 3 },
            ]}
            disabled={multiStep ? false : !isEditing}
          />
        </Form.Item>
      ),
    },
    {
      title: "",
      key: "delete",
      render: (_, __, index) => (
        <Form.Item>
          {(multiStep || isEditing) && (
            <DeleteOutlined
              onClick={() => {
                const selfManagementList = form.getFieldValue(
                  "createCCMSelfManagementList"
                );
                form.setFieldValue(
                  "createCCMSelfManagementList",
                  selfManagementList.filter((_, i) => i !== index)
                );
              }}
              style={{ color: "red", cursor: "pointer" }}
            />
          )}
        </Form.Item>
      ),
    },
  ];

  return content;
};

export default SelfManagementForm;
