import dayjs from "dayjs";

/**
 * Calculates the age based on the given date of birth.
 *
 * @param {string | Date} dob - The date of birth. Can be a string in a format
 *                              parseable by dayjs, or a Date object.
 * @returns {Object} An object containing the calculated age in various units:
 *                   - years: number of complete years
 *                   - months: total number of months
 *                   - weeks: total number of weeks
 *                   - days: total number of days
 *                   - formatted: a human-readable string representation of the age
 * @throws {Error} If the date of birth is missing, invalid, or in the future.
 */
const calculateAge = (dob) => {
  // Check if date of birth is provided
  if (!dob) {
    throw new Error("Date of birth is required");
  }

  // Parse the date of birth using dayjs
  const birthDate = dayjs(dob);

  // Validate the parsed date
  if (!birthDate.isValid()) {
    throw new Error("Invalid date of birth");
  }

  // Get current date
  const today = dayjs();

  // Ensure the date of birth is not in the future
  if (birthDate.isAfter(today)) {
    throw new Error("Date of birth cannot be in the future");
  }

  // Calculate the difference in various units
  const years = today.diff(birthDate, "year");
  const months = today.diff(birthDate, "month");
  const weeks = today.diff(birthDate, "week");
  const days = today.diff(birthDate, "day");

  // Return an object with all calculated values
  return {
    years,
    months,
    weeks,
    days,
    formatted: formatAge(days, weeks, months, years),
  };
};

/**
 * Formats the age into a human-readable string.
 *
 * @param {number} days - Total number of days.
 * @param {number} weeks - Total number of weeks.
 * @param {number} months - Total number of months.
 * @param {number} years - Number of complete years.
 * @returns {string} A formatted string representing the age in the most appropriate unit.
 */
const formatAge = (days, weeks, months, years) => {
  if (years > 0) {
    return `${years} year${years > 1 ? "s" : ""}`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""}`;
  } else if (weeks > 0) {
    return `${weeks} week${weeks > 1 ? "s" : ""}`;
  } else {
    return `${days} day${days !== 1 ? "s" : ""}`;
  }
};

export default calculateAge;
