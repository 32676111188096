import Icon from '@ant-design/icons'

const CustomIcon = () => (
  <svg width="1.5em" height="1.5em" viewBox="0 0 24 24" fill="currentColor">
    <path d="M4 4h6v6H4V4zm10 0h6v6h-6V4zM4 14h6v6H4v-6zm10 0h6v6h-6v-6z" />
  </svg>
)

const DashboardIcon = (props) => <Icon component={CustomIcon} {...props} />
export default DashboardIcon
