import { Checkbox, Form, Input, Select, Typography } from 'antd'
import { useState } from 'react'
import { StyledCheckBoxContainer, StyledMainContainer } from './styles'
const { Option } = Select
const { TextArea } = Input
const { Text } = Typography

const options = [
  {
    label: 'Not Applicable',
    value: 'notApplicable',
  },
  {
    label: 'Maintained Ideal Height and Weight ratio',
    value: 'ideal',
  },
  {
    label: 'Be alchol free',
    value: 'alchol',
  },
  {
    label: 'Complete therapy/detox',
    value: 'complete',
  },
  {
    label: 'Consistently attend support group(AA) sessions',
    value: 'support',
  },
  {
    label: 'Reduce anxiety and depression',
    value: 'anxiety',
  },
  {
    label: 'Other(s)',
    value: 'others',
  },
]

export default function CareGoals() {
  return (
    <StyledMainContainer>
      <Typography.Title level={4}>
        What are the patient's goal for diseas management?
      </Typography.Title>

      <StyledCheckBoxContainer>
        <Form.Item name="careplanGoals">
          <Checkbox.Group
            options={options}
            style={{ display: 'flex', flexDirection: 'column', gap: '13px' }} // Ensure vertical alignment
          />
        </Form.Item>
      </StyledCheckBoxContainer>

      <div style={{ margin: '10px 0px' }}>
        <Typography.Text className="ant-form-item-label">
          Add Notes
        </Typography.Text>
      </div>
      <Form.Item name="careplanGoalsDescription">
        <Input.TextArea placeholder="Add Notes" rows={5} />
      </Form.Item>
    </StyledMainContainer>
  )
}
