import NotificationSnackbar from "@/components/ui/notification/NotificationSnackbar";
import theme from "@/constants/theme.js";
import { ConfigProvider } from "antd";
import { RouterProvider } from "react-router-dom";
import "./App.css";
import appRouter from "./routes/router";
function App() {
  return (
    <ConfigProvider theme={theme}>
      <RouterProvider router={appRouter} />
      <NotificationSnackbar />
    </ConfigProvider>
  );
}

export default App;
