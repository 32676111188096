import { useCreatePatientMutation } from '@/store/features/patient/patientApiSlice'

const useCreatePatient = () => {
  const [createPatient, { isLoading, isSuccess, isError, data, error }] =
    useCreatePatientMutation()

  const handleCreatePatient = (body) => {
    createPatient(body)
  }

  return { handleCreatePatient, isLoading, isSuccess, isError, data, error }
}

export default useCreatePatient
