import Icon from '@ant-design/icons'

const CustomIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <path
      fill="transparent"
      stroke="currentColor"
      d="M6.796 14.22a5.5 5.5 0 012.062-2.735L6.796 14.22zm0 0a5.5 5.5 0 00-.044 3.425m.044-3.425l-.044 3.425m0 0a5.5 5.5 0 001.993 2.787m-1.993-2.787l1.993 2.787m0 0A5.5 5.5 0 0012 21.5m-3.255-1.067L12 21.5m0 0a5.5 5.5 0 003.255-1.067M12 21.5l3.255-1.067m0 0a5.5 5.5 0 001.993-2.787m-1.993 2.787l1.993-2.787m0 0a5.5 5.5 0 00-.044-3.425m.044 3.425l-.044-3.425m0 0a5.5 5.5 0 00-2.062-2.735l2.062 2.735zM15.5 10.255v.261l.214.15a6.5 6.5 0 11-7.428 0l.214-.15V5a3.5 3.5 0 117 0v5.255zm-6 .782l-.642.448h6.284l-.642-.448V5a2.5 2.5 0 00-5 0v6.037zm5.964 5.463a3.5 3.5 0 01-6.928 0h6.928z"
    ></path>
  </svg>
)

const TemperatureIcon = (props) => <Icon component={CustomIcon} {...props} />
export default TemperatureIcon
