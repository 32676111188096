import { setHealthConditionsId } from "@/store/features/patient/addPatientSlice";
import { useCreateHealthConditionMutation } from "@/store/features/patient/patientApiSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const useCreateHealthCondition = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [lastRequestBody, setLastRequestBody] = useState({});
  const [
    createHealthCondition,
    { isLoading, isSuccess, isError, data, error },
  ] = useCreateHealthConditionMutation();

  const handleCreateHealthCondition = (body) => {
    createHealthCondition(body);
    setLastRequestBody(body);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setHealthConditionsId(data.id));
      if (lastRequestBody?.hasActivityRPM) {
        navigate(`/patients/add/${lastRequestBody.patientId}/rpm-setup`);
      } else if (lastRequestBody?.hasActivityCCM) {
        navigate(`/patients/add/${lastRequestBody.patientId}/ccm-setup`);
      } else if (lastRequestBody?.hasActivityPCM) {
        navigate(`/patients/add/${lastRequestBody.patientId}/pcm-setup`);
      } else if (lastRequestBody?.hasActivityRTM) {
        navigate(`/patients/add/${lastRequestBody.patientId}/rtm-setup`);
      } else {
        navigate(`/patients/add/${lastRequestBody.patientId}/caregiver-info`);
      }
    }
  }, [isSuccess, navigate, lastRequestBody, dispatch, data]);

  return {
    handleCreateHealthCondition,
    isLoading,
    isSuccess,
    isError,
    data,
    error,
  };
};

export default useCreateHealthCondition;
