import { Flex, Typography } from "antd";
import PatientAvatar from "../../Pages/Patients/dashboard/components/PatientAvatar";

export default function PatientInfo(props) {
  const { name, mrn, photoUrl } = props;
  return (
    <Flex gap="12px" align="center">
      <PatientAvatar src={photoUrl} name={name} />
      <Flex vertical gap="2px">
        <Typography.Text
          strong
          style={{ fontSize: "16px", fontWeight: 600, color: "#242235" }}
        >
          {name}
        </Typography.Text>
        <Typography.Text
          type="secondary"
          style={{ fontSize: "14px", fontWeight: 400, color: "#76767B" }}
        >
          {mrn ? `#${mrn}` : ""}
        </Typography.Text>
      </Flex>
    </Flex>
  );
}
