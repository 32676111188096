import styled from "styled-components";




export const StyledMainContainer = styled.div`

display: flex;

justify-content:space-between;

align-items:center;

width: 100%;

margin-top:9px;

`


export const StyledIconsDiv = styled.div`

display:flex;

justify-content:center;

align-items:center;

gap:7px

`