import { useEffect, useMemo, useCallback } from "react";
import { Form, Typography } from "antd";
import {
  useCreateGlobalSettingsMutation,
  useGetGlobalSettingsQuery,
  useUpdateGlobalSettingsMutation
} from "@/store/features/globalSettings/globalSettingsSlice";
import LocationManagement from "./Component/LocationManagement";
import { StyledDivContainer, StyledMainContainer } from "./Style";
import TypewriterEffect from "@/components/shared/TypeWriterEffect";
import LoadingComponent from "@/components/LoadingComponent";
import EmptyState from "@/components/ui/empty/Empty";
import GlobalSettingsForm from "./GlobalSettingsForm";

export default function GlobalSetting() {
  const [form] = Form.useForm();

  const [createGlobalSettings, { isLoading: isCreateLoading }] =
    useCreateGlobalSettingsMutation();
  const [updateGlobalSettings, { isLoading: isUpdateLoading }] =
    useUpdateGlobalSettingsMutation();
  const {
    data: globalSettings,
    isLoading: isGlobalSettingsLoading,
    isError,
    isSuccess
  } = useGetGlobalSettingsQuery();

  const processedSettings = useMemo(() => {
    if (!isSuccess || !globalSettings) return null;

    let exists = false;
    let settings = {};

    if (Array.isArray(globalSettings) && globalSettings.length !== 0) {
      exists = true;
      settings = globalSettings[0];
    } else if (
      !Array.isArray(globalSettings) &&
      Object.keys(globalSettings).length !== 0
    ) {
      exists = true;
      settings = globalSettings;
    }

    return { exists, settings };
  }, [isSuccess, globalSettings]);

  useEffect(() => {
    if (processedSettings?.exists) {
      const { globalSettingId, ...initialValues } = processedSettings.settings;
      form.setFieldsValue(initialValues);
    }
  }, [processedSettings, form]);

  const onFinish = useCallback(() => {
    let values = form.getFieldsValue();
    values = Object.entries(values).reduce((acc, [key, value]) => {
      acc[key] = value === undefined ? false : value;
      return acc;
    }, {});

    if (processedSettings?.exists) {
      updateGlobalSettings({
        ...values,
        globalSettingId: processedSettings.settings.globalSettingId
      });
    } else {
      createGlobalSettings(values);
    }
  }, [form, processedSettings, updateGlobalSettings, createGlobalSettings]);

  let content;
  if (isGlobalSettingsLoading) {
    content = <LoadingComponent />;
  } else if (isError) {
    content = <EmptyState />;
  } else if (processedSettings) {
    content = (
      <GlobalSettingsForm
        onFinish={onFinish}
        form={form}
        userGlobalSettings={processedSettings.settings}
        isLoading={isCreateLoading || isUpdateLoading}
        isGlobalSettingsExists={processedSettings.exists}
      />
    );
  }

  return (
    <StyledMainContainer>
      <Typography.Title level={2}>
        <TypewriterEffect>
          {processedSettings?.exists
            ? " Settings Existed. Want to update?"
            : "Create your Settings"}
        </TypewriterEffect>
      </Typography.Title>
      <StyledDivContainer>
        {content}
        <StyledDivContainer>
          <LocationManagement
            globalSettingId={processedSettings?.settings.globalSettingId}
          />
        </StyledDivContainer>
      </StyledDivContainer>
    </StyledMainContainer>
  );
}
