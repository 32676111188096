import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
export const PatientBarChart = ({ data }) => {
  return (
    <BarChart
      data={data}
      width={1100}
      height={250}
      style={{ marginTop: "30px" }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        angle={-20}
        dataKey="createdAt"
        label={{
          value: "Date",
          position: "insideBottom",
          offset: -5,
        }}
        padding={{ left: 30, right: 30 }}
      />
      <YAxis
        label={{
          value: "value",
          angle: -90,
          position: "insideLeft",
          offset: 20,
        }}
      />
      <Tooltip />
      <Bar dataKey="patientData" fill="#8884d8" />
    </BarChart>
  );
};
export const PatientLineChart = ({ data }) => {
  return (
    <LineChart
      data={Array.isArray(data) ? [...data].reverse() : []}
      width={1100}
      height={250}
      style={{ marginTop: "30px" }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        angle={-20}
        dataKey="createdAt"
        label={{
          value: "Date",
          position: "insideBottom",
          offset: -5,
        }}
        padding={{ left: 30, right: 30 }}
      />
      <YAxis
        label={{
          value: "value",
          angle: -90,
          position: "insideLeft",
          offset: 20,
        }}
      />
      <Tooltip />

      <Line
        type="monotone"
        dataKey="patientData"
        stroke="#8884d8"
        activeDot={{ r: 8 }}
      />
    </LineChart>
  );
};
