import IncomingCall from "@/components/call/IncomingCall";
import { handleEndCall } from "@/store/features/audioVideoCall/audioVideoSlice";
import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { Button, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
  .ant-modal-body {
    padding: 0;
  }
`;

const JitsiWrapper = styled.div`
  iframe {
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
`;

const ControlsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  gap: 10px;
`;

const FullscreenContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const AudioVideoCall = () => {
  const [api, setApi] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const fullscreenContainerRef = useRef(null);
  const { hasActiveCall, hasAcceptedCall, callType, meetingUrl, caller } =
    useSelector((state) => state.call);
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      if (api) {
        api.dispose();
      }
    };
  }, [api]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    if (callType === "audio") {
      setIsVideoMuted(true);
    }
  }, [callType]);
  const handleCallEnd = () => {
    dispatch(handleEndCall());
  };
  const handleApiReady = (apiInstance) => {
    setApi(apiInstance);

    if (callType === "audio") {
      apiInstance.executeCommand("toggleVideo");
    }

    apiInstance.on("videoConferenceLeft", () => {
      handleCallEnd();
    });

    apiInstance.on("audioMuteStatusChanged", (muted) => {
      setIsMuted(muted);
    });

    apiInstance.on("videoMuteStatusChanged", (muted) => {
      setIsVideoMuted(muted);
    });
  };

  const getJitsiRoomName = () => {
    try {
      const parsedUrl = new URL(meetingUrl);
      return parsedUrl.pathname.split("/").pop();
    } catch (error) {
      console.error("Invalid URL:", meetingUrl, error);
      return "";
    }
  };

  const toggleAudio = () => {
    if (api) {
      api.executeCommand("toggleAudio");
    }
  };

  const toggleVideo = () => {
    if (api) {
      api.executeCommand("toggleVideo");
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      fullscreenContainerRef.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  return (
    <StyledModal
      open={hasActiveCall}
      onCancel={null}
      maskClosable={false}
      footer={null}
      width={hasAcceptedCall ? 832 : 500}
      destroyOnClose
      zIndex={1400}
      centered
    >
      {hasAcceptedCall ? (
        <FullscreenContainer ref={fullscreenContainerRef}>
          <JitsiWrapper>
            <JitsiMeeting
              domain="video.medikick.xyz"
              roomName={getJitsiRoomName()}
              configOverwrite={{
                startWithAudioMuted: false,
                startWithVideoMuted: callType === "audio",
                prejoinPageEnabled: false,
              }}
              interfaceConfigOverwrite={{
                TOOLBAR_BUTTONS: [],
              }}
              onApiReady={handleApiReady}
              getIFrameRef={(iframeRef) => {
                iframeRef.style.width = "100%";
                iframeRef.style.height = isFullscreen ? "100vh" : "600px";
              }}
            />
          </JitsiWrapper>
          <ControlsWrapper>
            <Button onClick={toggleAudio}>
              {isMuted ? "Unmute" : "Mute"} Audio
            </Button>
            <Button onClick={toggleVideo}>
              {isVideoMuted ? "Start" : "Stop"} Video
            </Button>
            <Button onClick={toggleFullscreen}>
              {isFullscreen ? (
                <FullscreenExitOutlined />
              ) : (
                <FullscreenOutlined />
              )}
            </Button>
            <Button onClick={handleCallEnd}>End Call</Button>
          </ControlsWrapper>
        </FullscreenContainer>
      ) : (
        <IncomingCall callType={callType} caller={caller} />
      )}
    </StyledModal>
  );
};

export default AudioVideoCall;
