import { Flex, Typography } from "antd";

const Weight = ({ rpmData }) => {
  const weightData = rpmData?.find((item) => item.rpmTypeId === 3);

  const formatRange = (min, max) =>
    min !== undefined && max !== undefined ? `${min}-${max}` : "N/A";

  if (!weightData) {
    return <Flex>No weight data available</Flex>;
  }

  return (
    <Flex
      vertical
      style={{
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        width: "70%",
        flex: "1",
      }}
    >
      <Flex vertical style={{ margin: "13px 0px 13px 0px" }}>
        <Typography.Title level={5} style={{ marginBottom: "-3px" }}>
          WEIGHT
        </Typography.Title>
        <Typography.Text>
          Normal{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(weightData.min, weightData.max)}
          </span>
        </Typography.Text>
        <Typography.Text>
          Warning{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(weightData.critMin, weightData.min)} &{" "}
            {formatRange(weightData.max, weightData.critMax)}
          </span>
        </Typography.Text>
        <Typography.Text>
          Emergency{" "}
          <span style={{ fontWeight: "bold" }}>
            {formatRange(0, weightData.critMin)} &{" "}
            {formatRange(weightData.critMax, 999)}
          </span>
        </Typography.Text>
      </Flex>
      <Flex vertical>
        <Typography.Title level={5} style={{ marginBottom: "-3px" }}>
          INTERVENTIONS
        </Typography.Title>
        <Typography.Text style={{ width: "500px", textAlign: "start" }}>
          {weightData.inteventionNote || "No intervention note available"}
        </Typography.Text>
      </Flex>
    </Flex>
  );
};

export default Weight;
