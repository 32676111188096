import {
  CREATE_GLOBAL_SETTINGS_FOR_USER,
  GET_GLOBAL_SETTINGS_FOR_USER,
  UPDATE_GLOBAL_SETTINGS_FOR_USER
} from "@/constants/apiDefinition";
import { apiSlice } from "@/store/features/api/apiSlice";

export const globalSettingsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createGlobalSettings: builder.mutation({
      query: (data) => ({
        url: CREATE_GLOBAL_SETTINGS_FOR_USER,
        method: "POST",
        body: data
      }),
      invalidatesTags: [{ type: "GlobalSettings", id: "LIST" }]
    }),

    getGlobalSettings: builder.query({
      query: () => ({
        url: `${GET_GLOBAL_SETTINGS_FOR_USER}`,
        method: "GET"
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "GlobalSettings", id })),
              { type: "GlobalSettings", id: "LIST" }
            ]
          : [{ type: "GlobalSettings", id: "LIST" }]
    }),

    updateGlobalSettings: builder.mutation({
      query: (data) => ({
        url: UPDATE_GLOBAL_SETTINGS_FOR_USER,
        method: "PUT",
        body: data
      }),
      invalidatesTags: [{ type: "GlobalSettings", id: "LIST" }]
    }),

    deleteDevice: builder.mutation({
      query: (id) => {
        return {
          url: `${"delete-url"}/${id}`,
          method: "DELETE",
          providesTags: ["Device"]
        };
      },
      invalidatesTags: [{ type: "GlobalSettings", id: "LIST" }]
    })
  })
});

export const {
  useCreateGlobalSettingsMutation,
  useGetGlobalSettingsQuery,
  useUpdateGlobalSettingsMutation
} = globalSettingsApi;
