import { useState, useEffect } from "react";
import { Input, Button, Form, Modal, Select, Row, Col } from "antd";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    text-align: right;
  }
`;

const HealthcareFacilitySelector = ({
  modalOpen,
  setModalOpen,
  initialFacilities,
  globalSettingId,
  isLoading,
  createLocationMap
}) => {
  const [form] = Form.useForm();
  const [facilities, setFacilities] = useState(initialFacilities);
  const [selectedFacility, setSelectedFacility] = useState(null);

  useEffect(() => {
    setFacilities(initialFacilities);
  }, [initialFacilities]);

  const handleCreate = () => {
    const globalLocationId = selectedFacility
      ? selectedFacility.globalLocationId
      : null;

    // Call createLocationMap with the correct parameters
    createLocationMap({ globalLocationId, globalSettingId });

    setModalOpen(false);
    form.resetFields();
    setSelectedFacility(null);
  };

  const handleSelectChange = (value) => {
    const selected = facilities.find(
      (facility) => facility.displayName === value
    );
    if (selected) {
      setSelectedFacility(selected);
      form.setFieldsValue(selected);
    } else {
      setSelectedFacility(null);
    }
  };

  return (
    <StyledModal
      title="Facility Details"
      open={modalOpen}
      onCancel={() => {
        setModalOpen(false);
        form.resetFields();
        setSelectedFacility(null);
      }}
      footer={[
        <Button
          key="cancel"
          onClick={() => {
            setModalOpen(false);
            form.resetFields();
            setSelectedFacility(null);
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          loading={isLoading}
          disabled={selectedFacility === null}
          type="primary"
          onClick={() => form.submit()}
        >
          {selectedFacility === null ? "Select Facility First" : "Create"}
        </Button>
      ]}
      width={600}
    >
      <Form form={form} onFinish={handleCreate} layout="vertical">
        <Form.Item name="displayName" label="Display Name">
          <Select
            showSearch
            placeholder="Select a facility"
            optionFilterProp="children"
            onChange={handleSelectChange}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={facilities.map((facility) => ({
              value: facility.displayName,
              label: facility.displayName
            }))}
          />
        </Form.Item>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="facultyApi" label="Faculty API">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="locationName" label="Location Name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="state" label="State">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="address" label="Address">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="city" label="City">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="zipCode" label="Zip Code">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledModal>
  );
};

export default HealthcareFacilitySelector;
