import { Radio } from "antd";
import styled from "styled-components";

const StyledRadioGroup = styled(Radio.Group)`
  background-color: #1677ff;
  border-radius: 32px;
  padding: 5px 7px;
  display: inline-flex;
`;

const StyledRadioButton = styled(Radio.Button)`
  &&& {
    padding: 0 16px;
    border: none;
    border-radius: 32px;
    background: #1677ff;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;

    &::before {
      display: none;
    }

    &:first-child {
      border-left: none;
    }

    &:hover {
      color: #333;
    }

    &.ant-radio-button-wrapper-checked {
      background-color: white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      color: #000000;
      background-color: #ffffff;
      font-weight: 600;
    }

    &.ant-radio-button-wrapper-checked:not(
        .ant-radio-button-wrapper-disabled
      )::before {
      background-color: transparent;
    }
  }
`;

const MonitoringSelector = ({ options, value, ...props }) => {
  return (
    <StyledRadioGroup buttonStyle="solid" {...props} value={value}>
      {options.map((option) => (
        <StyledRadioButton key={option.value} value={option.value}>
          {option.label}
        </StyledRadioButton>
      ))}
    </StyledRadioGroup>
  );
};

export default MonitoringSelector;
