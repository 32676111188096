const errorResponses = {
  400: {
    status: 400,
    statusText: 'Bad Request',
    message:
      'The request was invalid or cannot be otherwise served. Please check your input and try again.',
  },
  401: {
    status: 401,
    statusText: 'Unauthorized',
    message:
      'Authentication is required to access this resource. Please log in or provide valid credentials.',
  },
  403: {
    status: 403,
    statusText: 'Forbidden',
    message:
      "You don't have permission to access this resource. If you believe this is an error, please contact the system administrator.",
  },
  404: {
    status: 404,
    statusText: 'Not Found',
    message:
      'The requested resource could not be found. Please check the URL and try again.',
  },
  405: {
    status: 405,
    statusText: 'Method Not Allowed',
    message:
      'The method specified in the request is not allowed for the resource identified by the request URI.',
  },
  408: {
    status: 408,
    statusText: 'Request Timeout',
    message:
      'The server timed out waiting for the request. Please try again later.',
  },
  409: {
    status: 409,
    statusText: 'Conflict',
    message:
      'The request could not be completed due to a conflict with the current state of the resource.',
  },
  415: {
    status: 415,
    statusText: 'Unsupported Media Type',
    message:
      'The server refuses to accept the request because the payload format is in an unsupported format.',
  },
  429: {
    status: 429,
    statusText: 'Too Many Requests',
    message:
      'You have sent too many requests in a given amount of time. Please try again later.',
  },
  500: {
    status: 500,
    statusText: 'Internal Server Error',
    message:
      'The server encountered an unexpected condition that prevented it from fulfilling the request. Please try again later.',
  },
  502: {
    status: 502,
    statusText: 'Bad Gateway',
    message:
      'The server received an invalid response from an upstream server. Please try again later.',
  },
  503: {
    status: 503,
    statusText: 'Service Unavailable',
    message:
      'The server is currently unable to handle the request due to temporary overloading or maintenance. Please try again later.',
  },
  504: {
    status: 504,
    statusText: 'Gateway Timeout',
    message:
      "The server didn't receive a timely response from an upstream server. Please try again later.",
  },
  default: {
    status: 'unknown',
    statusText: 'Unexpected Error',
    message:
      'An unexpected error occurred. Please try again later or contact support if the problem persists.',
  },
}

export default errorResponses
