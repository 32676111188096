import TypewriterEffect from "@/components/shared/TypeWriterEffect";
import { Button, Empty, Typography } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

const FullScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
  width: 100%;
`;

const EmptyState = ({ text, link }) => (
  <FullScreenContainer>
    <Empty
      description={
        <Typography.Title level={4}>
          <TypewriterEffect>
            {text || "  No Data Available Right Now!"}
          </TypewriterEffect>
        </Typography.Title>
      }
    >
      {link ? (
        <Link to={link}>
          <Button>Create</Button>
        </Link>
      ) : null}
    </Empty>
  </FullScreenContainer>
);

export default EmptyState;
