import { Button, Col, DatePicker, Form, Row, Typography } from "antd";
import { StyledDivider, StyledForm, StyledRadioGroup } from "./Style";
import useCreatePatientSetting from "@/hooks/patient/usePatientSetting";
import { useParams } from "react-router-dom";
const { Title } = Typography;
const { RangePicker } = DatePicker;

export default function PatientSetting() {
  const { handleCreatePatientSetting, isLoading } = useCreatePatientSetting();

  const [form] = Form.useForm();
  const { patientId } = useParams();
  const onFinish = (values) => {
    const payload = { ...values };
    payload.monitoringStartDate = payload?.monitoringDateRange?.[0];
    payload.monitoringEndDate = payload?.monitoringDateRange?.[1];
    payload.patientId = patientId;
    delete payload.monitoringDateRange;

    handleCreatePatientSetting(payload);
  };

  return (
    <StyledForm
      form={form}
      name="patientSetting"
      onFinish={onFinish}
      layout="vertical"
      requiredMark="optional"
      initialValues={{
        deviceProducedBy: "provider",
        smsReminder: "yes",
        monitoringStatus: "on",
      }}
    >
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item
            name="monitoringDateRange"
            label="Monitoring Date Range"
            rules={[{ required: true, message: "Please select the date!" }]}
          >
            <RangePicker />
          </Form.Item>
        </Col>
      </Row>

      <StyledDivider />

      <Row gutter={[16, 24]}>
        <Col xs={24} md={8}>
          <Title level={5}>Device Provided By</Title>
          <Form.Item
            name="deviceProcuredById"
            rules={[{ required: true, message: "Please select an option!" }]}
          >
            <StyledRadioGroup
              options={deviceOptions}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Title level={5}>SMS Reminder</Title>
          <Form.Item
            name="smsReminder"
            rules={[{ required: true, message: "Please select an option!" }]}
          >
            <StyledRadioGroup
              options={smsOptions}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          <Title level={5}>Monitoring Status</Title>
          <Form.Item
            name="monitoringStatusTypeId"
            rules={[{ required: true, message: "Please select an option!" }]}
          >
            <StyledRadioGroup
              options={statusOptions}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </Col>
      </Row>

      <StyledDivider />

      <Row gutter={16} justify="end">
        <Col>
          <Button htmlType="button">Cancel</Button>
        </Col>
        <Col>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Next
          </Button>
        </Col>
      </Row>
    </StyledForm>
  );
}

const deviceOptions = [
  { label: "Provider", value: 1 },
  { label: "Insurance", value: 2 },
  { label: "Patient", value: 3 },
];

const smsOptions = [
  { label: "YES", value: true },
  { label: "NO", value: false },
];

const statusOptions = [
  { label: "On", value: 1 },
  { label: "Off", value: 2 },
  { label: "Paused", value: 3 },
];
