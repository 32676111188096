import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hasActiveCall: false,
  hasAcceptedCall: false,
  callType: null,
  meetingUrl: null,
  caller: null,
};

const callSlice = createSlice({
  name: "call",
  initialState,
  reducers: {
    handleEndCall: (state) => {
      state.hasActiveCall = false;
      return state;
    },
    handleIncomingCall: (state, action) => {
      state.hasActiveCall = true;
      state.hasAcceptedCall = false;
      state.callType = action.payload.callType;
      state.meetingUrl = action.payload.meetingUrl;
      state.caller = action.payload.caller;
      return state;
    },
    setHasAcceptedCall: (state, action) => {
      state.hasAcceptedCall = action.payload;
      if (action.payload === false) {
        state.hasActiveCall = false;
      }
      return state;
    },
  },
});

export const { handleEndCall, handleIncomingCall, setHasAcceptedCall } =
  callSlice.actions;
export default callSlice.reducer;
