import {
  setCurrentStep,
  setIsModalOpen,
} from "@/store/features/ccm/ccmFormSlice";
import { useDispatch, useSelector } from "react-redux";
import ContentContainer from "./ContentContainer";
import Stepper from "./Stepper";
import { StyledContainer, StyledModal } from "./styles";
const ContainerModal = () => {
  const { isModalOpen, currentStep } = useSelector((state) => state.ccmForm);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setIsModalOpen(false));
  };
  return (
    <StyledModal
      title="Chronic Care Management"
      centered
      open={isModalOpen}
      onCancel={handleClose}
      maskClosable={false}
      width="80%"
      footer={null}
    >
      <StyledContainer>
        <Stepper currentStep={currentStep} onChange={setCurrentStep} />
        <ContentContainer />
      </StyledContainer>
    </StyledModal>
  );
};

export default ContainerModal;
