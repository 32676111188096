import Icon from '@ant-design/icons'

const CustomIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M9.707 13.293a1 1 0 00-1.414 1.414l1.414-1.414zM11 16l-.707.707a1 1 0 001.414 0L11 16zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zM18 7v12h2V7h-2zm-1 13H7v2h10v-2zM6 19V7H4v12h2zM7 6h2V4H7v2zm8 0h2V4h-2v2zM7 20a1 1 0 01-1-1H4a3 3 0 003 3v-2zm11-1a1 1 0 01-1 1v2a3 3 0 003-3h-2zm2-12a3 3 0 00-3-3v2a1 1 0 011 1h2zM6 7a1 1 0 011-1V4a3 3 0 00-3 3h2zm2.293 7.707l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414zM11 4h2V2h-2v2zm2 2h-2v2h2V6zm-2 0a1 1 0 01-1-1H8a3 3 0 003 3V6zm3-1a1 1 0 01-1 1v2a3 3 0 003-3h-2zm-1-1a1 1 0 011 1h2a3 3 0 00-3-3v2zm-2-2a3 3 0 00-3 3h2a1 1 0 011-1V2z"
    ></path>
  </svg>
)

const TasksIcon = (props) => <Icon component={CustomIcon} {...props} />
export default TasksIcon
